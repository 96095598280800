import React from 'react';
import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import { bindActionCreators } from 'redux';
import  {OpidApplicationBase,  WebSession }  from '@opidcore/components/OpidApplication';

import * as CentralActions from '@opidcore/actions/CentralActions';
import * as WebSocketActions from '@opidcore/actions/WebSocketActions';

import * as testing from './Wasabi.scss';

import Icon from '@opidcore/components/Icon';

class KoalatyApplication extends OpidApplicationBase {
	
	 constructor() {
		 super();
		 
	     this.state.loggedIn = false;
	     this.state.mainContainerClass = "page-container";
	 }
	 
	
	 getPrintFooter(){
	     return "Wastemetric (version 1.00)";
	 }
	 
	 getFooterChildren(){
		 	var footer = [];
		 
			return footer;
		}

	componentDidMount() {
		super.componentDidMount();

		APP.getState("webSession", (webSession) => {
			if (webSession && webSession.role) {
				this.setState({ loggedIn: true, ws: webSession });
			}
		});

		let rememberMe = false;
		if (document.cookie) {
			_.each(document.cookie.split(";"), (cookie) => {
				const [key, val] = cookie.split("=");
				if (key == "remember_username") {
					//this.setState({ username: val });
					rememberMe = true;
				}
			});
		}

		this.props.central.TimedTokens.isLoggedIn().then(response => {
			if (response.result && response.result.result) {
				window.USER = response.result.result;

				new WebSession(window.USER);

			}
		});
	}

	 
	 logout(){
		 this.props.central.TimedTokens.logout().then( ()=>{
			 window.location = "/login";	 
		 });
		 //this.props.history.push("/login");
		 
	 }
	 	 
	 renderMenu(){
	     
		 const items = [];
		 	var role = this.props.login && this.props.login.result ? this.props.login.result.role : "not logged in";
		 
			if (this.state.loggedIn){
				const keys = [];
				_.each(APP.menus, (functionThing)=>{
					
					const newItems = functionThing();
					newItems.forEach(i=>{
							if (keys.indexOf(i.key) == -1){
								items.push(i);
								keys.push(i.key);
							}
							
					});
					
				});
				
				
				
				if (role == "admin"){
					items.push(<li key="admin"><Icon icon="backspace"/><Link to="/admin">Admin</Link></li>);
				}

				items.push(<li key="logout"><a  style={{cursor: 'pointer'}} onClick={()=>this.logout()}><Icon icon="sign-out"/> Logout</a></li>);
				
			}else{
				    items.push(<li key="login"><Link to="/login"><Icon icon="sign-in"/> Login</Link></li>);
			}
			
					
			if (this.state.processingMessage){
				items.push(<li key="processing">{this.state.processingMessage}</li>);		
			}

			
			
			return items;
			
			
	 }
	 	 
	 logoSrc(){
		 return "/assets/images/customer_logo.png"
	 }
	 
	 
	  
}

function mapDispatchToProps(dispatch) {
	let theProps = CentralActions.modifyProps(dispatch);
	return {
		...theProps,
		actions: bindActionCreators({...CentralActions}, dispatch),
		wsactions: bindActionCreators({...WebSocketActions}, dispatch),
	};
}

function mapStateToProps(state) {	
	return {
		processingMessage: state.coreData._processingMessage,
		errors: state.central.errors,
		login: state.central.login
	};
}

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(KoalatyApplication);

