import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tabular, TabularColumn } from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import Icon from "@opidcore/components/Icon";
import { useNavBar } from "@opidcore/hooks/WTF";
import { newSite } from "../../Workflow";
import ExcelExportModal from "../../components/ExcelExportModal";
import FullTextSearchable from "../../components/FullTextSearchable";

export default function ListSites(props) { 
    
  //const [data, dataSet] = useData("site", { paginate: 50 });
    const dsOptions = { method: "fetchSitesForList", paginate: 50 };
    let dsName = "site";
    if (props.forVendor){
      dsName = "site;" + props.forVendor;
      dsOptions.method = "fetchSitesForVendor";
      dsOptions.forVendor = props.forVendor;
    }else{
      useNavBar("Sites", [], {menu: ["clients", "sites"]});
    }
    
    //const [data, dataSet] = useData(dsName, dsOptions);
    const [data, setData] = useState([])
    const [fetchSites, setFetchSites] = useState(false);

  const handleClick = (row,e) => {
    if (e.defaultPrevented == false){
      APP.redirect("/ui/sites/detail/" + row.friendly_id);
    }
  };

  const excelExport = () => {
    APP.central.Site.getDefaultExportLayout().then((r) => {
      APP.instance.createModal(<ExcelExportModal defaultLayout={r.result} dataSet={data} model={"Site"} />, { modal_name: "Excel Export" });
    });
  };

  return (
    <div key="list_sites">
      <ActionBar title="Actions">
        {/*<Searchable ds={dataSet} />*/}
        <FullTextSearchable 
          model={"Site"} 
          method={"reasonableList"} 
          setData={setData} 
          doFetchData={fetchSites} 
          setDoFetchData={setFetchSites} 
          filterOptions={{
            columns: [
              { column: "customer_name", heading: "Client" },
              { column: "city" },
              { column: "province" },
              { column: "historical", default: "0"},
            ]
          }} 
        />
        {/*<Pagination dataSet={dataSet} />*/}
        <a className="btn btn-new" onClick={() => newSite()}><Icon color="white" icon="plus" /> {"\u00a0"}
          New Site
        </a>
        <Icon icon="file-excel" size="2x" title="Open export modal" onClick={() => excelExport()} />
      </ActionBar>

      {/*dataSet.loading ? "loading" : ""*/}
      {data.length == 0 ? "Search to show results." : null}
      <Tabular data={data} onClick={handleClick} pageSize={20} paginationLocation={"both"}>
        <TabularColumn data={(row) => row.friendly_id} title="Site #" sortCol="friendlyId" />
        <TabularColumn
          data={(row) => (
            <Link onClick={(e) => e.stopPropagation()} className="plain_link" to={"/ui/clients/" + row.customer_friendly_id}>
              {row.customer_name}
            </Link>
          )}
          title="Client"
          sortCol="customerName"
        />
        <TabularColumn data={(row) => row.site_name} title="Name" sortCol="name" />
        <TabularColumn data={(row) => row.address} title="Address Line 1" sortCol="address" />
        <TabularColumn data={(row) => row.address_2} title="Address Line 2" sortCol="address_2" />
        <TabularColumn data={(row) => row.city} title="City" sortCol="city" />
        <TabularColumn data={(row) => row.province} title="Province" sortCol="province" />
        <TabularColumn data={(row) => row.postal_code} title="Postal Code" sortCol="postal_code" />
        <TabularColumn data={(row) => (row.tax_name ? row.tax_name : "")} title="Sales Tax Setting" />
        <TabularColumn data={(row) => row.historical == 0 ? "Active" : "Inactive"} title="Status" sortCol="historical" />
        <TabularColumn data={(row) => row.tags} title="Tags" sortCol="tags" />
        <TabularColumn
          data={(row) => {
            return (
              <Link onClick={(e) => e.stopPropagation()} to={"/ui/sites/edit/" + row.friendly_id}>
                <Icon icon="edit" size="1x" />
              </Link>
            );
          }}
          title="Edit"
        />
      </Tabular>
    </div>
  );
}
