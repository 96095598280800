import React, { useState, useEffect, useRef, useContext }  from 'react';
import Bound, {DataContext} from './Bound';
import SaveToolbar from '@opidcore/components/SaveToolbar';
import {CurrentModal} from '@opidcore/components/OpidApplication';
import { useRouteMatch } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * 
 * @param {} props 
 * @returns
 * 
 * @example
 * if you are creating a new record, __new:true will force delta to be all columns passed in
 * __dataUID can be set to update any listening data caches 
 */
export default function BoundCommit(props){
	const boundContext = useContext(DataContext);
	const initialRecord = useRef(props.initialValue);
	const boundRef = useRef({_myMagic: undefined});
	const match = useRouteMatch();
	const inAModal = useContext(CurrentModal);
	const [saveMessage, setSaveMessage] = useState("");
	
	useEffect( ()=>{
		if (props.stealBound){
			props.stealBound(boundRef);
		}
		
		return ()=>{
			//unregister my bound because I am unmounting
			if(props.boundId){
				APP.unregisterBoundMagic(props.boundId);
				
				//unregister my children as well
				_.forEach(APP.registeredBoundMagics, (rbm, rbmKey)=>{
					if(rbmKey.indexOf(props.boundId + "|") > -1){
						//console.log("unregistering bound commit " + rbmKey);
						APP.unregisterBoundMagic(rbmKey);
					}
				});
			} else {
				//we don't have a way of knowing if something needs unregistering
			}
		}
	}, [] );
	
	
	useEffect(()=>{
		if(Array.isArray(props.initialValue)){
			initialRecord.current = {...props.initialValue[0]};
		} else {
			initialRecord.current = {...props.initialValue};
			if(props.initialValue == undefined){
				//alert("You shound send me an initialValue");
			}
		}

		//boundRef.current._myMagic.to = initialRecord.current;
		boundRef.current.replaceMagicTo(initialRecord.current);
	}, [props.initialValue]);
	
	useEffect(()=>{
		console.log('BoundCommit.js: useEffect(): ' + APP.getState("displayMessage") + " " + window.FETCH_REMAINING);
		//setSaveMessage(APP.getState("displayMessage") + "" + window.FETCH_REMAINING);
		
	}, [APP.getState("displayMessage"), window.FETCH_REMAINING]);

	const checkContractTerm = (term)=>{
		const termInt = parseInt(term);		
		if(! termInt || termInt <=0)
		{ return false;	}
		return true;		
	}

	const checkContractRequiredFields = (props) =>{
		let requiredOK = true;
		if(props.commit.source === "ClientContract" || props.commit.source === "VendorContract"){			
			const expiryPeriod = parseInt(props.to.to.expiryPeriod);
			if(!expiryPeriod || expiryPeriod <= 0){
				APP.alert("Failed to Save. Please enter a positive numeric value for Expiry Warning Period field.");
				requiredOK = false;
			}
			if(!checkContractTerm(props.to.to.contractTerm)){
				APP.alert("Failed to Save. Please enter a positive numeric value for the Term field");   
				requiredOK =  false; 
			}			
		}
		return requiredOK;		
	}

	const isNameFormatCorrect = (name) =>{
		let regexp = "^[a-zA-Z0-9 ]{0,}$"		
		return name.match(regexp) != null; 
	}

	const isEmailFormatCorrect = (email) =>{ 
		//let regexp = ".+@.+\\..+"	
		let regexp = "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$";  
		return email.match(regexp) != null; 
	}

	const checkContactFieldsFormat = (props) => {
		let contactFieldsOK = true; 
		if(props.commit.source === "CustomerContact"){
			if(!isNameFormatCorrect(props.initialValue.to.firstName) || !isNameFormatCorrect(props.initialValue.to.lastName)){
				APP.alert("Failed to Save. Please use the correct format for the name");
				contactFieldsOK = false;
			}
			if(props.initialValue.to.email && !isEmailFormatCorrect(props.initialValue.to.email)){
				APP.alert("Failed to Save. Please use the correct format for the email"); 
				contactFieldsOK = false;

			}
			if(!props.initialValue.to.email && ! props.initialValue.to.phone && ! props.initialValue.to.cellPhone){
				APP.alert("Failed to Save. Either email, phone number, or mobile number must be entered for the contact.");   
				contactFieldsOK = false; 
			}
			
		}
		return contactFieldsOK; 
	} 

 
	const doSave = ()=>{		
		if (props.commit && !props.commitWithParent && checkContractRequiredFields(props) && checkContactFieldsFormat(props) ){
			const delta = {... _.pick(props.initialValue, props.forceSaveProps), ...boundRef.current._myMagic.getDelta()};
			const requiredOk = boundRef.current._myMagic.requiredFieldsOk();  
			
			if(!requiredOk){
				APP.alert("Failed to save. Please fill in all required fields.");
				throw new Error("Failed to save. Please fill in all required fields."); 
			}

			const commitHappened = props.commit(boundRef.current._myMagic.getIdOr(0), delta, boundRef.current._myMagic.to, boundRef.current._myMagic );

			if (commitHappened && commitHappened.then){
				return commitHappened.then((r)=>{ 
					if(props.dataSet && !Array.isArray(r.result)){
						props.dataSet.replace(r.result, "id");					
					}
					
					//check for modals with the same url and minimize them
					_.each(_.filter(APP.instance.state.modals, {path: match.url}), (modal)=>{
						if(match.params.id == 0 || boundRef.current._myMagic.to.id == 0 || props.closeOnCommit){
							//modal was a new record so we need to get rid of it
							APP.instance.closeModal(modal.id);
						} else {
							//modal was an existing record so we can just minimize it
							APP.instance.minimizeModal(modal.id);
						}
					});

					if(props.afterSave != undefined){
						props.afterSave(r.result);
					}
					
					if (r && r.result){
						return r.result;	
					}				
				});
			}else{				
				APP.instance.closeModal(inAModal.id);

				if(props.afterSave != undefined){
					props.afterSave(boundRef.current._myMagic.to);
				}
			}
			
		}else{
			//APP.alert("you should really set a commit prop");
		}
	};
 
	boundRef.current.__boundCommitDoSave = doSave;
	
	let toolbar = <SaveToolbar handleSave={doSave} message={saveMessage} key="savetoolbar" saveVerb={props.saveVerb}>{props.toolbar}</SaveToolbar>;
	if(props.commitWithParent || props.skipSave){
		toolbar = undefined;
	}

	let children = [];
	if(toolbar != undefined){
		if(!Array.isArray(props.children)){
			children = [props.children];
		} else {
			children = [...props.children];
		}
		children.push(toolbar);
	} else {
		children = props.children;
	}

	const disabled = props.disabled || props.skipSave || false;
	
	return <Bound ref={boundRef} className={props.className} disabled={disabled} flex={props.flex} listenTo={props.listenTo} to={props.to? props.to : initialRecord.current} onChange={props.onChange} commit={props.commit} commitWithParent={props.commitWithParent} boundId={props.boundId} permissions={props.permissions}>
		{children}
	</Bound>;
}

BoundCommit.propTypes = {
	boundId: PropTypes.string,
	initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	commit: PropTypes.func,
	commitWithParent: PropTypes.bool,
	flex: PropTypes.bool,
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
	dataSet: PropTypes.object,
	forceSaveProps: PropTypes.array,
	saveVerb: PropTypes.string,
	to: PropTypes.object,
	closeOnCommit: PropTypes.bool,
	afterSave: PropTypes.func,
	stealBound: PropTypes.func,
	className: PropTypes.string,
	permissions: PropTypes.object,
	skipSave: PropTypes.bool,
	disabled: PropTypes.bool
}