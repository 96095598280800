import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Bound,
  BoundView,
  Button,
  DatePicker,
  FlexRow,
  Icon,
  InputSelect,
  InputText,
  InputToggleSwitch,
  Loading,
  TagPicker,
  UnlockToEdit,
  useMagic
} from "@opidcore/components";
import ActionBar from "@opidcore/components/ActionBar";
import BoundCommit from "@opidcore/components/BoundCommit";
import { EndDatePicker, TermPicker } from "@opidcore/components/DatePicker";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useData } from "@opidcore/hooks/WTF";
import _ from "lodash";

import { useParams } from "react-router-dom";
import { File, LinesThing, NiceBox, NiceBoxContainer } from "../../components";
import { NiceBoxSection } from "../../components/NiceBox";
import ChangeLogs from "../ChangeLog/View";
import Notes from "../Notes";
import { ContractStatusLight } from "../StatusLight";
import Tasks from "../Tasks";

export default function VendorEdit(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const [data, dataSet] = useData("vendor_contract", params.id, extra);

  const refreshDataset = () => {
    APP.central.Util.clearCache(dataSet.uid).then((r) => {
      dataSet.fetch();
    });
  };

  const openEditContract = () => {
    APP.instance.createModal(<EditVendorContractDetails data={data} dataSet={dataSet} afterSave={refreshDataset} />, { modal_name: "Vendor Contract #" + params.id });
  };

  const deleteContract = (id) => {
    APP.confirm("Are you sure?", (res) => {
      if (res == true) {
        APP.alert("Not yet implemented");
      }
    });
  };

  return (
    <div key="edit_vendor_contract">
      <h2 style={{ flex: "auto" }}> Vendor Contract</h2>
      <ActionBar model={data} showNotes={true} showTasks={true}>
        <ContractStatusLight contract={data} />
        <div style={{ flex: "auto" }} />
        {data.__type ? <Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} /> : <Loading/>}
        {/*<Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} />*/}
        <TagPicker />
        <Notes model={data} />
        <Tasks model={data} />
        <ChangeLogs model={data}/>
      </ActionBar>

      {data.__type ? <DisplayVendorContract vendorContract={data} /> : <Loading/>}

      <h2>Contract Lines</h2>
      <LinesThing lines={data.lines} groups={["client", "site", "service"]} />
      {data.lines && data.lines.length && data.lines.length > 0 ? null : <Button onClick={() => deleteContract(data.id)}>Delete Contract</Button>}
    </div>
  );
}

const EditVendorContractDetails = ({ data, dataSet, afterSave = undefined }) => {
  const [expiryLookups, expiryLookupsDS] = useData("lookup;vendorContractExpiryStatus", { name: "vendorContractExpiryStatus" });
  const [haulerNoticeLookups, haulerNoticeLookupsDS] = useData("lookup;haulerNoticePeriod", { name: "haulerNoticePeriod" });
  const isActive = (status) => {
    return status == "active";
  };

  const [showTermDate, setShowTermDate] = useState(!isActive(data.status));
  const [contract, setContract] = useState(data);
  const [contractStatusBool, setContractStatusBool] = useState({ status: isActive(data.status) });
  const [expiryOpts, setExpiryOpts] = useState({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month to Month" });
  const [haulerNoticeOpts, setHaulerNoticeOpts] = useState({
    30: "30",
    60: "60",
    90: "90",
    120: "120",
    180: "180",
    noAutoRenewal: "No Auto Renewal",
    noContract: "No Contract",
    "30dayOut": "30 Day Out",
  });
  
  const bound = useMagic({});

  useEffect( ()=>{
    bound.magicalState("status", (s)=>{
      setShowTermDate(!isActive(s));
    } );
    
  }, [bound]);

  useEffect(() => {
    updateOpts();
  }, [expiryLookupsDS.loading, haulerNoticeLookupsDS.loading]);

  useEffect(() => {
    updateOpts();
  }, [contract]);

  useEffect(() => {
    setContract(data);
    bound.replaceTo(data);
    setContractStatusBool({ status: isActive(data.status) });
  }, [data]);

  const doAfterSave = () => {
    APP.central.VendorContract.fetch(contract.id).then((r) => {
      if (r.status == "ok") {
        setContract(r.result);
        bound.replaceTo(r.result);
      }
      if (afterSave) {
        afterSave();
      }
    });
  };

  useEffect(() => {
    doAfterSave();
  }, []);

  const updateOpts = () => {
    if (expiryLookups && expiryLookups.length > 0 && bound.magicalGet("expiryStatus")) {
      const statusOpt = _.find(expiryLookups, { key: bound.magicalGet("expiryStatus") });
      if (statusOpt && statusOpt.key) {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month", [statusOpt.key]: statusOpt.label });
      } else {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month" });
      }
    }
    
    if (haulerNoticeLookups && haulerNoticeLookups.length > 0 && bound.magicalGet("hauler_notice_period")) {
      const haulerNoticeOpt = _.find(haulerNoticeLookups, { key: bound.magicalGet("hauler_notice_period") });
      if (haulerNoticeOpt && haulerNoticeOpt.key) {
        setHaulerNoticeOpts({
          30: "30",
          60: "60",
          90: "90",
          120: "120",
          180: "180",
          noAutoRenewal: "No Auto Renewal",
          noContract: "No Contract",
          "30dayOut": "30 Day Out",
          [haulerNoticeOpt.key]: haulerNoticeOpt.label,
        });
      } else {
        setHaulerNoticeOpts({
          30: "30",
          60: "60",
          90: "90",
          120: "120",
          180: "180",
          noAutoRenewal: "No Auto Renewal",
          noContract: "No Contract",
          "30dayOut": "30 Day Out",
        });
      }
    }
  };

  const doOnStatusChange = (s) => {
    bound.magicalSet("status",  s ? "active" : "inactive");
    updateOpts();
  };

  return (
    <Fragment>
      <div>
        <FlexRow>
          <h2>{contract && contract.niceName ? contract.niceName : null}</h2>
          <Bound to={contractStatusBool}>
            <InputToggleSwitch
              field="status"
              name={(status) => {
                return status == undefined || status == false ? "Inactive" : "Active";
              }}
              onChange={doOnStatusChange}
            />
          </Bound>
        </FlexRow>
        <ContractStatusLight contract={contract} />
      </div>
      <BoundCommit
        to={bound}
        dataSet={dataSet}
        commit={APP.central.VendorContract.saveChanges}
        afterSave={doAfterSave}
        boundId={"editVendorContractDetailsBound"}
      >
        <UnlockToEdit>
          <InputText field="friendlyId" name="#" />
        </UnlockToEdit>
        {showTermDate ? (
          <Fragment>
            <DatePicker field="terminationDate" name="Termination Date" required />{" "}
            <InputText field="terminationNote" name="Termination Note" inputType="textarea" required />
          </Fragment>
        ) : null}
        <DatePicker field="effectiveFrom" name="Start" required={true} />
        <TermPicker field="contractTerm" startField="effectiveFrom" endField="effectiveTo" required={true} />
        <EndDatePicker termField="contractTerm" startField="effectiveFrom" field="effectiveTo" name="End" required={true} />
        <InputText field="expiryPeriod" inputType="number"  name="Expiry Warning Period (days)" />  
        <InputSelect options={expiryOpts} name="Expiry Status" field="expiryStatus" showEmpty={true} />
        <InputSelect options={haulerNoticeOpts} name="Hauler Notice Period" field="haulerNoticePeriod" defaultValue="30dayOut"/>
        {/*<InputText field="internalNotes" name="Notes" inputType="textarea" />*/}
        {/*<InputText field="contractIssues" name="Contract Issues" inputType="textarea" />*/}
        <InputText field="externalVendorContractNumber" name="External Contract Number" />
        <InputText field="paymentTermsNetDays" name="Vendor Payment Terms" children={<em>Leave blank for Xero terms or net 60 day default.</em>} />
      </BoundCommit> 
    </Fragment>
  );
};

export { EditVendorContractDetails };

export function DisplayVendorContract(props) {
  const [files, setFiles] = useState([]);

  if (props.vendorContract == undefined) {
    return null;
  }

  const openAnnotations = (md5) => {
    APP.alert("hey " + md5);
  };

  return (
    <div className="edit-vendor-contracts">
      <Bound to={props.vendorContract} boundId="vendorContractNiceBlocks" disableEditing={true}>
        <NiceBoxSection>
          <NiceBoxContainer>
            <h4>Vendor Info</h4>
            <NiceBox label="Vendor #">
              <BoundView boundTo="vendor" key="vendor-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Vendor">
              <BoundView boundTo="vendor" key="vendor-name">
                <InputText field="name" disabled={true} />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>

          <NiceBoxContainer>
            <h4>Contract Info</h4>
            <NiceBox label="Vendor Contract #">
              <BoundView key="contract-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Start">
              <BoundView key="contract-effectiveFrom">
                <DatePicker field="effectiveFrom" />
              </BoundView>
            </NiceBox>

            <NiceBox label="End">
              <BoundView key="contract-effectiveTo">
                <DatePicker field="effectiveTo" />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>
        </NiceBoxSection>

        <File showHistoricalSet={true} onFileList={(files) => setFiles(files)} />
      </Bound>
    </div>
  );
}

