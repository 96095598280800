import React, { Fragment, useContext, useEffect, useState } from "react";
import EditService, { ContractLines, LineHistory } from "../UI/Service/EditService";
import JasonBlocks from "./JasonBlocks";
import { Util } from "@opidcore/Util";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import moment from "moment";
import { NiceBox, NiceBoxContainer } from "./NiceBox";
import { AccessControl, Button, Icon, InputSelect, Loading, SaveToolbar, UnlockToEdit } from "@opidcore/components";
import _ from "lodash";

export default function SupportServiceChange({ bound }) { 
  const site = bound.magicalGet("site", null);
  const caseId = bound.magicalGet("id", null);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);

  const [serviceInfoBlocks, setServiceInfoBlocks] = useState(null);
  const [griddedContractLines, setGriddedContractLines] = useState(null);

  const [serviceChange, setServiceChange] = useState(null);

  if (site == null) {
    return <span>No Site On Case</span>;
  }

  // Fetch Services for Site
  useEffect(() => {
    if (site == null || caseId == null) {
      return;
    }

    APP.central.Service.fetchServicesForSite(site.id).then((fetchServicesForSite) => {
      setServices(fetchServicesForSite.result);
    });
  }, [site]);

  // Fetch Service Change for Case
  useEffect(() => {
    APP.central.ServiceChange.fetchForCase(caseId).then((fetchForCase) => {
  
      if (fetchForCase.result == null) {
        return;
      }
      setServiceChange(fetchForCase.result);
      doSetSelectedService(null, null, fetchForCase.result.serviceId, null);
    });
  }, [services]);


  const openServiceChangeModal = () => {
    if (selectedService == null) {
      return;
    }

    setLoading(true);

    APP.central.JasonBlocks.fetchServiceEditInfoBlocks(selectedService.id, selectedService.customer, selectedService.site.id).then(
      (fetchServiceEditInfoBlocks) => {
        APP.central.ServiceContract.fetchGriddedContractLines({ serviceId: selectedService.id, siteId: selectedService.site.id }, "", "").then(
          (fetchGriddedContractLines) => {
            setLoading(false);
            setServiceInfoBlocks(_.cloneDeep(fetchServiceEditInfoBlocks.result));
            setGriddedContractLines(_.cloneDeep(fetchGriddedContractLines.result));

            APP.instance.createModal(
              <EditService
                id={selectedService.id}
                additionalInfo={{
                  isServiceChange: true,
                  serviceInfoBlocks: fetchServiceEditInfoBlocks.result,
                  griddedContractLines: fetchGriddedContractLines.result,
                  caseId: caseId,
                  existingServiceChange: serviceChange ? true : false,
                  doMakeChanges: doServiceChange,
                }}
              />,
              {
                modal_name: "Service Change: " + selectedService.friendlyId,
              }
            );
          }
        );
      }
    );
  };

  const doServiceChange = (caseId, changes, theServiceInfoBlocks, theGriddedContractLines) => {
    //console.log("HEY CESAR snapshot theServiceInfoBlocks:", theServiceInfoBlocks);
    //console.log("HEY CESAR snapshot theGriddedContractLines:", theGriddedContractLines);
    //console.log("HEY CESAR changes:", changes);
    //console.log("HEY CESAR caseId:", caseId);

    return APP.central.ServiceChange.createOrUpdateServiceChange(
      theServiceInfoBlocks.serviceId,
      caseId,
      theServiceInfoBlocks,
      theGriddedContractLines,
      changes
    ).then((createOrUpdateServiceChange) => {
      //console.log("HEY CESAR createOrUpdateServiceChange.result", createOrUpdateServiceChange.result);
      setServiceChange(createOrUpdateServiceChange.result);
    });
  };

  const openViewServiceChangeModal = () => {
    APP.instance.createModal(<ViewApplyServiceChange serviceChange={serviceChange} setServiceChange={setServiceChange} />, {
      modal_name: "View Apply Service Change: " + serviceChange.id,
    });
  };

  const doSetSelectedService = (to, field, val, context) => {
    /*console.log(
      "HEY CESAR setting selected service to",
      services.find((s) => s.id == val)
    );*/
    setSelectedService(services.find((s) => s.id == val));
  };

  const viewApplyServiceChangeButtonText = serviceChange && serviceChange.status != "approved" ? "View/Apply Service Change" : "View Applied Service Change";

  return (
    <div>
      <hr />

      <h2>Service Change</h2>
      <UnlockToEdit unlocked={!(serviceChange && serviceChange.status == "approved")}>
        <InputSelect
          name="Service"
          options={_.fromPairs(services.map((s) => [s.id, s.friendlyId + ", " + s.cleanDescription]))}
          defaultValue={selectedService ? selectedService.id : null}
          onChange={doSetSelectedService}
        />
      </UnlockToEdit>
      {loading ? <Loading /> : null}
      <div className="serviceChangeButtons">
        {selectedService && !loading && (serviceChange ? serviceChange.status != "approved" : true) ? (
          <Button onClick={() => openServiceChangeModal()}> Edit Service Change </Button>
        ) : null}
        {serviceChange && !loading ? <Button onClick={() => openViewServiceChangeModal()}> {viewApplyServiceChangeButtonText} </Button> : null}
        {serviceChange && !loading && serviceChange.status == "approved" ? (
          <Icon onClick={() => APP.instance.createModal(<LineHistory serviceId={selectedService.id} />)} icon="history" size="2x" title="View Service History" />
        ) : null}
      </div>

      <hr />
    </div>
  );
}

// ============================== ViewApplyServiceChange ==============================

const ViewApplyServiceChange = ({ serviceChange, setServiceChange }) => {
  const thisModal = useContext(CurrentModal);

  //console.log("HEY CESAR serviceChange", serviceChange);

  // serviceChange.changes is an array of changeItems
  // changeItems are arrays of changes
  // changes are objects with a delta property and an id property (if not throw them out)
  // deltas are objects with a __type property (if not throw them out)
  //    and any number of other properties which are the actual changes,
  //    if the other properties are objects, they should be flattened? omitted?

  // create a map of unique changes by __type
  const changeMap = {};
  serviceChange.changes.forEach((changeItem) => {
    changeItem.forEach((change) => {
      if (change.delta.__type && change.id) {
        if (changeMap[change.delta.__type]) {
          changeMap[change.delta.__type][change.id] = change.delta;
        } else {
          changeMap[change.delta.__type] = {};
          changeMap[change.delta.__type][change.id] = change.delta;
        }
      }
    });
  });

  //console.log("HEY CESAR changeMap", changeMap);

  const getRecursiveList = (obj, prefix) => {
    return _.compact(
      _.map(obj, (val, key) => {
        if (key === "__type" || key === "__dataUID") {
          return null;
        }
        if (_.isObject(val)) {
          return getRecursiveList(val, prefix + "." + key);
        }
        return (
          <Fragment>
            <b>{_.startCase(_.trim(Util.deCamelCase(prefix + "." + key), "."))}:</b> {_.toString(val)}
            <br />
          </Fragment>
        );
      })
    );
  };

  const proposedChanges = _.map(changeMap, (change, type) => {
    return (
      <NiceBoxContainer className="serviceChangeNiceBoxContainer">
        <h4>{_.startCase(Util.deCamelCase(type))}</h4>
        {_.map(change, (delta, id) => {
          return <NiceBox label={"ID: " + id}>{getRecursiveList(delta, "")}</NiceBox>;
        })}
      </NiceBoxContainer>
    );
  });

  const applyServiceChanges = () => {
    //console.log("HEY CESAR WE APPLYING CHANGES");

    return APP.central.ServiceChange.approveServiceChange(serviceChange.id).then((approveServiceChange) => {
      return _.reduce(
        serviceChange.changes,
        (previousPromise, deltas) => {
          return previousPromise.then(() => {
            return APP.central.ServiceContract.saveBoundDeltas(serviceChange.serviceId, deltas).then((saveBoundDeltas) => {
              //console.log("HEY CESAR saveBoundDeltas result:", saveBoundDeltas);
            });
          });
        },
        Promise.resolve()
      ).then(() => {
        //console.log("HEY CESAR WE DONE APPLYING CHANGES");
        setServiceChange(approveServiceChange.result);
        APP.instance.closeModal(thisModal != undefined && thisModal.id != undefined ? thisModal.id : undefined);
      });
    });
  };

  return (
    <div>
      <h2>View/Apply Service Change</h2>

      <h3>Service Change Info</h3>
      <span>
        <b>Service Change ID:</b> {serviceChange.id}
        <br />
        <b>Service ID:</b> {serviceChange.infoBlock.service.friendlyId}
        <br />
        <b>Case ID:</b> {serviceChange.caseId}
        <br />
        <b>Snapshot Time:</b> {moment(serviceChange.changeDate).format("MM/DD/YYYY hh:mm:ss A")}
      </span>

      <hr />

      <h3>{serviceChange.status == "approved" ? "Applied Changes" : "Proposed Changes"}</h3>
      <div>{proposedChanges}</div>

      <hr />

      <h3>Service Snapshot Before</h3>
      <div key="edit_service" className="edit-service">
        <JasonBlocks altInfoBlock={serviceChange.infoBlock} serviceData={{ id: null }} customerId={null} siteId={null} editing={false} />
        <ContractLines lines={serviceChange.griddedThing.rows} serviceData={null} serviceDataSet={null} registeredGrids={null} isViewAdditionalThing={true} />
      </div>

      <AccessControl resource="Accounting">
        {serviceChange.status != "approved" ? <SaveToolbar handleSave={applyServiceChanges} saveVerb="Apply Service Changes" /> : null}
      </AccessControl>
    </div>
  );
};
