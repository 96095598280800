import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import Bound from '@opidcore/components/Bound'
import Button from '@opidcore/components/Button'
import Icon from '@opidcore/components/Icon'
import Loading from '@opidcore/components/Loading'
import { Tabular, TabularColumn } from '@opidcore/components/Tabular'
import { useNavBar } from '@opidcore/hooks/WTF'
import Converter from '../../../utilities/UnitConversion'

import PeriodSelect from "../../components/PeriodSelect"

import { NiceCurrency } from '../Nice'

import moment from 'moment'

export default function ClientHealth(props) {

    const [firstLoad, firstLoadSet] = useState(true)
    const [loading, loadingSet] = useState(true)
    const [data, dataSet] = useState([])
    const [group, groupSet] = useState([])
    const [structure, structureSet] = useState(0)
    const [periodRange, periodRangeSet] = useState(false)
    const [periodStart, periodStartSet] = useState(moment().format("YYYY-MM"))
    const [periodEnd, periodEndSet] = useState(moment().format("YYYY-MM"))
    const [monthTotalToggle, monthTotalToggleSet] = useState(false)
    const [revenueOrMargin, revenueOrMarginSet] = useState(true)

    const stateRef = useRef({})

    var startString = moment(periodStart).format("MMMM YYYY")
    var endString = moment(periodEnd).format("MMMM YYYY")

    var titleRange = startString + " - " + endString

    var monthDiff = moment(periodEnd).diff(moment(periodStart), "M")

    const nav = useNavBar("Client Health", [], { menu: ["clients", "clienthealth"] });

    const updateRef = (them, magic, key) => {
        if (periodRange) {
            switch (key) {
                case "start":
                    periodStartSet(stateRef.current.start)
                    break
                case "end":
                    periodEndSet(stateRef.current.end)
                    break
                default:
            }
        }
        else {
            periodStartSet(stateRef.current.start)
            periodEndSet(stateRef.current.start)
        }
    }

    const toggleRange = () => {
        if (periodRange) {
            periodEndSet(periodStart)
        }
        periodRangeSet(!periodRange)
    }

    /*  Structure is passed as an integer and determines what sort of grouping is used -
        0 : Customers as the highest level
        1 : Industry as the highest level
        2 : Regional Account Manager as the highest level
    */

    useEffect(() => {
        loadingSet(true)
        var rollingEnd = moment(periodEnd).subtract(1, "M").format("YYYY-MM")
        var rollingStart = moment(periodEnd).subtract(3, "M").format("YYYY-MM")
        if (periodRange) {
            APP.central.ClientHealth.testGroup(group, periodStart, periodEnd, structure, rollingStart, rollingEnd).then((r) => {
                dataSet(r.result.rows)
                loadingSet(false)
                firstLoadSet(false)
            })
        }
        else {
            APP.central.ClientHealth.testGroup(group, rollingStart, periodEnd, structure, rollingStart, rollingEnd).then((r) => {
                dataSet(r.result.rows)
                loadingSet(false)
                firstLoadSet(false)
            })
        }
    }, [group, periodStart, periodEnd, structure])

    const doExport = ()=>{
        var rollingEnd = moment(periodEnd).subtract(1, "M").format("YYYY-MM")
        var rollingStart = moment(periodEnd).subtract(3, "M").format("YYYY-MM")

        APP.central.ClientHealth.doExport(group, periodEnd, periodEnd, structure, rollingStart, rollingEnd).then((r) => {
            APP.socket.send(JSON.stringify(r));
            //dataSet(r.result.rows)
            //loadingSet(false)
            //firstLoadSet(false)
        })
    }
    function ClientDisplay(props) {
        const values = [...Array(monthDiff).keys()]

        let healthCols = []

        for (var i = 0; i < monthDiff + 1; i++) {
            const curMonth = moment(periodStart).add(i, "M")

            healthCols.push(<TabularColumn title={curMonth.format("YYYY-MMM")} data={(row) => row.health.allPeriods.hasOwnProperty(curMonth.format("YYYY-MM")) ? <RevenueMargin revenue={row.health.allPeriods[curMonth.format("YYYY-MM")]} margin={ row.health.totalMargin } link={("/ui/arinvoice/" + (row.ardate.invoiceDates[curMonth.format("YYYY-MM")]))} /> : null} />)
        }

        const openService = (serviceId) => {
            APP.instance.createModal("/ui/services/edit/" + serviceId, { modal_name: "Service #" + serviceId }, {});
        };

        var topLevel = null;
        switch (structure) {
            case 0: //Customer
                topLevel = null
                break
            case 1: //Industry
                topLevel = <TabularColumn title="Industry" data={(row) => <span style={{ color: row._level > 0 ? "lightgray" : "black" }}>{row.environmental_industry}</span>} />
                break
            case 2: //Regional Account Manager
                topLevel = <TabularColumn title="Manager" data={(row) => <span style={{ color: row._level > 0 ? "lightgray" : "black" }}>{row.manager_name}</span>} />
                break
            default:
                topLevel = null
        }
       
        return <div>
            {loading ? <div className="page-block"> <Loading /> Loading... </div> : null}
            <div className="page-block">
                <HealthMetrics data={props.data} />

                <Button style={{ marginTop:"30px"}} onClick={()=>doExport()}>Export</Button>  
            </div>
            <div className="page-block" style={{ fontSize: periodRange ? "16px" : null }}>
                <Tabular data={props.data}>
                    <TabularColumn data={(row) => { return ((row._level > 1 && structure == 0) || (row._level > 2 && structure != 0)) ? null : <span style={{ paddingLeft: (row._level * 2) + "em" }}><Icon onClick={() => ChangeGroups(row)} icon={group.includes(row._groupString) ? "minus" : "plus"} /></span> }} />
                    {topLevel}
                    <TabularColumn title="Client" data={(row) => row.customerCount >= 2 ? <span style={{ color: (row._level > 0 && structure == 0) ? "lightgray" : ((row._level > 1 && structure != 0) ? "lightgray" : "black") }}>{row.customerCount + " Clients"}</span> : <span style={{ color: (row._level > 0 && structure == 0) ? "lightgray" : ((row._level > 1 && structure != 0) ? "lightgray" : "black") }}>{row.customer_name}</span>} />
                    <TabularColumn title="Site" data={(row) => ((row._level >= 1 && structure == 0) || (row._level >= 2 && structure != 0)) ? <span style={{ color: (row._level > 1 && structure == 0) ? "lightgray" : ((row._level > 2 && structure != 0) ? "lightgray" : "black") }}>{row.site_name}</span> : (row.siteCount > 1 ? <span style={{ color: (row._level > 1 && structure == 0) ? "lightgray" : ((row._level > 2 && structure != 0) ? "lightgray" : "black") }}>{row.siteCount + " Sites"}</span> : <span style={{ color: (row._level > 1 && structure == 0) ? "lightgray" : ((row._level > 2 && structure != 0) ? "lightgray" : "black") }}>{"Single Site"}</span>)} />
                    <TabularColumn title="Service" data={(row) => ((row._level >= 2 && structure == 0) || (row._level >= 3 && structure != 0)) ? <div className="service-link"> {row.service} <Icon onClick={() => openService(row.service)} icon="search-plus" /> </div> : null} />
                    <TabularColumn title="System" data={(row) => row.customerCount >= 2 ? <span style={{ color: (row._level > 0 && structure == 0) ? "lightgray" : ((row._level > 1 && structure != 0) ? "lightgray" : "black") }}>{row.customerCount + " Clients"}</span> : <span style={{ color: (row._level > 0 && structure == 0) ? "lightgray" : ((row._level > 1 && structure != 0) ? "lightgray" : "black") }}>{row.billing_system}</span>} />
                    {periodRange ? healthCols : null}
                    <TabularColumn title={periodRange ? "Total Revenue" : "Monthly Spend"} data={(row) => { return ((periodRange) || (structure != 0 && row._level == 0)) ? <NiceCurrency>{row.health.totalSpend}</NiceCurrency> : (row.invoiceIds[0] == null ? "N/A" : <Link to={"/ui/arinvoice/" + row.invoiceIds[0]}><NiceCurrency>{row.health.monthlySpend}</NiceCurrency></Link>) }} />
                    {periodRange ? null : <TabularColumn title="Rolling Average" data={(row) => <RollingSpend spend={row.health.monthlyRollingSpend} percent={row.percentChange} />} />}
                    <TabularColumn title="Margin" data={(row) => <NiceCurrency>{row.health.monthlyMargin}</NiceCurrency>} />
                    {/* --- Don't think this one is needed --- periodRange ? null : <TabularColumn title="Period" data={(row) => row.health.billingPeriod} /> */}
                </Tabular>
            </div>
        </div>
    }

    function HealthMetrics(props) {
        var totalClients = 0
        var totalSites = 0
        var totalRevenue = 0
        var totalWarnings = 0
        var monthTotals = {}
        for (var i = 0; i < monthDiff + 1; i++) {
            const curMonth = moment(periodStart).add(i, "M").format("YYYY-MM")
            monthTotals[curMonth] = 0;
        }
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i]._level == 0) {
                totalRevenue += props.data[i].health.monthlySpend
                totalClients += props.data[i].customerCount
                totalSites += props.data[i].siteCount
                if (props.data[i].percentChange > 50 || props.data[i].percentChange < -50) {
                    totalWarnings++
                }
                // Month totals, this will slow things down significantly, probably better to do it on the back end
                if (monthTotalToggle) {
                    for (var j = 0; j < monthDiff + 1; j++) {
                        const curMonth = moment(periodStart).add(j, "M").format("YYYY-MM")
                        if (props.data[i].health.allPeriods.hasOwnProperty(curMonth) && props.data[i] != undefined) {                           
                            monthTotals[curMonth] = monthTotals[curMonth] + props.data[i].health.allPeriods[curMonth]
                        }
                    }
                }

            }
        }

        let extraBlocks = []
        var j = 0;

        for (var i = 0; i < monthDiff + 1; i++) {
            const curMonth = moment(periodStart).add(i, "M").format("YYYY-MM")
            extraBlocks.push(<LargeStatBlock value={monthTotals[curMonth]} icon="money-bill" symbol="$" label={moment(curMonth).format("YYYY-MMM")} />)
        }
    

        return <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="flex">
                <LargeStatBlock value={totalClients} icon="user" label="Clients" />
                <LargeStatBlock value={totalSites} icon="store" label="Sites" />
                <LargeStatBlock value={totalRevenue.toFixed()} icon="money-bill" symbol="$" label={periodRange ? "Total Revenue" : "Monthly Revenue"} />
                <LargeStatBlock value={totalWarnings} icon="exclamation-triangle" label="Warnings" />
            </div>
            <div className="flex">
                {monthTotalToggle ? extraBlocks : null}
            </div>
        </div>
    }

    function RevenueMargin(props) {
        if (revenueOrMargin) {
            return <div style={{ display: "flex", flexDirection: "column" }}>
                <Link to={props.link}><NiceCurrency style={{ fontSize: "16px" }}>{props.revenue}</NiceCurrency></Link>
                <NiceCurrency style={{ fontSize: "12px" }}>{props.margin}</NiceCurrency>
            </div>
        }
        else {
            return <div style={{ display: "flex", flexDirection: "column" }}>
                <Link to={props.link}><NiceCurrency style={{ fontSize: "16px" }}>{props.margin}</NiceCurrency></Link>
                <NiceCurrency style={{ fontSize: "12px" }}>{props.revenue}</NiceCurrency>
            </div>
        }
    }

    function ChangeGroups(row) {
        if ((row._level < 2 && structure == 0) || (row._level < 3 && structure != 0)) {
            let groupVar = [...group]
            if (!groupVar.includes(row._groupString)) {
                groupVar.push(row._groupString)
                groupSet(groupVar)
            }
            else {
                var index = groupVar.indexOf(row._groupString)
                if (index != -1) {
                    groupVar.splice(index, 1)
                }
                groupSet(groupVar)
            }
        }
    }

    function ExpandAllGroups(level) {
        let groupVar = [...group]
        for (var i = 0; i < data.length; i++) {
            if (data[i]._level == level && !groupVar.includes(data[i]._groupString)) {
                groupVar.push(data[i]._groupString)
            }
        }
        groupSet(groupVar)
    }

    function UpdateStructure(val) {
        groupSet([])
        loadingSet(true)
        structureSet(val)
    }
    console.log(Converter.Convert(1.0, "v", "y", "m"))
    return <div>
        <div className="page-block">
            {periodRange ? titleRange : endString}
        </div>
        <div className="page-block">
            <div className="flex">
                <Button className="button-margin-bottom" onClick={() => monthTotalToggleSet(!monthTotalToggle)}>{monthTotalToggle ? "Hide Month Totals" : "Show Month Totals"}</Button>
                {periodRange ? <Button onClick={() => revenueOrMarginSet(!revenueOrMargin)}>{revenueOrMargin ? "Show Margin" : "Show Revenue"}</Button> : null}
                <Button className="button-margin-bottom" onClick={() => ExpandAllGroups(0)}>Expand {structure != 0 ? "Top Level" : "Clients"}</Button>
                <Button className="button-margin-bottom" onClick={() => ExpandAllGroups(1)}>Expand {structure != 0 ? "Clients" : "Sites"}</Button>
                {structure != 0 ? <Button onClick={() => ExpandAllGroups(2)}>Expand Sites</Button> : null}
            </div>      
            <div className="flex">
                <Button onClick={() => UpdateStructure(0)}>Show All</Button>
                <Button onClick={() => UpdateStructure(1)}>Industry</Button>
                <Button onClick={() => UpdateStructure(2)}>Manager</Button>
            </div>
            <div className="flex">
                <Bound to={stateRef.current} onChange={updateRef}>
                    <PeriodSelect field="start" label="Period Start" />
                    {periodRange ? <PeriodSelect field="end" label="Period End" /> : null}
                    <Button onClick={toggleRange} width="300px">{periodRange ? "Multi-Month" : "Single Month"}</Button>
                </Bound>
            </div>
        </div>
        {(firstLoad) ? <Loading /> : <ClientDisplay data={data} level="site" levels={["client", "site"]} />}
    </div>
}

function LargeStatBlock(props) {
    let percent = null;
    if (props.units == "%") {
        percent = "%";
        props.units = null;
    }
    return <div className="stat-block-large">
        <div className="stat"><Icon icon={props.icon} fType={props.fType || "fal"} color={props.color} size="2x" /></div>
        <div className="stat-value"> {props.symbol} {Number(props.value).toLocaleString()} {percent} {props.units ? <span className="stat-units">{props.units}</span> : null}</div>
        <div className="stat-label">{props.label}</div>
    </div>
}

function RollingSpend(props) {
    var warning = null;
    if (props.percent > 50 || props.percent < -50) {
        warning = <Icon icon="exclamation-triangle" colour="#b83030" />
    }
    else if (props.percent > 10 || props.percent < -10) {
        warning = <Icon icon="exclamation-triangle" colour="#f5c542" />
    }
    return <div title={Math.round(Math.abs(props.percent)) + "% " + (props.percent > 0 ? "increase" : "drop")}>
        <NiceCurrency>{props.spend}</NiceCurrency> {warning}
    </div>
}