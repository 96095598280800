import * as types from '../constants/ActionTypes';
import * as _ from 'lodash';



export function central() {
	return {
		type: "CENTRAL_METHOD",
	}
}

export function API() {
	return {
		type: "CENTRAL_API",
		api: API
	}
}

export function processing() {
	return {
		type: "CENTRAL_PROCESSING"
	}
}

export function processingDone() {
	return {
		type: "CENTRAL_PROCESSING_DONE"
	}
}


export function refreshDataNeeded(view) {
	return {
		type: "CENTRAL_REFRESH_NEEDED",
		view: view
	}
}

export function refreshDataDone(form) {
	return function (dispatch) {
		setTimeout(function () { dispatch({ type: "CENTRAL_REFRESH_CLEAR" }) }, 2000);

		dispatch({ type: "CENTRAL_REFRESH_DONE", view: form })

	}
}


export function refreshDataClear(view) {
	return {
		type: "CENTRAL_REFRESH_CLEAR",
		view: view
	}
}



export function newModel(statePath, model) {

	return {
		type: "NEW_MODEL",
		statePath: statePath,
		model: model
	}
}



export function modifyProps(dispatch) {
	var _apis = {

		updateModel: (statePath, model, attribute, value) =>
			dispatch({
				type: types.UPDATE_MODEL,
				statePath,
				model,
				attribute,
				value
			}
			)
	};

	const store = window.__STORE;

	_.each(window._API, function (def) {

		if (_apis[def.name] == undefined) {
			_apis[def.name] = {};
		}

		_.each(def.methods, function (meth) {

			var action = { type: "CENTRAL_API_" + def.name + "_" + meth.name };

			_apis[def.name][meth.name] = (...args) => {



				let argCount = 0;

				let body = {};
				let cancellingRequest = false;

				//incase you pass in a hash when you were supposed to give values
				if (args == "CANCEL_ALL_REQUESTS_NOW"){
					cancellingRequest = true;
					_.each(window.CRAPPY_FETCHING[meth.name], (theThing, theNow)=>{
						if (theThing.status == "pending"){
							window.CRAPPY_FETCHING[meth.name][theNow].status = "cancelled";
						}
					});
					return Promise.reject();
				}else if (_.isObject(_.first(args)) && args[0][meth.argumentNames[0]] != undefined) {
					body = _.first(args);
				} else {
					meth.argumentNames.map((arg) => {
						body[arg] = args[argCount++];
					});
				}
				action.method = meth;

				if (meth.name == "save" || meth.name == "deleteModel" || (meth.argumentNames && meth.argumentNames[0] == "jsonBody")) {
					body = args[0];
				}

				if (meth.argumentNames.length == 0 && args[0] != null) {
					body = args[0];
				}
				
				let loggedInUser = sessionStorage.getItem("loggedInUser")? sessionStorage.getItem("loggedInUser"): localStorage.getItem("loggedInUser");
				let jwtToken = localStorage.getItem("jwtToken");
				if(loggedInUser != null){
					body.uin = loggedInUser;
				}
				if(jwtToken != null){
					body.bin = jwtToken; 
				}
				

				var path = "/api/v1/" + def.name.toLowerCase() + "/" + meth.name;


				if (window.FETCH_REMAINING == null) {
					window.FETCH_REMAINING = 0;
				}

				action.execute = (now) => {
					if (window.FETCH_REMAINING == 0) {					
						APP.setState("displayMessage", "...");
					}
					window.FETCH_REMAINING++;
					if (window.CRAPPY_FETCHING == null){
						window.CRAPPY_FETCHING = {};
					}
					if (window.CRAPPY_FETCHING[meth.name] == null){
						window.CRAPPY_FETCHING[meth.name] = {};
					}

					if (window.CRAPPY_FETCHING[meth.name] == null){
						window.CRAPPY_FETCHING[meth.name] = {};
					}
					
					window.CRAPPY_FETCHING[meth.name][now] = {status: "pending"};
					
					//const bodyParams = JSON.stringify(body, (name,val)=>{ return name == "_ourMagic" ? null : val } );

					//should let us post empty values so that values can be unset
					//const bodyParams = JSON.stringify(body, (name, val) => { return name == "_ourMagic" || val === undefined ? null : val })
					const bodyParams = APP.stringify(body);
					return fetch(APIHOST + path, { method: "POST", body: bodyParams, credentials: 'include' })
						.then(response => {
							if(response.status === 401){
								APP.alert("Your login session is no longer valid. Press OK to login again.", ()=>{
									APP.central.TimedTokens.logout().then( ()=>{
										window.location = "/login";	 
									});		
								});  
							} 
							if (window.CRAPPY_FETCHING[meth.name][now].status == "cancelled"){
								return Promise.reject();
							}

							if (response.status !== 401 && !response.ok) {
								window.CRAPPY_FETCHING[meth.name][now].status = "error";								

								console.log("CentalAction.js: An error occured during a request to " + path);


								if (response.headers.get("content-type").indexOf("text") == 0) {
									response.text().then((t) => {
										setTimeout(() => {
											window.CRAPPY_FETCHING[meth.name][now].details = t;
											var title = "Server Error 500";

											var exception = { title: title, details: t, path: path, body: body };

											APP.store.dispatch({ type: 'CENTRAL_EXCEPTION', details: exception });

										}, 500);
									});
								} else {
									response.json().then(j => {
										window.CRAPPY_FETCHING[meth.name][now].details = j; 
										setTimeout(() => {

											var title = j.result.exception.split("\n")[0];

											var exception = { title: title, details: j.result, path: path, body: body, message: j.result._exception.cause.message };

											if(title.indexOf("RuntimeUserMessageException") > -1){
												APP.instance.openConfirmDialog(exception.message, "Error");
											} else {
												APP.store.dispatch({ type: 'CENTRAL_EXCEPTION', details: exception });
											}

										}, 500);
									});
								}

							}
							if (response.statusText == "OK") {
								return response.json();
							} else {
								return response;
							}
						})
						.then(response => {
							if(response.status !== 401){
								window.CRAPPY_FETCHING[meth.name][now].status = "complete";
								
								window.FETCH_REMAINING--;

								if (response.status == "ok") {
									action.response = response;

									if (dispatch) {
										if (window.FETCH_REMAINING == 0) {
											setTimeout(() => { dispatch({ type: 'CENTRAL_CLEAR_DISPLAY_MESSAGE' }) }, 2000);
										}

										dispatch(action);
									} else {
										if (window.FETCH_REMAINING == 0) {										
											APP.setState("displayMessage", null);
										}
										
									}
								}

								_.each(response.message, (msg) => {
									if (msg.indexOf("DATA_CACHE_UPDATE") == 0) {
										const dataUID = msg.split(" ")[1];
										if (dataUID) {
											const newRow = response.result[dataUID] || response.result;
											//window.APP.updateDataCache(dataUID, newRow);
											if (_.isArray(newRow)){
												_.each(newRow, (row)=>{ window.APP.updateDataCache(dataUID, row) });
											}else{
												window.APP.updateDataCache(dataUID, newRow);
											}
										} else {
											debugger;
										}
								}
							});
						}
							return response;

						});
				};


				//only cache if it returns to state, and isn't named fetch, and has a disable cache method. 

				if (meth.returnsToState && meth.cache == true) {
					const central_key = meth.returnsToState.split('.')[1];

					const existing = store.getState().central[central_key];
					const dirty = store.getState().central.__dirty[central_key];

					if (existing == null) {
						return action.execute();
					} else {
						return new Promise(function (resolve, reject) {
							const ret = {};
							ret.response = {};
							ret.response.result = existing;
							resolve(ret);
						});
					}
				}


				const now = Math.floor(performance.now())
				return action.execute(now);
				//dispatch(processing());

			}

			Object.defineProperty(_apis[def.name][meth.name], 'source', {
				value: def.name
			})

			Object.defineProperty(_apis[def.name][meth.name], 'method', {
				value: meth.name
			})
		});
	});


	_apis.clear = (...args) => {
		dispatch({ type: 'CENTRAL_CLEAR_STATE', path: args[0] });
	};


	_apis.clearErrors = (...args) => {
		APP.store.dispatch({ type: 'CENTRAL_CLEAR_STATE', path: 'errors' })
	};

	return { central: _apis };
}

export function alogout() {
	return function (dispatch) {

		fetch(APIHOST + "/api/v1/logout", { credentials: 'include' })
			.then(response => { return response.json() })
			.then(json => {
				//alert('ok you are logged out now');
				dispatch({ type: "CENTRAL_LOGGED_OUT" });
			});
	}
}

export function forgotPassword(emailAddress) {
	return function (dispatch) {
		fetch(APIHOST + "/api/v1/forgotPassword", {
			method: 'post',
			headers: {
				"Content-type": "text/plain; charset=UTF-8"
			},
			body: emailAddress,
			credentials: 'include'
		})
			.then(response => { return response.json() })
			.then(json => {
				dispatch({ type: "CENTRAL_FORGOT_PASSWORD", response: json });
			});
	}
}

export function downloadTimelog(personId, personRole, periodInterval, period) {
	return function (dispatch) {
		fetch(APIHOST + "/api/v1/download_timelogs", {
			method: 'post',
			headers: {
				"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
			},
			body: "personId=" + personId + "&personRole=" + personRole + "&periodInterval=" + periodInterval + "&period=" + period,
			credentials: 'include'
		})
			.then(response => { return response.blob() })
			.then((responseData) => { return responseData })
			.then(data => {
				dispatch({ type: "CENTRAL_DOWNLOAD", response: data, fileName: "StudentTimeLog " + period + ".pdf" });
			});
	}
}

