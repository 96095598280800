import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { DataContext } from '@opidcore/components';

const SimpleMonthPicker = ({field, className = "", label = undefined}) => {
    const boundMagic = useContext(DataContext);
    const [selected, setSelected] = useState(null);

    useEffect(()=>{
        boundMagic.magicalState(field, (value)=>{
            let defaultDate = null;
            if(value != "" && value != undefined){
                defaultDate = new Date(value.split("-"));
            }

            setSelected(defaultDate);
        });
    }, [boundMagic]);

    const setValue = (newValue) =>{
        if(newValue == ""){
            newValue = null;
        }

        if(newValue != null){
            newValue = moment(newValue.getTime()).format("yyyy-MM");
        }

        boundMagic.magicalSet(field, newValue);
    };

    return <div className={"field " + className}>
        {label != undefined? <label>{label}</label> : null}
        <DatePicker dateFormat={"yyyy-MM"} showMonthYearPicker selected={selected} onChange={setValue}/>
    </div>
}

export default SimpleMonthPicker;