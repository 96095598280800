import React, { useEffect } from "react";
import { AccessControl } from "@opidcore/components";
import { Tabular, TabularColumn } from "@opidcore/components/Tabular";
import { useData } from "@opidcore/hooks/WTF";

import CesarFilter from "../../components/CesarFilter";
import { TotalContractsCount } from "../Contract/ContractExpiryManagement";
import { CreateNewContract } from "../Customer/ClientContractList";

// This is the dashboard which will display contract expiry information for the user
// Team managers will be able to see all users
export default function AccountManagementDashboard(props) {
  const [data, dataset] = useData("contracts;", { method: "contractExpiryManagementList" });
  const me = APP.getWebSession();

  useEffect(() => {
    dataset.filter({ type: "client_contract" }, true);
  }, [dataset.loading]);

  // Refreshes dataset
  const refreshDataset = () => {
    APP.central.Util.clearCache(dataset.uid).then(() => {
      dataset.fetch();
    });
  };

  // Opens modal to create new client contract
  const newClientContract = () => {
    APP.instance.createModal(
      <CreateNewContract />,
      {
        modal_name: "New Client Contract",
      },
      {
        afterClose: () => refreshDataset(),
      }
    );
  };

  const handleClick = (row, e) => {   

    let linkPath = "/ui/vendorcontract/edit/";
    if (row.type == "client_contract") {
      linkPath = "/ui/clientcontract/edit/";
    }

    if (e.defaultPrevented == false) {
      // @ts-ignore
      APP.instance.createModal(linkPath + row.friendly_id, {
        modal_name: "Contract Preview",
      },
      {
        afterClose: () => refreshDataset(),
      });
    }
  };

  return (
    <div id="userDashboard" className="text-center container">
      <h1>Account Management Dashboard</h1>
      <h3>{me.fullname}</h3>

      <AccessControl resource="All AM Contracts">
        <CesarFilter columns={[{ column: "regional_account_manager", heading: "Account Manager" }]} dataSet={dataset} />
      </AccessControl>

      <TotalContractsCount data={data} />

      <Tabular data={data} defaultSort="effective_to" defaultSortOrder="asc" onClick={handleClick}>
        <TabularColumn title="Client" data={(row) => row.customer_name} />
        <TabularColumn title="Expiry Status" data={(row) => <StyledExpiryStatus status={row.expiry_status} />} />
        <TabularColumn title="Contract Expiry Date" data={(row) => row.effective_to} />
      </Tabular>
    </div>
  );
}

const StyledExpiryStatus = ({ status }) => {
  let statusString = status;

  switch (status) {
    case "expired":
      statusString = "Expired";
      break;
    case "expiringSoon":
      statusString = "Expiring Soon";
      break;
    case "current":
      statusString = "Current";
      break;
    case "renewed":
      statusString = "Renewed";
      break;
    case "lost":
      statusString = "Lost";
      break;
    case "monthToMonth":
      statusString = "Month to Month";
      break;
    case "autoRenewal":
      statusString = "Auto Renewal";
      break;
    default:
      break;
  }

  
  return <div className={"expiryStatus" + status}>{statusString}</div>;
};
