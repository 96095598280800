import React, { useContext, useEffect, useRef, useState } from "react";

import Util from '@opidcore/Util';
import { Bound, BoundDataContext, Button, DataContext, Grid, GridItem, HoverElement, Icon, InputAutoComplete, InputDecimal, InputMultiCheckbox, InputSelect, InputText, InputToggleSwitch, Loading, LookupInputSelect, Tabular, TabularColumn } from "@opidcore/components";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import GraphMax from "../../../utilities/GraphMax";
import IconNew from "../../components/IconNew";

import BoundLinkButton from "../../components/BoundLinkButton";

import { File, NiceBox, SelectClientSiteService, StaffSelect } from "../../components";
import { NiceBoxContainer } from "../../components/NiceBox";

import LookupStatusSelect from '@opidcore/components/LookupStatusSelect';
import { CurrentModal, RoutedDefaults } from "@opidcore/components/OpidApplication";
import _ from 'lodash';
import moment from "moment";
import { useParams } from "react-router-dom";
import { BarGraph } from "../../Portal/Throwaway";
import PeriodSelect from "../../components/PeriodSelect";
import { ContactList } from "../Customer/EditClient";
import { NiceMaterial, NiceNumber, NiceNumberWithUnits } from "../Nice";
import './junkstyles.css';

import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import FancyWeightInput from "../../components/FancyWeightInput";
import MultiThingSelect from "../../components/MultiThingSelect";
import Notes from "../Notes";
import Tasks from "../Tasks";

//comment to trigger a build

export default function EditSustainabilityNew(props) {

    let params = useParams()

    const [client, setClient] = useState(null)

    const [clientId, setClientId] = useState(0)
    const [inv, setInv] = useState({})
    const [loading, setLoading] = useState(true)
    const [siteList, setSiteList] = useState([])
    const [saveWarning, setSaveWarning] = useState(false)
    //const [currentData, setCurrentData] = useState(null)
    const [site, setSite] = useState(null)
    const [initialPeriod, setInitialPeriod] = useState(null)
    const [initialClient, setInitialClient] = useState(null)

    var d = new Date()
    var p = d.getFullYear().toString() + "-" + d.getMonth().toString() // Previous month

    const [period, setPeriod] = useState(p)

    const stateRef = useRef({ id: params.id, period: params.period })

    const extra = useContext(RoutedDefaults)

    useEffect(() => {
        setInitialClient(params.period)
        setInitialPeriod(params.id)
    }, [])

    useEffect(() => {
        if (params.id && params.period) {
            stateRef.current.client = params.id
            stateRef.current.period = params.period
            setClientId(params.id)
            setPeriod(params.period)
            if (clientId != 0 && clientId != NaN && clientId != undefined) {
                setLoading(true)
                if (params.limit) {
                    APP.central.Sustainability.getData(clientId, period, params.limit).then((r) => {
                        setInv(r.result)
                        //setCurrentData(_.clone(inv))
                        setSiteList(r.result.sites.map((site) => 
                            { 
                                return {id: site.id, name: site.name} 
                            }))
                        APP.central.Customer.fetch(clientId).then((r) => {
                            setClient(r.result)
                            setLoading(false)
                        })
                    })
                } else {
                    APP.central.Sustainability.getData(clientId, period, null).then((r) => {
                        setInv(r.result)
                        //setCurrentData(_.clone(inv))
                        setSiteList(r.result.sites.map((site) => 
                            { 
                                return {id: site.id, name: site.name} 
                            }))
                        APP.central.Customer.fetch(clientId).then((r) => {
                            setClient(r.result)
                            setLoading(false)
                        })
                    })
                }
            }
        }
    }, [])

    useEffect(() => {
        if (site != null) {
            if (site == "") {
                setLoading(true)
                APP.central.Sustainability.getData(clientId, period).then((r) => {
                    setInv(r.result)
                    setLoading(false)
                })
            } else {
                setLoading(true)
                APP.central.Sustainability.getDataForSite(clientId, period, site).then((r) => {
                    setInv(r.result)
                    setLoading(false)
                })
            }  
        } 
    }, [site])

    /*
    useEffect(() => { //first time load all data
        if (inv != null && typeof inv == "object") {
            var tempCopy = {...inv}
            setCurrentData(tempCopy)
        }
    }, [inv]) 
    */

    useEffect(() => {
        if (clientId != 0 && clientId != NaN && clientId != undefined) {
            setLoading(true)
            //window.location.href = "localhost:9000/ui/sustainabilityedit/" + clientId + "/" + period
            APP.central.Sustainability.getData(clientId, period, (params.limit ? params.limit : null)).then((r) => {
                setInv(r.result)
                //setCurrentData(_.clone(inv))
                setSiteList(r.result.sites.map((site) => 
                    { 
                        return {id: site.id, name: site.name} 
                    }))
                APP.central.Customer.fetch(clientId).then((r) => {
                    setClient(r.result)
                    setLoading(false)
                })
            })
        }
    }, [clientId, period])

    const updateRef = (them, magic, key) => {
        if (saveWarning) {
            if (!window.confirm("Unsaved work will be lost. Continue?")) {
                stateRef.current.period = period;
                stateRef.current.client = client;
                stateRef.current.site = site;
                return
            }
        }
        switch (key) {
            case "client":
                if (stateRef.current.client != initialClient && stateRef.current.client != 0) {
                    //window.location.href = "/ui/sustainabilityedit/" + stateRef.current.client + "/" + period 
                }
                setClientId(stateRef.current.client)                              
                break;
            case "period":
                if (stateRef.current.period != initialPeriod && clientId != 0) {
                    //window.location.href = "/ui/sustainabilityedit/" + clientId + "/" + stateRef.current.period
                }
                setPeriod(stateRef.current.period)
                break;
            case "site":
                setSite(stateRef.current.site)
                break;
            default:
                console.log("Case not covered, case was " + key + ".")
                break;
        }
    }

    const nav = useNavBar("Sustainability", [])

    /*
    const filterSites = () => {
        console.log(stateRef.current)
        const site = stateRef.current.site
        if (site == '') {  
            _.each(currentData.lines, (line) => { //update data
                //aaa
            })
            //currentData.lines = []
            var tempData = []
            var tempCopy = _.clone(inv)
            tempCopy.lines = tempData;
            setCurrentData(tempCopy)
            _.each(inv.lines, (line) => {
                tempData.push(line)
            }) //swap current data  
            var tempCopy2 = _.clone(inv)
            tempCopy2.lines = tempData
            setCurrentData(tempCopy2)
            console.log(currentData)
            //I'm dumb, I need to use setCurrentData
        } else {
            _.each(currentData.lines, (line) => { //update data
                //aaa
            })
            //currentData.lines = []
            var tempData = []
            var tempCopy = _.clone(inv)
            tempCopy.lines = tempData;
            setCurrentData(tempCopy)
            _.each(inv.lines, (line) => {
                if (line.site.id == site) {
                    tempData.push(line)
                }
            }) //swap current data  
            var tempCopy2 = _.clone(inv)
            tempCopy2.lines = tempData
            setCurrentData(tempCopy2)
            console.log(currentData)
            //I'm dumb, I need to use setCurrentData
        }  
    }
    */

    const refreshData = () => {
        setLoading(true)
        //window.location.href = "localhost:9000/ui/sustainabilityedit/" + clientId + "/" + period
        APP.central.Sustainability.getData(clientId, period).then((r) => {
            setInv(r.result)
            //setCurrentData(_.clone(inv))
            setSiteList(r.result.sites.map((site) => {
                return { id: site.id, name: site.name }
            }))
            APP.central.Customer.fetch(clientId).then((r) => {
                setClient(r.result)
                setLoading(false)
            })
        })
    }

    return <div>

        <div style={{
            display: "flex",
            flexDirection: "row"
        }}>

            <Bound to={stateRef.current} onChange={updateRef}>
                <InputAutoComplete central={APP.central.Customer.autocomplete} field="client" >
                    {(site) => <div className="customer_option">{site.displayValue}</div>}
                </InputAutoComplete>
                <PeriodSelect field="period" label="Period" />
                <InputSelect style={{ marginTop: '15px' }}field="site" optionsCollection={siteList} optionKey="name" /*onChange={() => filterSites()}*/ defaultValue={{id: 0, name: ""}}/>
                {/*<Button onClick={() => refreshData()}>Refresh Data</Button>*/}
            </Bound>
        </div>

        

        {clientId == 0 ? "Please select a client" : (inv == undefined ? "Loading..." : <DataDisplay data={/*stateRef.current.site == '' ? inv : currentData*/ inv} loading={loading} period={period} client={client} sites={siteList} extraPeriod={stateRef.current.period} saveWarning={saveWarning} setSaveWarning={setSaveWarning} refreshData={refreshData} setLoading={setLoading}/>)}

    </div>
}

export function DataDisplay(props) {

    const [waste, wasteSet] = useState("none")
    const [site, setSite] = useState("")
    const [value, valueSet] = useState(0)
    const [period, setPeriod] = useState("")
    const [service, serviceSet] = useState(-1)
    const [serviceLine, serviceLineSet] = useState()
    const [bound, setBound] = useState(props.data)
    const [isSaving, setIsSaving] = useState(false)
    const [sustainabilityStatus, setSustainabilityStatus] = useState([])
    const [slEmpty, setSlEmpty] = useState(true)
    const [editDisabled, setEditDisabled] = useState(true)
    const [id, setId] = useState(0)
    const [fileList, setFileList] = useState([])
    const [test, setTest] = useState(0)
    const [saveWarning, setSaveWarning] = useState(props.saveWarning);
    const [estimateWarning, setEstimateWarning] = useState(false)
    const [ticky, setTicky] = useState(0);
    const gridRef = useRef()

    useEffect(() => {
        setBound(props.data)
        setTicky(Math.random());
        if (gridRef.current) {
            gridRef.current.boundMagic.replaceTo(props.data);
            gridRef.current.refresh();
        }
    }, [])

    useEffect(() => {
        setBound(props.data)
        
        if (gridRef.current) {
            //gridRef.current.clear()
            //gridRef.current.reset()
            //gridRef.current.setData(bound.lines)
            gridRef.current.boundMagic.replaceTo(props.data);
            gridRef.current.refresh();
        }  
    }, [props.data])

    const [statusDisplay, setStatusDisplay] = useState({ status: null });

    useEffect(() => {
        setSlEmpty(props.data.sustainabilityLines != undefined ? (props.data.sustainabilityLines.length > 0 ? false : true) : true)
    }, [props.data.sustainabilityLines])

    const updateGraph = (bound) => {
        wasteSet(bound.to.material.name)
        setSite(bound.to.site.name)
        valueSet(bound.to.metricTonne)
        setPeriod(moment(bound.to.period).format("YYYY-MM"))
        serviceSet(bound.to.line.service)
        serviceLineSet(bound.to)
        setId(bound.to.line.id)
    }

    const startSave = () => {
        setIsSaving(true)
        testFunction2()
    }

    const testFunction = () => {
        for (var i = 0; i < bound.to.lines.length; i++) {
            if (bound.to.lines[i].hasOwnProperty("__delta")) {
                APP.central.Sustainability.saveLineItemChanges(bound.to.lines[i], bound.to.lines[i].__delta.metricTonne)
            }
        }
        for (var i = 0; i < bound.to.sustainabilityLines.length; i++) {
            if (bound.to.sustainabilityLines[i].hasOwnProperty("__delta")) {
                var targetLine = bound.to.sustainabilityLines[i]
                APP.central.Sustainability.saveSustainabilityLineItemChanges(bound.to.sustainabilityLines[i], bound.to.sustainabilityLines[i].__delta.metricTonne).then((r) => {
                    console.log(targetLine)
                    if (r.result != null) {
                        targetLine.line.id = r.result
                    }
                })
            }
        }
    }

    const testFunction2 = () => {
        APP.central.Sustainability.saveAllLines(bound).then((r) => {
            console.log(r.result)
            setIsSaving(false)
            props.setSaveWarning(false)
            setSaveWarning(false)
        })
    }

    const generateReport = () => {

        APP.showLoading({ message: "Generating PDF", children: "Generating EIR.  You can minimize this dialog and the document will load when it's ready." }, () => {
            APP.central.Sustainability.createSustainabilityReport({ "sites": props.sites, "customerId": props.client.id, "period": props.period }).then((r) =>
                APP.socket.send(JSON.stringify(r))
            )
        });

    }

    const testEmail = () => {
        console.log(period, props.extraPeriod)
        APP.central.Sustainability.sendTestEmail(bound, (period.length < 1 ? props.extraPeriod : period))
    }

    const approveSites = () => {
        console.log(props.sites, props.period)
        APP.central.Sustainability.updateMultiSiteSustainabilityStatus(props.sites, props.period, "pendingDistribution").then((r) => {
            console.log(r.result);
            setStatusDisplay({ status: "pendingDistribution" });
        })
    }

    const addLine = () => {
        const monthFrom = props.period + "-01";
        const monthTo = moment(monthFrom).endOf("month").format("YYYY-MM-DD");

        const allItems = bound.magicalAppend("sustainabilityLines", { period: props.period, service: null, line: { id: -1, __type: "LineItem", lineItemType: "sustainability_line", periodFrom: monthFrom, periodTo: monthTo, customer: { id: props.client.id } }, material: null })
        const newItem = bound.getBound("sustainabilityLines", allItems.length - 1)
        setSlEmpty(false)
        //openSetup(newItem._ourMagic) old setup for making real services associated with sustainability lines
        openSetup2(newItem._ourMagic)
    }

    const copyLines = () => {
        if (saveWarning) {
            APP.confirm("Copying lines will refresh the current data and changes will be lost. Copy anyway?", (ok) => {
                if (ok) {
                    props.setLoading(true)
                    const p = props.period;
                    const customer = props.client.id;
                    const monthFrom = props.period + "-01";
                    const monthTo = moment(monthFrom).endOf("month").format("YYYY-MM-DD");

                    APP.central.Sustainability.copyPreviousMonth(props.period, props.client.id).then((res) => {
                        props.refreshData()
                    })
                }
            })
        } else {
            props.setLoading(true)
            const p = props.period;
            const customer = props.client.id;
            const monthFrom = props.period + "-01";
            const monthTo = moment(monthFrom).endOf("month").format("YYYY-MM-DD");

            APP.central.Sustainability.copyPreviousMonth(props.period, props.client.id).then((res) => {
                props.refreshData()
            })
        }
    }


    const removeLine = (line) => {
        bound.magicalSplice("sustainabilityLines", bound.magicalGet("sustainabilityLines").findIndex((l) => { return l.line.id == line.magicalGet("line").id }), true)
        if (bound.magicalGet("sustainabilityLines").length < 1) setSlEmpty(true)
    }

    const clearLines = () => {
        for (let i = 0; i < bound.magicalGet("lines").length; i++) {
            bound.getBound("lines", i)._ourMagic.magicalSet("metricTonne", 0)
            bound.getBound("lines", i)._ourMagic.magicalSet("tempMetricTonne", 0)
            bound.getBound("lines", i)._ourMagic.magicalSet("line.reportExclude", true)
        }
    }

    const clearSustainabilityLines = () => {
        for (let i = 0; i < bound.magicalGet("sustainabilityLines").length; i++) {
            bound.getBound("sustainabilityLines", i)._ourMagic.magicalSet("metricTonne", 0)
            bound.getBound("sustainabilityLines", i)._ourMagic.magicalSet("tempMetricTonne", 0)
            bound.getBound("sustainabilityLines", i)._ourMagic.magicalSet("line.reportExclude", true)
        }
    }

    useEffect(() => {
        const siteIds = props.sites.map((s) => {
            return s.id
        })
        APP.central.Sustainability.fetchSiteSustainabilityStatus(siteIds, props.period).then((r) => {
            setSustainabilityStatus(r.result)
            if (r.result[0]) {
                setStatusDisplay({ status: r.result[0].status });
            }

        })
    }, [props.sites, props.period])

    const doUpdateStatus = (changeInfo) => {
        //console.log("Updating Statuses for site: ", changeInfo.boundTo.siteId);
        console.log("Updating Statuses for sites: ", props.sites);
        console.log("In period: ", props.period);
        console.log("changeInfo: ", changeInfo);

        APP.central.Sustainability.updateMultiSiteSustainabilityStatus(props.sites, props.period, changeInfo.selection.key).then((r) => {
            console.log(r.result);
        });
    }

    const updateStatus = (bound) => {
      const myBound = bound.bound.to

      APP.central.Sustainability.updateSustainabilityStatusDirect(myBound.id, myBound.status).then((r) => {
        console.log(r.result)
      })
    }

    const SustainabilityStatusDisplay = (props) => {
        const [expand, setExpand] = useState(false);

        if (props.data.length < 1) {
            return null;
        }

        const display = expand ? (
            <div style={{ borderStyle: "solid", borderColor: "gray", borderWidth: "1px" }}>
                <Button onClick={() => setExpand(!expand)}>Hide Status Display</Button>
                <Tabular data={props.data}>
                    <TabularColumn data={(row) => row.customer.name} title="Client Name"/>
                    <TabularColumn data={(row) => row.site.friendlyId} title="WS ID"/>
                    <TabularColumn data={(row) => row.site.address} title="Address"/>
                    <TabularColumn data={(row) => row.customer.clientType.name} title="Client Type"/>
                    <TabularColumn data={(row) => row.site.name} title="Site Name"/>
                    <TabularColumn data={(row) => <Bound to={row}>
                            <LookupStatusSelect field="status" lookupName="sustainabilityStatus" disabled={false} onChange={(bound) => updateStatus(bound)} />
                        </Bound>} title="Status"/>
                    
                </Tabular>
            </div>
        ) : (<div><Button style={{ marginRight: '10px', marginTop: '10px' }} onClick={() => setExpand(!expand)}>Show Status Display</Button></div>) 

        return display;
    };

    /*
    {props.data.map((s, i) => {
            return (
              <div>
                <Bound to={s}>
                  {s.site.name}
                  <LookupStatusSelect lookupName="sustainabilityStatus" field="status" onChange={(bound) => updateStatus(bound)} />
                </Bound>
              </div>
            );
          })}
    */

    const ServiceDetailDisplay = (props) => {
        if (props.data == undefined) {
            return null
        }
        return <div>
            <NiceBoxContainer>
                <NiceBox label="Material">
                    {props.data.material.name}
                    <NiceMaterial>{props.data.material}</NiceMaterial>
                </NiceBox>
                <NiceBox label="Density">
                    {props.data.material.density}
                </NiceBox>
                <NiceBox label="Description">
                    {props.data.description}
                </NiceBox>
                <NiceBox label="Metric Tonnes">
                    {props.data.metricTonne}
                </NiceBox>
            </NiceBoxContainer>
        </div>
    }

    const FileDisplay = ({ fileList, sustainabilityStatus }) => {
        const [expand, setExpand] = useState(false)
        if (!expand) return <div>
            <Button style={{ marginRight: '10px', marginTop: '10px' }} onClick={() => setExpand(!expand)}>Show Weight Reports</Button>
        </div>
        else return <div style={{ borderStyle: "solid", borderColor: "gray", borderWidth: "1px" }}>
            <Button onClick={() => setExpand(!expand)}>Hide Weight Reports</Button>
            <Bound to={sustainabilityStatus[0]}>
                <File onUpload={(file) => uploadFile(file)} label="Vendor Weight Reports" showFiles={false} />
            </Bound>

            <Tabular data={fileList}>
                <TabularColumn title="File Name" data={(row) => row.filename} />
                <TabularColumn title="Details" data={(row) => <Icon icon="edit" onClick={() => openFileModal(row)} />} />
                <TabularColumn title="Delete" data={(row) => <Icon icon="trash" onClick={() => deleteFile(row)} />} />
            </Tabular>
        </div>
    }

    const openSetup = (bound) => {
        console.log(bound)
        APP.instance.createModal(<LineSetup bound={bound} />)
    }

    const openSetup2 = (bound) => {
        APP.instance.createModal(<LineSetup2 bound={bound} />)
    }

    const deleteItem = (bound) => {
        APP.central.Sustainability.deleteSustainabilityLine(bound).then((r) => {
            if (r.result == true) {
                removeLine(bound)
            }
        })
    }

    const uploadFile = (file) => {
        APP.central.File.list("SiteSustainabilityStatus", sustainabilityStatus[0].id).then((r) => {
            if (r.result != null) {
                if (props.fileCategory) {
                    setFileList(_.filter(r.result, s => s.fileCategory == props.fileCategory))
                } else {
                    setFileList(r.result);
                }
            }
        });
    }

    const openFileModal = (row) => {     
        APP.instance.createModal(<FileSitePicker row={row} period={period.length == 0 ? props.extraPeriod : period} />)
    }

    const deleteFile = (row) => {
        APP.central.File.delete(row.id).then((r) => {
            if (r.result == true) {
                APP.central.File.list("SiteSustainabilityStatus", sustainabilityStatus[0].id).then((r) => {
                    if (r.result != null) {
                        if (props.fileCategory) {
                            setFileList(_.filter(r.result, s => s.fileCategory == props.fileCategory))
                        } else {
                            setFileList(r.result);
                        }
                    }
                });
            }
        })
    }

    const updateOverride = (bound) => {
        if (bound.magicalGet("line.estimateType") == "WSC Estimate" || bound.magicalGet("line.estimateType") == null || bound.magicalGet("line.estimateType") == "") {
            bound.magicalSet("line.estimateType", "Hauler Estimate")
            bound.magicalSet("line.reportExclude", true)
        }
    }

    useEffect(() => {
        if (sustainabilityStatus[0] != null) {
            APP.central.File.list("SiteSustainabilityStatus", sustainabilityStatus[0].id).then((r) => {
                if (r.result != null) {
                    if (props.fileCategory) {
                        setFileList(_.filter(r.result, s => s.fileCategory == props.fileCategory))
                    } else {
                        setFileList(r.result);
                    }
                }
            });
        }
    }, [sustainabilityStatus])

    const saveSustainabilityStatus = (a,b,c) => {
        console.log(a,b,c)
        
        APP.central.SiteSustainabilityStatus.saveReportPeople(b.to).then((r) => {
            console.log(r.result)
        })
        
    }

    const EmailDisplay = ({emails}) => {
        return <div>
            <Tabular data={emails}>
                <TabularColumn title="ID" data={(row) => row.id}/>
                <TabularColumn title="Created" data={(row) => row.dateCreated}/>
                <TabularColumn title="Status" data={(row) => row.status == null ? "pending" : row.status}/>
                <TabularColumn title="Details" data={(row) => row.details}/>
            </Tabular>
        </div>
    }

    const closeFunction = () => {
        setTest(test + 1);
    }

    const PendingEmails = ({test}) => {
        const [emailStatus, setEmailStatus] = useState(null)
        const [pendingEmails, setPendingEmails] = useState(null)
        const [sentEmails, setSentEmails] = useState(null)
        const [failedEmails, setFailedEmails] = useState(null)

        const getEmails = () => {
            var statusIds = []
            sustainabilityStatus.forEach((s) => statusIds.push(s.site.id))
            APP.central.SiteSustainabilityStatus.getSustainabilityEmails(statusIds, sustainabilityStatus[0].reportPeriod).then((r) => {
                if(r != null && r.result != null){ 
                    setEmailStatus(r.result)
                var pending = 0;
                var sent = 0;
                var failed = 0;
                r.result.forEach((s) => {
                    if (s.status == null) {
                        pending += 1
                    } else if (s.status == "sent") {
                        sent += 1
                    } else if (s.status == "failed") {
                        failed += 1
                    }
                })
                setPendingEmails(pending) 
                setSentEmails(sent)
                setFailedEmails(failed)
                }                
            })
        }

        useEffect(() => {
            getEmails()
        }, [sustainabilityStatus, test])

        if (emailStatus == null) {
            return <div>
                No pending emails.
            </div>
        } else return <div>
            <HoverElement content={emailStatus.length + " total emails: " + pendingEmails + " pending, " + sentEmails + " sent, " + failedEmails + " failed."}><EmailDisplay emails={emailStatus}/></HoverElement> 
        </div>
    }

    const SustainabilityChanges = (props) => {
        const [data, setData] = useState([])
        const lineId = props.line.magicalGet("line.id")
        useEffect(() => {
            APP.central.Sustainability.getSustainabilityChangelogs(lineId).then((r) => {
                setData(r.result)
            })
        }, [])

        const deltaFormatter = (delta)=>{
            return <tr key={"delta-formatter-" + delta.field} className="delta-formatter">
                <td>{delta.field}</td>
                <td>{delta.before == null? "" : delta.before + ""}</td>
                <td>{delta.after == null? "" : delta.after + ""}</td>
            </tr>
        }
    
        const ChangeLogDelta = ({delta}) =>{
            return <div className="change-log-delta">
                <h4>{delta.changeType}</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Before</th>
                            <th>After</th>
                        </tr>
                    </thead>
                    <tbody>
                        { _.map(delta.diffs, (diff)=>deltaFormatter(diff)) }
                    </tbody>
                </table>
            </div>
        }

        if (data.length == 0) return <Loading />
        else return <div>
            <Tabular data={data}>
                <TabularColumn title="Changed By" data={(row) => row.userObject.fullName} />
                <TabularColumn title="Changed At" data={(row) => row.niceTime} />
                <TabularColumn title="Changes" data={(row) => <ChangeLogDelta delta={row.differentials}/>} />
            </Tabular>
        </div>
    }

    const newInvoiceTab = (bound) => {
        window.open("/ui/arinvoice/" + bound.to.invoiceId)
    }

    const MetricTonneEdit = (props) => {
        const bound = useContext(BoundDataContext)
        if (bound.magicalGet("invoiceStatus") == "skipped") return <div>
            Skipped
        </div>
        else return <div>
            <InputDecimal boundUpdate={true} field="metricTonne" minPlace={2} /*onFocus={(bound) => updateGraph(bound)}*/ disabled={false/*editDisabled/*(bound) => {bound.magicalGet("line.reportExclude") == true ? false : true}*/} update={(bound) => updateOverride(bound)} />
        </div>
    }

    const fireSaveWarning = (bound) => {
        props.setSaveWarning(true)
        setSaveWarning(true)
    }

    const estimateWarningConfirm = (a, b, c) => {
        if (c == "WSC Estimate" && !estimateWarning) {
            if (window.confirm('Keep overridden value?')) {
                a._ourMagic.magicalSet("line.reportExclude", true)
            } else {
                a._ourMagic.magicalSet("line.reportExclude", false)
            }
            //setEstimateWarning(true);
        }
    }

    const handleRegisterGrid = (ctx) => {
        gridRef.current = ctx;
    }

    if (props.loading || sustainabilityStatus.length == 0) {
        return <div>
            <Loading message="Loading..." />
        </div>
    }

    if (!props.loading && sustainabilityStatus.length == 0) {
        return <div>
            <ClientDetailDisplay data={props.client} />
            No site sustainability status found for this client and period.
        </div>
    }

    else if (props.data.lines != undefined) {
        if (props.data.lines.length == 0) {
        /** return <div>
                <ClientDetailDisplay data={props.client} />
                <HoverElement content="Sustainability Status">
                    {sustainabilityStatus && sustainabilityStatus.length > 0 ? <SustainabilityStatusDisplay data={sustainabilityStatus} /> : null}
                </HoverElement>
                No data.
            </div> **/
        }
    }

    return <div>

        {/*<ClientDetailDisplay data={props.client} />*/}

        {/*<HoverElement content="Sustainability Status"> */}
        <div style={{
            display: "flex",
            flexDirection: "row"
        }}>{sustainabilityStatus && sustainabilityStatus.length > 0 ? <SustainabilityStatusDisplay data={sustainabilityStatus} /> : null}
        {/*</HoverElement> */}
        <FileDisplay fileList={fileList} sustainabilityStatus={sustainabilityStatus} /></div>
        

        <div style={{
            display: "flex",
            flexDirection: "row"
        }}>

            <div style={{
                display: "flex",
                flexDirection: "column"
            }}>

                <div className="sustainability-edit">
                    <Bound to={bound} icon="file-pdf" init={(thisBound) => setBound(thisBound)} onChange={(bound) => saveWarning ? null : fireSaveWarning(bound)}>
                        <Tasks model={sustainabilityStatus[0]}/>
                        <Notes model={props.client}/>
                        <PendingEmails test={test}/>
                        {saveWarning ? "You have unsaved changes." : null}
                        <div>
                            {waste == "none" ? /*<TotalDiversionGraph lines={props.data.lines} period={props.period}/>*/ null : <SomeGraph graphType={waste} site={site} period={props.period} value={value} service={service} id={id} />}
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h4>Invoice Lines</h4> <a onClick={() => clearLines()}> <Icon icon="broom" title="Clear Lines" /> <em>Clear Lines</em></a>
                        </div>
                        { props.data != null && props.data.lines != null && props.data.lines.length == 0 ? 
                            <em>There are no invoices, or no related material lines for this period.</em> : null
                        }
                        <Grid registerGrid={handleRegisterGrid} boundData="lines" ticky={ticky} alternate="site.id">
                            <GridItem title="Site" justify="left" width="120">
                                <InputText field="site.name" disabled={true} />
                            </GridItem>
                            <GridItem title="WMX ID" justify="left" width="100">
                                <InputText field="site.friendlyId" disabled={true} />
                            </GridItem>
                            <GridItem title="Vendor" justify="left" width="60">
                                <InputText field="vendor.name" disabled={true} />
                            </GridItem>
                            <GridItem title="Description" justify="left">
                                <InputText field="description" disabled={true} />
                            </GridItem>
                            <GridItem title="Material" justify="left">
                                <GridClickableModalThing field="material.name" fnc={() => console.log("aaa")} />
                            </GridItem>
                            <GridItem title="Sub Material" justify="left">
                                <GridClickableModalThing field="specificMaterial.name" fnc={() => console.log("aaa")} />
                            </GridItem>
                            <GridItem title="Equipment" justify="left" width="120">
                                <InputText field="fullEquipmentName" disabled={true} />
                            </GridItem>
                            <GridItem title="Weight" justify="left" width="200">
                                <FancyWeightInput />
                                {/*<MetricTonneEdit />*/}
                            </GridItem>
                            <GridItem title="Metric Tonnes" justify="left" width="120">
                                <InputText field="metricTonne" disabled={true} />
                            </GridItem>
                            <GridItem title="Estimate Type" justify="left">
                                <InputSelect field="line.estimateType" options={["Not Selected", "WSC Estimate", "Actual Weight", "Hauler Estimate", "Mixed"]} onChange={(a, b, c) => estimateWarningConfirm(a, b, c)}/>
                            </GridItem>
                            <GridItem title="Notes" justify="left" width="120">
                                <InputText field="line.notes" disabled={false} />
                            </GridItem>
                            <GridItem title="Review Setting" justify="left" width="130">
                                <InputText field="service.sustainabilityReviewSetting" disabled={true} />
                            </GridItem>
                            {/*
                            <GridItem title="Line MT">
                                <InputDecimal field="currentLineMetricTonne" disabled={true} />
                            </GridItem>
                            */}
                            <GridItem title="Calculator" hideTitle={true}>
                                <IconNew title="" onClick={(bound) => openCalculator(bound)} icon="calculator" />
                            </GridItem>
                            <GridItem title="Invoice" hideTitle={true}>
                                <BoundLinkButton target={"/ui/arinvoice/"} icon="file-invoice" field="invoiceId"/>
                            </GridItem>
                            <GridItem title="Changes" hideTitle={true}>
                                <IconNew onClick={(bound) => APP.instance.createModal(<SustainabilityChanges line={bound}/>)} icon="history"/>
                            </GridItem>
                            <GridItem title="Graph" hideTitle={true}>
                                <IconNew onClick={(bound => updateGraph(bound))} icon="chart-bar" />
                            </GridItem> 
                        </Grid>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {slEmpty ? <h4></h4> : <h4>Additional Materials</h4>}
                            <div>
                                <a onClick={() => addLine()}><Icon icon="plus-circle" title="Add Material" /> <em>Add Material</em></a> &nbsp;
                                <a onClick={() => copyLines()}><Icon icon="plus-circle" title="Add Materials From Latest Month" /> <em>Copy Previous Materials</em></a> &nbsp;
                                {slEmpty ? null : <a onClick={() => clearSustainabilityLines()}><Icon icon="broom" title="Clear Additional Materials" /> <em>Clear Additional Materials</em></a>}</div>
                        </div>
                        {slEmpty ? null : <div>
                            <Grid boundData="sustainabilityLines">
                                <GridItem title="Site" justify="left">
                                    <InputText field="site.fullName" disabled={true} />
                                </GridItem>
                                <GridItem title="Material" justify="left">
                                    <InputText field="material.name" disabled={true} />
                                    {/*<GridClickableModalThing field="material.name" fnc={() => console.log("aaa")} />*/}
                                </GridItem>
                                <GridItem title="Sub Material" justify="left">
                                    <InputText field="specificMaterial.name" disabled={true} />
                                </GridItem>
                                <GridItem title="Weight" justify="left" width="200">
                                    <FancyWeightInput />
                                    {/*<MetricTonneEdit />*/}
                                </GridItem>
                                <GridItem title="Metric Tonnes" justify="left" width="120">
                                    <InputText field="metricTonne" disabled={true} />
                                </GridItem>
                                {/*<GridItem title="Metric Tonnes" justify="left">
                                    <InputDecimal field="metricTonne" minPlace={2} onFocus={(bound) => updateGraph(bound)} />
                                </GridItem>*/}
                                
                                <GridItem title="Estimate Type">
                                    <InputSelect field="line.estimateType" options={["Not Selected", "WSC Estimate", "Actual Weight", "Hauler Estimate", "Mixed"]}/>
                                </GridItem>
                                <GridItem title="Notes" justify="left">
                                    <InputText field="line.notes" disabled={false} />
                                </GridItem>
                                <GridItem title="Calculator" hideTitle={true}>
                                    <IconNew title="" onClick={(bound) => openCalculator(bound)} icon="calculator" />
                                </GridItem>
                                <GridItem title="Delete" hideTitle={true}>
                                    <IconNew onClick={(bound) => deleteItem(bound)} icon="trash-alt" />
                                </GridItem>
                                <GridItem title="Calculator" hideTitle={true}>
                                  <IconNew title="" onClick={(bound) => openCalculator(bound)} icon="calculator" />
                                </GridItem>
                                {/*} // full sustainability line calculator stuff
                                <GridItem title="Edit" hideTitle={true}>
                                    <IconNew onClick={(bound) => openSetup(bound)} icon="edit" />
                                </GridItem>
                                <GridItem title="Calculator" hideTitle={true}>
                                    <IconNew onClick={(bound) => openCalculator(bound)} icon="calculator" />
                                </GridItem>
                                */}
                            </Grid>
                        </div>}

                        <div>
                        <HoverElement content={<div>{props.client.customerContacts.length + (props.client.customerContacts.length == 1 ? " Contact" : " Contacts")}</div>}>
                            <ContactList client={props.client} truncate={true} />
                        </HoverElement>
                            <Bound to={sustainabilityStatus[0]} onChange={(a,b,c) => saveSustainabilityStatus(a,b,c)} >
                                    <StaffSelect style={{width:"20%"}} what="staff" team="Sustainability Team" field="reportPreparer" name="Prepared By"/>
                                    <StaffSelect style={{width:"20%"}} what="staff" team="Data Assurance" field="reportApprover" name="Audited By"/>
                            </Bound>
                        </div>


                        {isSaving ? <Loading /> : <div className="button-group">
                            {isSaving ? <Button>Saving</Button> : <Button style={saveWarning ? {backgroundColor: "#00FF00"} : null} onClick={isSaving ? null : () => startSave()}>Save Changes</Button>}
                            <Button onClick={approveSites}>Approve</Button>
                            <Button onClick={() => APP.instance.createModal(<ReportModal sites={props.sites} period={props.period} client={props.client} statuses={sustainabilityStatus} />)}>Generate Report</Button>
                            <Button onClick={() => APP.instance.createModal(<NewReportSender sites={props.sites} period={props.period} client={props.client} statuses={sustainabilityStatus} />, {afterClose: () => closeFunction()})}>Send Reports</Button>
                            <Button onClick={() => APP.instance.createModal(<LineViewer sites={props.sites} period={props.period} customer={props.client.id}/>)}>View All Lines</Button>
                        </div>}
                        {saveWarning ? "You have unsaved changes." : null} 

                        
                        {/*
                        <Button onClick={() => clearAll(bound)}>Clear All</Button>
                        */}
                    </Bound>
                    <Prompt message="Unsaved work will be lost. Continue?" when={saveWarning}/>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
            }}>
                <div>
                    {/* Source document? todo */}
                </div>
            </div>
        </div>
    </div >
}

function GridClickableModalThing({ field, fnc }) {
    const magic = useContext(DataContext)
    const service = magic.magicalGet("service")
    let style = { color: "#4d4d4f", }
    let value = null;
    try {
        value = magic.magicalGet(field);
        if (typeof value == "object") { }
    } catch (e) { }

    const openService = () => {
        APP.instance.createModal("/ui/services/edit/" + service.id, { modal_name: "Service #" + service.friendlyId })
    }

    if (typeof fnc == "function") {
        return <div style={style} onClick={() => openService()}>{value}</div>
    } else {
        return <div style={style}>{value}</div>
    }
}

function openInvoice(bound) {
    APP.instance.createModal("/ui/arinvoice/" + bound.to.invoiceId)
}

function openPDF(bound) {
    APP.redirect("/ui/audit/" + bound.to.invoiceId)
}

function openCalculator(bound) {    
    APP.instance.createModal(<LineCalculator bound={bound} updateLine={updateLine} />)
}

function updateLine(bound, line) {
    var tempLine = _.find(bound.getParentMagic().magicalGet("lines"), (sLine) => {
        return sLine.line.id == line.id
    })
    tempLine.line = line;
    bound.getParentMagic().replaceTo(bound.getParentMagic().to)
}

function revertExclude(bound) {
    console.log(bound)
    let logic = 0
    if (bound.magicalGet("line.activity") == null) {
        logic = 101
    }
    APP.central.Sustainability.includeLineItem(bound.magicalGet("line"), logic)
}

function LineSetup(bound) {
    const [loading, setLoading] = useState(false)
    const myBound = bound.bound
    const currentModal = useContext(CurrentModal)
    const [line, setLine] = useState(myBound.getBound("line"))

    const saveLine = () => {
        setLoading(true)
        APP.central.Sustainability.saveLineSetup(line, line._ourMagic.getAllDeltas()).then((r) => {          
            myBound.replaceTo(r.result);
            APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
            setLoading(false)
        })
    }

    return <div>
        <Bound to={line} boundId="lineBound">
            <SelectClientSiteService show={["site", "service"]} allowAddService={true} />
            <InputDecimal field="quantity" name="Quantity" />
            <LookupInputSelect what="lookup" field="unitPricePer" name="Quantity Type" />
            {loading ? <Button>Saving...</Button> : <Button onClick={saveLine}>Save</Button>}
        </Bound>
    </div>
}

function LineSetup2(bound) {
  const [loading, setLoading] = useState(false)
  const [material, setMaterial] = useState({ material: null })
  const myBound = bound.bound
  const currentModal = useContext(CurrentModal)
  const [line, setLine] = useState(myBound.getBound("line"))

  const theLine = myBound.magicalGet("line");
  const [services, setServices] = useState([]);
  const [useExistingService, setUseExistingService] = useState({ isUsing: false });
  const [serviceBound, setServiceBound] = useState(null);

  const saveLine = () => {
    setLoading(true)
    APP.central.Sustainability.saveLineSetup2(line, line._ourMagic.getAllDeltas()).then((r) => {
     
      myBound.replaceTo(r.result);
      APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
      setLoading(false)
    })
  }

  const doSaveWithService = () => {
    setLoading(true);
    APP.central.Sustainability.saveLineSetupExistingService(line, theLine.site, theLine.service, theLine).then((r) => {
      let newLine = {...r.result};
      newLine.metricTonne = _.toNumber(theLine.reportMetricTonne);
      newLine.line.reportMetricTonne = _.toNumber(theLine.reportMetricTonne);
      newLine.estimateType = theLine.estimateType;
      newLine.line.estimateType = theLine.estimateType;
      newLine.notes = theLine.notes;
      newLine.line.notes = theLine.notes;
      newLine.periodFrom = theLine.periodFrom;
      newLine.line.periodFrom = theLine.periodFrom;
      newLine.periodTo = theLine.periodTo;
      newLine.line.periodTo = theLine.periodTo;
      newLine.description = newLine.service.cleanDescription;
      newLine.containerName = newLine.service.containerName;
      newLine.environmentalDensity = newLine.service.environmentalDensity;
      newLine.vendor = newLine.service.vendor;
      newLine.line.unitPricePer = "month";

      APP.central.Service.serviceInPeriod2(newLine.service.id, newLine.line.periodTo).then((r) => {
        if (r.result && r.result.length > 0) {
          newLine.totalServiceDays = r.result.length;
        }
      });

      myBound.replaceTo(newLine);

      APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined);
      setLoading(false);
    })
  }

  const fetchServices = () => {
    if (theLine.site) {
      APP.central.Service.fetchServicesForSite(theLine.site).then((r) => {
        setServices(r.result);
        setServiceBound({ site: theLine.site });
      });
    }
  }

  const boundChange = (a,b,c,d) => {
    if(c && d && theLine[c] != d) {
      theLine[c] = d;
    }
    if(myBound.magicalGet("line."+c) != d) {
      myBound.magicalSet("line."+c, d);
    }

    if(c == "site") {
      fetchServices();
    }
  }

  const doOnIsUsingChange = (e) => {
    setUseExistingService({ isUsing: e });
  }

  // I need a site, a material, and a tonnage
  return (
    <div>
      <Bound to={useExistingService}>
        <InputToggleSwitch
          field="isUsing"
          name={(isUsing) => {
            return isUsing ? "Use Existing Service" : "Do Not Use Existing Service";
          }}
          onChange={doOnIsUsingChange}
        />
			</Bound>
      {useExistingService.isUsing ? (
        <Bound to={theLine} boundId="theLineBound" onChange={boundChange} >
          <SelectClientSiteService show={["site"]} />
          <InputSelect name="Service" field="service" options={_.fromPairs(services.map((s)=>[s.id, s.longDescription]))} />
          <InputDecimal field="reportMetricTonne" name="Metric Tonnes" />
          <InputSelect field="estimateType" options={["Not Selected", "WSC Estimate", "Actual Weight", "Hauler Estimate", "Mixed"]}/>
          <InputText inputType="textarea" field="notes" name="Notes" />
          {loading ? <Button>Saving...</Button> : <Button onClick={doSaveWithService}>Save</Button>}
        </Bound>
        ) : (
        <Bound to={line} boundId="lineBound" >
          <SelectClientSiteService show={["site"]} />
          {/*<Bound to={material}>*/}
        <LookupInputSelect name="Material" what="parent_materials" fetch={APP.central.Material.listParentMaterials} field="material" bound={true} store="object" showEmpty={true} />
          {/*</Bound>*/}
          <LookupInputSelect name="Sub Material" what="material" field="specificMaterial" store="object" showEmpty={true} />
          <InputDecimal field="reportMetricTonne" name="Metric Tonnes" />
          <InputSelect field="estimateType" options={["Not Selected", "WSC Estimate", "Actual Weight", "Hauler Estimate", "Mixed"]}/>
          <InputText inputType="textarea" field="notes" name="Notes" />
          {loading ? <Button>Saving...</Button> : <Button onClick={saveLine}>Save</Button>}
        </Bound>
      )}
    </div>
  )
}

function FileSitePicker(props) {

    console.log(props)

    const id = props.row.id
    const period = props.period
    const [initialSiteList, setInitialSiteList] = useState(null)
    const [siteList, setSiteList] = useState(null)
    const modal = useContext(CurrentModal)

    useEffect(() => {
        APP.central.SiteSustainabilityStatus.getSites(id, period).then((r) => {
            setInitialSiteList(r.result)
            setSiteList(r.result)
        })
        /*
        var tempSiteList = []
        if (props.row.siteSustainabilityStatus.length > 0) {
            props.row.siteSustainabilityStatus.forEach((sss) => {
                tempSiteList.push(sss.site)
            })
        }
        setSiteList(tempSiteList)
        setInitialSiteList(tempSiteList)
        */
    }, [])

    const setFileIds = () => {
        var initialSiteIds = []
        initialSiteList.forEach((site) => initialSiteIds.push(site.id))
        var siteIds = []
        siteList.forEach((site) => siteIds.push(site.id))
        APP.central.SiteSustainabilityStatus.setFiles(id, period, initialSiteIds, siteIds).then((r) => {
            APP.instance.closeModal(modal.id)
        })
    }

    if (siteList == null) {
        return <Loading />
    }

    else return <div>
        <div>
            <NiceBoxContainer>
                <h4>File Details</h4>
                <NiceBox label="File Name">
                    {props.row.filename}
                </NiceBox>
            </NiceBoxContainer>
        </div>
        <div>
            <MultiThingSelect thing="site" setter={setSiteList} existing={siteList}>
                <Button onClick={() => setFileIds()}>Update File Association</Button>
            </MultiThingSelect>

        </div>
    </div>
}

function LineCalculator(bound) {

    const [loading, setLoading] = useState(false)

    const [schedules, schedulesSet] = useData("service_schedule")
    const [volumes, volumesSet] = useData("equipmentsize")

    const [scheduleOptions, setScheduleOptions] = useState([])
    const [volumeOptions, setVolumeOptions] = useState([])

    const currentModal = useContext(CurrentModal)

    useEffect(() => {
        var scheduleOptionsTemp = []
        schedules.forEach((s) => {
            scheduleOptionsTemp.push({ ...s, name: s.frequency })
        })
        setScheduleOptions(scheduleOptionsTemp)
    }, [schedulesSet.loading])

    useEffect(() => {
        var volumeOptionsTemp = []
        volumes.forEach((v) => {
            volumeOptionsTemp.push({ id: v.id, name: v.name })
        })
        setVolumeOptions(volumeOptionsTemp)
    }, [volumesSet.loading])

    const myBound = bound.bound

    const cubicMetersToCubicYards = (meters) => {
        return Util.roundNice(meters * 1.30795, 2);
    }

    const cubicYardsToCubicMeters = (yards) => {
        return Util.roundNice(yards / 1.30795, 2);
    }

    const [line, setLine] = useState(myBound.getBound("line"))
    const [service, setService] = useState(myBound.getBound("service"))
    const [services, setServices] = useState([]);
    const [site, setSite] = useState(myBound.getBound("site"))
    const [calculator, setCalculator] = useState({
        override: (line.reportExclude ? myBound.magicalGet("metricTonne") : null),
        calculatedDaysQuantity: myBound.magicalGet("totalServiceDays"),
        sustainabilityFrequencyMethod: (service.sustainabilityFrequencyMethod != null ? service.sustainabilityFrequencyMethod : "monthlyFactor"),
        manualQuantity: (service.sustainabilityFrequencyMethod == "manual" && line.sustainabilityQuantity != null ? line.sustainabilityQuantity : null),
        materialDensityConverted: (cubicMetersToCubicYards(myBound.magicalGet("density")))
    })

    const [calculatorFrequency, setCalculatorFrequency] = useState(null)
    const [calculatorDensity, setCalculatorDensity] = useState(null)
    const [unconvertedDensity, setUnconvertedDensity] = useState(null)
    const [sustainabilitySpecificMaterial, setSustainabilitySpecificMaterial] = useState()
    const [imperialDensity, setImperialDensity] = useState(null)
    const [calculatorVolume, setCalculatorVolume] = useState(null)
    const [unconvertedVolume, setUnconvertedVolume] = useState(null)
    const [calculatorMT, setCalculatorMT] = useState(null)

    const [differenceString, setDifferenceString] = useState(null)
    const [estimateToggle, setEstimateToggle] = useState(false)
    const [frequencyComponent, setFrequencyComponent] = useState(null)

    const [overrideToggle, setOverrideToggle] = useState(line.reportExclude)

    const [override, setOverride] = useState(line.reportExclude)

    const compactedOptions = [{ id: "false", name: "No (x1)", value: "false" }, { id: "true", name: "Yes (x3)", value: "true" }]

    const updateLineAndService = () => {
        if (service.sustainabilityFrequencyMethod == "manual") {
            service.useSustainabilityFrequency = true
            line.sustainabilityQuantity = calculator.manualQuantity
            console.log(line.sustainabilityQuantity, calculator.manualQuantity)
        } else if (service.sustainabilityFrequencyMethod == "calculatedDays") {
            service.useSustainabilityFrequency = true
            line.sustainabilityQuantity = calculator.calculatedDaysQuantity
            console.log(line.sustainabilityQuantity, calculator.calculatedDaysQuantity)
        } else if (service.sustainabilityFrequencyMethod == "monthlyFactor") {
            service.useSustainabilityFrequency = false
            //line.sustainabilityQuantity = null
        }
    }

    const testCalculator = (theLine = line, theService = service) => {
        //updateLineAndService()
        setLoading(true)
        if (calculator.sustainabilityFrequencyMethod == "manual") {
            line.sustainabilityQuantity = calculator.manualQuantity
        }
        //service.containerFullness = parseFloat(service.containerFullness)
        APP.central.Sustainability.calculateSustainability(theLine, theService).then((r) => {
            setCalculatorFrequency(r.result.frequency)
            setCalculatorDensity(r.result.density)
            setCalculatorVolume(r.result.volume)
            setUnconvertedDensity(r.result.unconvertedDensity)
            setImperialDensity(r.result.imperialDensity)
            setUnconvertedVolume(r.result.unconvertedVolume)
            r.result.lineItem.reportMetricTonne = r.result.lineItem.reportMetricTonne.toFixed(2)
            setCalculatorMT(r.result.lineItem.reportMetricTonne);
            setLine(r.result.lineItem)
            setEstimateToggle(true)
            setLoading(false)
            setDifferenceString((((parseFloat(calculator.override) - r.result.lineItem.reportMetricTonne)).toFixed(2) + " (" + Math.abs(((- r.result.lineItem.reportMetricTonne) / r.result.lineItem.reportMetricTonne) * 100.0).toFixed(2) + "%)"))

          })
    }

    const saveAnyChanges = () => {
        if (line.reportExclude == true) {
            saveOverrideChanges()
        } else {
            saveChanges()
        }
    }

    const saveChanges = () => {
        setLoading(true)
        line.sustainabilityEstimate = calculatorMT
        APP.central.Sustainability.saveSustainabilityChanges(line, service).then((r) => {
            myBound.magicalSet("metricTonne", line.reportMetricTonne)
            //myBound.magicalSet("currentLineMetricTonne", line.reportMetricTonne)
            bound.updateLine(myBound, line)
            APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
        })
    }

    const saveOverrideChanges = () => {
        setLoading(true)
        line.reportMetricTonne = calculator.override
        line.sustainabilityEstimate = calculatorMT
        APP.central.Sustainability.saveSustainabilityChanges(line, service).then((r) => {
            myBound.magicalSet("metricTonne", line.reportMetricTonne)
            //myBound.magicalSet("currentLineMetricTonne", line.reportMetricTonne)
            bound.updateLine(line)
            APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
        })
    }

    const renderFrequency = (unitPricePer) => {
        //console.log(unitPricePer)
        switch (unitPricePer) {
            case "lift": //quantity
                return <Bound to={myBound}>
                    <InputDecimal field="quantity" disabled={true} onChange={() => testCalculator()} />
                </Bound>
            case "month": //calculated days per month, schedule, or manual?
                return <Bound to={calculator} onChange={() => setFrequencyComponent(renderFrequency2())} >
                    <LookupInputSelect what="lookup" field="sustainabilityFrequencyMethod" showEmpty={true} onChange={() => testCalculator()} defaultValue={"monthlyFactor"} />
                    {(frequencyComponent == null ? <div>Select a frequency.</div> : frequencyComponent)}
                </Bound>
            default: //no idea
              return (
                <Bound to={calculator} onChange={() => setFrequencyComponent(renderFrequency2())} >
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex"}}>
                      <LookupInputSelect what="lookup" field="sustainabilityFrequencyMethod" showEmpty={true} onChange={() => testCalculator()} defaultValue={"monthlyFactor"} /> 
                      {(frequencyComponent == null ? <div>Select a frequency.</div> : frequencyComponent)}
                    </div>
                    <div>
                      Unit price per not defined, confirm this frequency is correct.
                    </div>
                  </div>
                </Bound>
              )
            /*default: //no idea
                return <Bound to={line}>
                    <InputDecimal field="sustainabilityQuantity" onChange={() => testCalculator()} /> Unit price per not defined.
                </Bound>
                */
        }
    }

    useEffect(() => {
        setFrequencyComponent(renderFrequency2())
    }, [scheduleOptions])

    const renderFrequency2 = () => {
        service.sustainabilityFrequencyMethod = calculator.sustainabilityFrequencyMethod
        switch (service.sustainabilityFrequencyMethod) {
            case "manual":
                return <Bound to={calculator} onChange={() => testCalculator()}>
                    <InputDecimal field="manualQuantity" /*update={() => testCalculator()}*/ />
                </Bound>
            case "monthlyFactor":
                return <Bound to={service} onChange={() => testCalculator()}>
                    <InputSelect optionsCollection={scheduleOptions} field="schedule" store="object" defaultValue={service.schedule} showEmpty={false} /*onChange={() => testCalculator()}*/ disabled={true} />
                </Bound>
            case "calculatedDays":
                return <Bound to={myBound} onChange={() => testCalculator()}>
                    {myBound.magicalGet("totalServiceDays") == 0 && calculatorFrequency == 0 ? "Days for service schedule are not set." : <InputDecimal field="totalServiceDays" disabled={true} /*onChange={() => testCalculator()}*/ />}
                    
                </Bound>
            default:
                return null

        }
    }

    useEffect(() => {
        testCalculator()
    }, [])

    const calculateDifference = () => {
        console.log(parseFloat(calculator.override), line.reportMetricTonne)
        setDifferenceString(((parseFloat(calculator.override) - line.reportMetricTonne).toFixed(2) + " (" + (((calculator.override - line.reportMetricTonne) / Math.abs(line.reportMetricTonne)) * 100).toFixed(2) + "%)"))
    }

    /*
    const recalculateCalculator = () => {
        if (calculatorBound.fullness > 100.0) calculatorBound.fullness = 100.0
        else if (calculatorBound.fullness < 0.0) calculatorBound.fullness = 0.0

        calculatorBound.total = calculatorBound.materialDensity * calculatorBound.density.value * calculatorBound.volume.value * calculatorBound.frequency.value * (calculatorBound.fullness / 100.0) * calculatorBound.compacted.value
    }
    */
    /*
    if (myBound.magicalGet("line.reportExclude") == true) {
        return <div>
            Metric tonnage for this item has been overridden.
            <Button onClick={() => revertExclude(myBound)}>Revert Override</Button>
        </div>
        */

    /*

    const switchLineItemFullness = () => {
        if (lineItemFullness) {
            line.containerFullness = null;
            setLineItemFullness(false)
        } else {
            line.containerFullness = 0.00;
            setLineItemFullness(true)
        }
    }

    const switchLineItemDensity = () => {
        if (lineItemDensity) {
            line.materialDensity = null;
            setLineItemDensity(false)
        } else {
            line.materialDensity = 0.00;
            setLineItemDensity(true)
        }
    }

    */

    const updateOverride = () => {      
        if (!line.reportExclude) {
            line.estimateType = "WSC Estimate"
            setOverride(false)
        } else {
            line.estimateType = "Hauler Estimate"
            setOverride(true)
        }
    }

    const LineViewer = ({ line }) => {
        return <div>
            <NiceBoxContainer>
                {Object.keys(line).map((property) => {
                    if (typeof line[property] != "object") {
                        return <NiceBox label={property}>
                            {line[property]}
                        </NiceBox>
                    }
                    else console.log(property, line[property])
                })}
            </NiceBoxContainer>
        </div>
    }

    const doSetSustainabilitySpecificMaterial = (a,b,c) => {
      APP.central.Material.fetch(c).then((r) => {
        const newLine = {...line, materialDensity: r.result.densityInKgCubicYards};
        setLine(newLine);
        testCalculator(newLine, service);
      });
    }

    useEffect(() => {
      testCalculator();
    }, [service]);

    const doChangeLine = (a, b, c) => {
      const newLine = {...line, [b]: c};
      setLine(newLine);
      testCalculator(newLine, service);
    }

    const OverrideComponent = (props) => {
        if (props.reportExclude == true) {
            return <div>
                <InputSelect field="estimateType" options={["Actual Weight", "Hauler Estimate"]} />
            </div>
        } else {
            return <div>
                Using WSC Estimate.
            </div>
        }
    }

    useEffect(() => {
      APP.central.Service.fetchServicesForSite(site.id).then((r) => {
        setServices(r.result);
      });
    }, [calculator]);

    if (["haul", "surcharge"].includes(line.unitPricePer)) {
        return <div>
            Line does not include tonnage.
        </div>
    } else if (volumeOptions.length < 1 || scheduleOptions.length < 1) {
        return <Loading />
    } else {
        return (
          <div>
            {/*
            <HoverElement content="View Line Item">
                <LineViewer line={line}/>
            </HoverElement>
            */}

            <div className="client-information">
              <NiceBoxContainer>
                <h4>Details</h4>
                <Bound to={myBound}>
                  <NiceBox label="Service Description">
                    <InputText field="description" disabled={true} />
                  </NiceBox>
                  <NiceBox label="Material">
                    <InputText field="material.name" disabled={true} />
                  </NiceBox>
                  <NiceBox label="Equipment">
                    <InputText field="fullEquipmentName" disabled={true} />
                  </NiceBox>
                </Bound>
              </NiceBoxContainer>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="calculation-volume" style={{ flexBasis: "32%", flexDirection: "column" }}>
                <NiceBoxContainer>
                  <h4>Volume Settings</h4>
                  <Bound to={service}>
                    <NiceBox label="Volume">
                      <InputSelect
                        optionsCollection={volumeOptions}
                        field="equipmentSize"
                        defaultValue={service.equipmentSize}
                        showEmpty={false}
                        disabled={myBound.magicalGet("line.lineItemType") == "sustainability_line" && service.equipmentSize == null ? false : true}
                        onChange={() => testCalculator()}
                      />
                    </NiceBox>
                  </Bound>
                  <NiceBox label="Fullness (%)">
                    <Bound to={line}>
                      <InputDecimal field="containerFullness" update={(a, b, c) => doChangeLine(a, b, c)}/>
                    </Bound>
                  </NiceBox>
                </NiceBoxContainer>
                {calculatorVolume ? (
                  <NiceBox label="Estimate Volume" className="calculation-factor">
                    {" "}
                    {/*calculatorVolume.toFixed(2)} -&gt;*/} <NiceNumber places={2}>{unconvertedVolume}</NiceNumber> <span className="units">cubic yards</span>{" "}
                  </NiceBox>
                ) : null}
              </div>

              <div className="just-an-operator">×</div>

              <div className="calculation-density" style={{ flexBasis: "32%" }}>
                <NiceBoxContainer>
                  <h4>Density Settings</h4>
                  <Bound to={service}>
                    <NiceBox label="Sub-Material">
                      <LookupInputSelect
                        what="child_materials"
                        fetch={APP.central.Material.listChildMaterials}
                        fetchOptions={{ parentId: service.material != undefined ? service.material : null }}
                        bound={true}
                        store="object"
                        field="sustainabilitySpecificMaterial"
                        showEmpty={true}
                        onChange={(a,b,c) => doSetSustainabilitySpecificMaterial(a,b,c)}
                      />
                    </NiceBox>
                  </Bound>
                  <Bound to={line}>
                    <NiceBox label={"Material Density"}>
                      <NiceNumberWithUnits places={2} value={line.materialDensity} sup="3" unit="kg/yd" />
                      {/*<Bound to={line}>
                        <NiceNumberWithUnits places={2} value={unconvertedDensity} sup="3" unit="kg/yd" />
                      </Bound>*/}
                    </NiceBox>
                  </Bound>
                  <Bound to={service}>
                    <NiceBox label="Density Modifier">
                      <LookupInputSelect what="lookup" field="environmentalDensity" showEmpty={false} onChange={() => testCalculator()} />
                    </NiceBox>
                  </Bound>

                  {/*<NiceBox label="Compacted?">
                            <Bound to={service}>
                                <InputSelect optionsCollection={compactedOptions} field="containerCompaction" showEmpty={false} onChange={() => testCalculator()} />
                            </Bound>
                        </NiceBox>*/}
                </NiceBoxContainer>
                {calculatorDensity ? (
                  <NiceBox label="Estimate Density" className="calculation-factor">
                    {" "}
                    {/*calculatorDensity.toFixed(2)*/} <NiceNumber places={2}>{line.materialDensity}</NiceNumber> <span className="units">kg per cubic yard</span>{" "}
                  </NiceBox>
                ) : null}
              </div>

              <div className="just-an-operator">×</div>

              <div className="calculation-frequency" style={{ flex: "", flexBasis: "32%" }}>
                <NiceBoxContainer>
                  <h4>Frequency Settings</h4>
                  <Bound to={service}>
                    <NiceBox label="Frequency">
                      <div className="crappy-wrapper">{renderFrequency(line.unitPricePer)}</div>
                    </NiceBox>
                  </Bound>
                </NiceBoxContainer>
                {calculatorFrequency != null ? (
                  <NiceBox label="Estimate Frequency" className="calculation-factor">
                    {" "}
                    {calculatorFrequency}{" "}
                  </NiceBox>
                ) : null}
              </div>
              <div className="just-an-operator">=</div>
              <div className="calculation-product" style={{ flex: "", flexBasis: "32%" }}>
                {calculatorMT != null ? (
                  <NiceBox label="Total" className="calculation-factor">
                    {" "}
                    {calculatorMT} <span className="units">MT</span>{" "}
                  </NiceBox>
                ) : null}
              </div>
            </div>
            <br />

            <Bound to={line}>
              <div className="calculation-total save-sustainability">
                <NiceBoxContainer>
                  <h4>Metric Ton Override</h4>
                  <NiceBox label="Line Item">
                    <div className="small-form">
                      <InputDecimal name="Estimate" field="reportMetricTonne" disabled={true} />

                      <Bound to={calculator} onChange={() => calculateDifference()}>
                        <InputDecimal name="Override MT" field="override" disabled={false} />
                      </Bound>

                      <Bound to={line}>
                        <InputToggleSwitch
                          defaultValue={line.estimateType == "WSC Estimate" ? false : true}
                          field="reportExclude"
                          name="Use Override"
                          onChange={() => updateOverride()}
                        />
                      </Bound>
                      <div className="override field">
                        <label>Override</label>
                        <div className="value">
                          <Bound to={line}>
                            <InputSelect
                              field="estimateType"
                              defaultValue={line.reportExclude ? null : "WSC Estimate"}
                              disabled={line.reportExclude ? false : true}
                              options={["WSC Estimate", "Actual Weight", "Hauler Estimate"]}
                            />
                          </Bound>
                        </div>
                      </div>

                      <div className="field difference">
                        <label>Difference</label>
                        <div className="value">{differenceString}</div>
                      </div>
                    </div>
                  </NiceBox>
                </NiceBoxContainer>

                <br />
              </div>
            </Bound>

            <br />

            {/*loading ? <Button>Loading...</Button> : <Button onClick={() => testCalculator()}>Calculate</Button>*/}
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Button onClick={() => testCalculator()}>Recalculate</Button>
                <Button onClick={() => saveAnyChanges()}>Save</Button>
                <Button onClick={() => APP.instance.createModal("/ui/services/edit/" + service.id, { modal_name: "Service #" + service.friendlyId })}>View Service</Button>
              </div>
            )}
          </div>
        );
    }
}

function ClientDetailDisplay(props) {
   
    var addressString = ""
    if (props.data.billingAddress1 != null) addressString += (props.data.billingAddress1 + " ")
    if (props.data.billingAddress2 != null) addressString += (props.data.billingAddress2 + " ")
    if (props.data.billingCity != null) addressString += (props.data.billingCity + " ")
    if (props.data.billingProvince != null) addressString += (props.data.billingProvince + " ")
    if (props.data.billingPostal != null) addressString += (props.data.billingPostal)
    return <div>
        <NiceBoxContainer>
            <NiceBox label="Client Name">
                {props.data.name}
            </NiceBox>
            <NiceBox label="Client Address">
                {addressString}
            </NiceBox>
            <NiceBox label="Environmental Industry">
                {props.data.environmentalIndustry}
            </NiceBox>
            <NiceBox label="Environmental Density">
                {props.data.environmentalDensity}
            </NiceBox>
        </NiceBoxContainer>
    </div>
}

function TotalDiversionGraph(props) {
    console.log(props)
    const [loading, setLoading] = useState(true)

    const periodStart = moment(props.period).subtract(13, "M").format("YYYY-MM")
    const periodEnd = moment(props.period).add(0, "M").format("YYYY-MM")

    const [data, dataSet] = useState([])
    const [wasteTypes, setWasteTypes] = useState([])

    const [testState, setTestState] = useState([])

    useEffect(() => {
        setLoading(true)
        var graphData = []
        for (var i = 0; i < 11; i++) {
            graphData.push({ period: moment(periodStart).add(i, "M").format("YYYY-MM") })
        }
        var wasteTypes = []
        var serviceList = []
        props.lines.forEach((line, i) => {
            wasteTypes.push(line.material.name)
            serviceList.push({ serviceId: line.line.service, materialType: line.material.name })
        })
        serviceList = _.uniqBy(serviceList, 'serviceId')
        wasteTypes = _.uniq(wasteTypes)
        wasteTypes.forEach((type, i) => {
            graphData.forEach((period, i) => {
                period[type] = 0
            })
        })
        let serviceIdList = []
        serviceList.forEach((service) => {
            serviceIdList.push(service.serviceId)
        })
        APP.central.Sustainability.getMultipleGraphData(periodStart, periodEnd, serviceIdList/*[354, 355, 356]*/).then((r) => {
            console.log(r)
        })
        serviceList.forEach((service, i) => {
            APP.central.Sustainability.getGraphData(periodStart, periodEnd, serviceList[i].serviceId, props.period, props.value).then((r) => {
                for (const [key, value] of Object.entries(r.result.periodData)) {
                    _.find(graphData, { period: key })[serviceList[i].materialType] += value
                }
            })
        })
        setTestState(graphData)

        /*
        
        */
    }, [props])

    useEffect(() => { //@brian weird async
        var graphData = testState
        graphData.forEach((thing, i) => {
            graphData[i].value = 0;
            graphData[i].color = '#004a69';
            wasteTypes.forEach((type, i) => {
                graphData[i].value += graphData[i][type];
                console.log(graphData[i].type)
            })
            //graphData[i].label = graphData[i].period
            //graphData[i].type = "diversion"
        })
        dataSet(graphData)
        setWasteTypes(wasteTypes)
        setLoading(false)
    }, [testState])

    if (loading) return <Loading />
    else return <div>
        <BarGraph groupMode={"stacked"} data={data} keys={wasteTypes} layout="horizontal" maxValue={100} tickValues={10} symbol="mT" />
        <Button onClick={() => console.log(data)}>Check data.</Button>
    </div>

}

function SomeGraph(props) {
    const [loading, setLoading] = useState(true)
    const [firstLoad, firstLoadSet] = useState(true)

    const periodStart = moment(props.period).subtract(12, "M").format("YYYY-MM")
    const periodEnd = moment(props.period).add(0, "M").format("YYYY-MM")
    const [data, dataSet] = useState([])

    useEffect(() => {
        setLoading(true)       
        APP.central.Sustainability.getGraphData(periodStart, periodEnd, props.service, props.period, props.value, props.id).then((r) => {
            dataSet(r.result.periodData)
            setLoading(false)
            firstLoadSet(false)
        })
    }, [props])

    var graphData = []
    var valList = []

    for (const val in data) {
        graphData.push({ label: val, value: data[val], color: '#004a69' })
        valList.push(data[val])
    }

    graphData.sort((element1, element2) => {
        return parseInt((element2.label.split('-')).join('')) - parseInt((element1.label.split('-')).join(''))
    })

    graphData.find((a, i) => {
        if (graphData[i].label == props.period) {
            graphData[i].label = "Current Month"
        }
    })

    graphData.reverse();

    if (firstLoad) {
        return <div>Select data.</div>
    }

    if (loading) {
        return <Loading />
    }
    else return <div>
        <h1 style={{ display: "flex", justifyContent: "center" }}>{props.graphType}</h1>
        <h3 style={{ display: "flex", justifyContent: "center" }}>{props.site}</h3>
        <BarGraph data={graphData} layout="vertical" units="mT" legend={false} height={500} margin={{ top: 10, left: 30, right: 10, bottom: 100 }} tickValues={8} maxValue={GraphMax.GuessMax(_.max(valList))} />

        {/* <BarGraph data={data} layout="vertical" units="" legend={false} height={500} margin={{ top: 10, left: 30, right: 10, bottom: 100 }} tickValues={8} /> */}
    </div>
}

const ReportModal = (props) => {
    const [periodFrom, setperiodFrom] = useState(moment(props.period.split("-")[0]).startOf('year'))
    const [periodTo, setperiodTo] = useState(periodFrom.clone().endOf('year'))
    const [bound, setBound] = useState({ siteList: null, siteIds: null, period: props.period, periodFrom: periodFrom, periodTo: periodTo, client: props.client, titleOverride: null, _ourMagic: null, contactList: [], contactIds: null });
    const [sites, setSites] = useState(props.sites)
    const [contacts, setContacts] = useState(null)

    const generatePDF = (download) => {
        var tempIds = []
        bound.magicalGet("siteList").forEach((s) => {
            tempIds.push(s.id)
        })
        if (tempIds.length == 0) {
            alert("No sites selected.")
            return
        }
        var tempIdsContacts = []
        bound.magicalGet("contactList").forEach((c) => {
            tempIdsContacts.push(c.id)
        })
        if (tempIdsContacts.length == 0) {
            //alert("No contacts selected.")
            //return
        }
        bound.siteIds = tempIds
        bound.contactIds = tempIdsContacts
        bound.periodFrom = bound.magicalGet("periodFrom");
        bound.periodTo = bound.magicalGet("periodTo");
        const user = props.statuses[0].reportPreparer == null ? APP.getWebSession().userId : props.statuses[0].reportPreparer
        bound.magicalSet("user", user)
        APP.showLoading({ message: "Generating PDF", children: "Generating EIR.  You can minimize this dialog and the document will load when it's ready." }, () => {
            APP.central.Sustainability.createSustainabilityReport(bound, download).then((r) =>
            APP.socket.send(JSON.stringify(r))
            )
        });
    }

    useEffect(() => {
        /*APP.central.Sustainability.getFullSites(props.sites).then((r) => {
            setSites(r.result)
            setBound({ ...bound, siteList: r.result })
        })*/
        APP.central.Sustainability.getCustomerContacts(props.client.id).then((r) => {
            setContacts(r.result)
        })
    }, [])

    if (sites == null || contacts == null) {
        return <div>
            <Loading />
        </div>
    } else {
        return <div>
            <Bound to={bound} init={(bound) => setBound(bound) }>
                <h3>Generate EIR Report</h3>
                <h4>Sites</h4>
                <InputMultiCheckbox field="siteList" optionsCollection={sites} optionKey="name" name="Sites" startSelected={sites.length > 1 ? false : true}>
                    {(row) => <div className="tile-item">{row.name}</div>}
                </InputMultiCheckbox>
                <h4>Contacts</h4>
                <InputMultiCheckbox field="contactList" optionsCollection={contacts} optionKey="name" name="Contacts" startSelected={contacts.length > 1 ? false : true}>
                    {(row) => <div className="tile-item">{row.firstName + " " + row.lastName}</div>}
                </InputMultiCheckbox>
                <PeriodSelect field="periodFrom" name="Period From" />
                <PeriodSelect field="periodTo" name="Period To" />
                <InputText field="reportTitle1" name="Report Title Override" />
                <InputText field="reportTitle2" name="Report Subtitle Override" />
                <Button onClick={() => generatePDF(false)}>Generate Report</Button>
                <Button onClick={() => generatePDF(true)}>Generate and Download</Button>
            </Bound>
        </div>
    }

}

const NewReportSender = (props) => {
    const [data, setData] = useState(null)
    const [sentData, setSentData] = useState(null)

    useEffect(() => {
        APP.central.SustainabilityReport.getReports(props.period, props.client.id).then((r) => {
            setData(r.result)
        })

    }, [])

    const updateData = () => {
        APP.central.SustainabilityReport.getReports(props.period, props.client.id).then((r) => {
            setData(r.result)
            updateSent()
        })
    }

    useEffect(() => {        
        const siteIds = []
        props.sites.forEach((s) => {
            siteIds.push(s.id);
        })
        APP.central.SustainabilityReport.getPeriodDataForSites(props.period.split("-")[0], siteIds).then((r) => {
            setSentData(r.result)
        })
    }, [])

    const updateSent = () => {
        const siteIds = []
        props.sites.forEach((s) => {
            siteIds.push(s.id);
        })
        APP.central.SustainabilityReport.getPeriodDataForSites(props.period.split("-")[0], siteIds).then((r) => {
            setSentData(r.result)
        })
    }

    const viewPDF = (file, fileName) => {
        APP.instance.createModal("ui/snowman/pdf/" + file, {modal_name: "PDF: " + fileName});
    }

    const deletePDF = (row) => {
        APP.central.SustainabilityReport.deleteReport(row.id).then(() => {
            updateData()
        })
    }

    const markAsSent = (row) => {
        APP.central.SustainabilityReport.markAsSent(row.id).then(() => {
            updateData()
        })
    }

    const sendReport = (row) => {
        APP.confirm("Are you sure you want to send this report? If you need to review this report before sending it, click the cancel button.", (ok) => {
            if (ok) {
                APP.central.SustainabilityReport.sendReport(row.id, row.comment).then(() => {
                    updateData()
                })
            }
        })
    }

    const sendReports = (data) =>{ 
        APP.confirm("Are you sure you want to send these reports? If you need to review the reports before sending it, click the cancel button.", (ok) => {
            if (ok) {
                APP.central.SustainabilityReport.sendReports(data).then(() => {
                    updateData()
                })
            }
        })
    }

    const ReportDisplay = (props) => {
        if (props.data != null) { 
            const stuff = props.data.map((s) => {
                return <div>
                    {s.site.name}
                    <Tabular data={s.data}>
                        <TabularColumn title="Jan" data={(row) => row.jan > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Feb" data={(row) => row.feb > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Mar" data={(row) => row.mar > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Apr" data={(row) => row.apr > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="May" data={(row) => row.may > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Jun" data={(row) => row.jun > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Jul" data={(row) => row.jul > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Aug" data={(row) => row.aug > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Sep" data={(row) => row.sep > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Oct" data={(row) => row.oct > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Nov" data={(row) => row.nov > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                        <TabularColumn title="Dec" data={(row) => row.dec > 0 ? <Icon icon="check"/> : null /*<Icon icon="minus"/>*/} />
                    </Tabular>
                </div>
            })
            return stuff
        }
        else return null
    }

    const oneEmailButton = ()=>{
        return (data && data.length && data.length > 1 ? 
           <Button style={{marginLeft: "20px"}}className="smallButton" onClick={() => sendReports(data)}>Send All Reports in one Email</Button> : 
           <></>
         )
    }

    return <div>
        Unsent Reports for {props.period.split("-")[0]}.
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row"
        }}><ReportDisplay data={sentData} /> 
         {oneEmailButton()}             
        </div>    
        <div style={{marginTop: "30px"}}>
            <Tabular data={data}>
                <TabularColumn title="Filename" data={(row) => row.title} />
                <TabularColumn title="Report Period" data={(row) => row.displayPeriodFrom + " to " + row.displayPeriodTo} />
                <TabularColumn title="Comment" data={(row) => <Bound to={row}><InputText field="comment"/></Bound>} />
                <TabularColumn title="PDF" data={(row) => <Icon onClick={() => viewPDF(row.fileIdentifier, row.title)} icon="file-pdf"/>} />
                <TabularColumn title="Delete" data={(row) => <Icon onClick={() => deletePDF(row)} icon="trash"/>} />
                <TabularColumn title="Mark as Sent" data={(row) => <Icon onClick={() => markAsSent(row)} icon="check" />} />
                <TabularColumn title="Send" data={(row) => (row.contacts != null && row.contacts.length) > 0 ? <Icon onClick={() => sendReport(row)} icon="envelope" title={_.map(row.contactsFull, "email").join(", ")}/> : "No contacts assigned."} />
            </Tabular>
        </div>
    </div>
}


function ReportSender(props) {    

    const [status, setStatus] = useState({ status: null, siteList: [] })
    const [fileMD5, setFileMD5] = useState(null)
    const [pdfInfo, setPdfInfo] = useState({ loading: true });
    const [pageNum, setPageNum] = useState(1)
    const [fileStatus, setFileStatus] = useState({ selected: [], list: null })
    const [sending, setSending] = useState(false);
    const currentModal = useContext(CurrentModal)

    /*

    useEffect(() => {
        APP.central.Sustainability.fetchSiteSustainabilityStatus(props.sites, props.period).then((r) => {
            setStatus({status: r.result, siteList: []})
            setFileMD5(r.result[0].file.storageIdentifier)
        })
    }, [])

    useEffect(() => {
        if (fileMD5 != null) {
            APP.central.Import.pdfInfo2(fileMD5, pageNum).then((r) => {
                setPdfInfo(r.result);
            });
        }
    }, fileMD5)

    */

    useEffect(() => {
        var statusIdList = [];
        props.statuses.forEach((s) => {
            statusIdList.push(s.id)
        })
        APP.central.SiteSustainabilityStatus.getFileDisplay(statusIdList).then((r) => {          
            setFileStatus({ list: r.result, selected: [] })
        })
    }, [])

    function sendReports() {
        setSending(true)        
        var reportData = []
        fileStatus.selected.forEach((s) => {
            reportData.push({contactList: s.contactList, fileId: s.fileId, statusList: s.statusList, comment: s.comment})
        })
        APP.central.Sustainability.sendSustainabilityEmails(reportData, APP.getWebSession().userId).then((r) => {           
            APP.instance.closeModal(currentModal != undefined && currentModal.id != undefined ? currentModal.id : undefined)
        })
    }

    const TestComponent = (props) => {
        return <div>
            <div className="tile-item">{props.row.file.filename}</div>
            <div className="pdf-preview-outer" style={{ display: "flex", flex: "1 1 0" }}>
                <div style={{ order: "0", }}>
                    {pdfInfo.loading ? <Loading /> : <img src={props.row.pdfInfo.image} className="preview" style={{ width: "600px" }} />}
                </div>
                <div style={{ order: "1" }}>
                    <InputText inputType="textarea" name="Additional Comments" />
                </div>
            </div>
        </div>
    }

    const FileDisplayComponent = (props) => {
        return <div>
            Unsent Reports for YEAR
            <Bound to={props.row}>
                <div className="tile-item">{props.row.file.filename}</div>
                <div className="pdf-preview-outer" style={{ display: "flex", flex: "1 1 0" }}>
                    <div style={{ order: "0", }}>
                        <img src={props.row.pdf.image} className="preview" style={{ width: "600px" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", order: "1" }}>
                        Sites on Report
                        {props.row.statusList.map((s) => {
                            return <li className="site-list-item">{s.site.name}</li>
                        })}
                        Contacts
                        {props.row.contactList.map((c) => {
                            return <li className="contact-list-item">{c.email}</li>
                        })}
                        <div>
                            <InputText field="comment" inputType="textarea" name="Additional Comments" />
                        </div>
                    </div>
                </div>
            </Bound>
        </div>
    }

    if (fileStatus.list == null) {
        return <div>
            <Loading />
        </div>
    } else return <div>

        <Bound to={fileStatus}>
            <InputMultiCheckbox field="selected" optionsCollection={fileStatus.list} optionKey={"file.fileIdentifier"} clickBox={true} iconType="span">
                {(row) => <FileDisplayComponent row={row} />}
            </InputMultiCheckbox>
            <Button onClick={() => sendReports()}>Send Reports</Button>
        </Bound>

        {/*
        <Bound to={status}>
            <InputMultiCheckbox field="siteList" optionsCollection={status.status} optionKey="site.name" name="Sites" id="id">
                {(row) => <TestComponent row={row}/>}
            </InputMultiCheckbox>
            <Button onClick={() => sendReports()}>Send Reports</Button>
        </Bound>  
        */}
    </div>
}

const LineViewer = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        APP.central.Sustainability.getAllSustainabilityLines(props.period, props.customer, props.sites).then((r) => {
            setData(r.result)
        })
    }, [])

    const setMTToZero = (row) => {
        APP.central.Sustainability.setMTToZero(row.lineId).then((r) => {
            APP.central.Sustainability.getAllSustainabilityLines(props.period, props.customer, props.sites).then((r) => {
                setData(r.result)
            })
        })
    }

    if (data == null) return <div>
        <Loading />
    </div>

    else return <div>
        <Tabular data={data.slStubs}>
            <TabularColumn data={(row) => row.lineId} title="Line ID" />
            <TabularColumn data={(row) => row.siteName} title="Site" />
            <TabularColumn data={(row) => row.description} title="Description" />
            <TabularColumn data={(row) => row.material} title="Material" />
            <TabularColumn data={(row) => row.metricTonne} title="Metric Tonne" />
            <TabularColumn data={(row) => <Icon onClick={() => setMTToZero(row)} icon="broom"/>} title="Reset MT" />
        </Tabular>
    </div>
}