import React, { useContext, useEffect, useState } from "react";
import EditService, { LineHistory } from "../UI/Service/EditService";
import { CurrentModal } from "@opidcore/components/OpidApplication";
import { Button, Icon, Loading, SaveToolbar } from "@opidcore/components";
import _ from "lodash";

export default function SupportTempBin({ bound }) {
  const site = bound.magicalGet("site", null);
  const caseId = bound.magicalGet("id", null);
  const [loading, setLoading] = useState(false);

  const [tempBin, setTempBin] = useState(null);
  const [tempBinService, setTempBinService] = useState(null); 

  if (site == null) {
    return <span>No Site On Case</span>;
  }

  // Fetch TempBin Service for Case
  useEffect(() => {
    APP.central.TempBin.fetchForCase(caseId).then((fetchForCase) => {
      //console.log("HEY CESAR fetchForCase.result", fetchForCase.result);
      if (fetchForCase.result == null) {
        return;
      }
      if (fetchForCase.result.serviceId != null) {
        APP.central.Service.fetch(fetchForCase.result.serviceId).then((fetchService) => {
          //console.log("HEY CESAR fetchService.result", fetchService.result);
          setTempBinService(fetchService.result);
          setTempBin(fetchForCase.result);
        });
      } else {
        setTempBin(fetchForCase.result);
      }
    });
  }, []);

  const openTempBinModal = () => {
    setLoading(true);

    if (!tempBinService) {
      APP.central.Service.createTempBin({ customer: site.customer, site: site.id }).then((create) => {
        APP.central.TempBin.doTempBinServiceThings(create.result.id, caseId).then((doTempBinServiceThings) => {
          setTempBin(doTempBinServiceThings.result);
          setTempBinService(create.result);
          doActualOpenTempBinModal(create.result);
        });
      });
    } else {
      doActualOpenTempBinModal(tempBinService);
    }
  };

  const doActualOpenTempBinModal = (theTempBinService) => {
    APP.central.JasonBlocks.fetchServiceEditInfoBlocks(theTempBinService.id, theTempBinService.customer, theTempBinService.site.id).then((fetchServiceEditInfoBlocks) => {
      APP.central.ServiceContract.fetchGriddedContractLines({ serviceId: theTempBinService.id, siteId: theTempBinService.site.id }, "", "").then((fetchGriddedContractLines) => {
        setLoading(false);
        APP.instance.createModal(
          <EditService
            id={theTempBinService.id}
            additionalInfo={{
              isTempBin: true,
              serviceInfoBlocks: fetchServiceEditInfoBlocks.result,
              griddedContractLines: fetchGriddedContractLines.result,
              caseId: caseId,
              existingTempBin: tempBin ? true : false,
              doMakeChanges: doCreateTempBin,
            }}
          />,
          {
            modal_name: "Create Temp Bin Service: " + theTempBinService.friendlyId,
          }
        );
      });
    });
  };

  const doCreateTempBin = (caseId, serviceId) => {
    setLoading(true);
    return APP.central.TempBin.createOrUpdateTempBin(serviceId, caseId, site.id).then((createOrUpdateTempBin) => {
      //console.log("HEY CESAR createOrUpdateTempBin.result", createOrUpdateTempBin.result);

      if (createOrUpdateTempBin.result && createOrUpdateTempBin.result.id) {
        //console.log("HEY CESAR WE APPROVING TEMP BIN");
        return APP.central.TempBin.approveTempBin(createOrUpdateTempBin.result.id).then((approveTempBin) => {
          return _.reduce(
            createOrUpdateTempBin.result.changes,
            (previousPromise, deltas) => {
              return previousPromise.then(() => {
                return APP.central.ServiceContract.saveBoundDeltas(createOrUpdateTempBin.result.serviceId, deltas).then((saveBoundDeltas) => {
                  //console.log("HEY CESAR saveBoundDeltas result:", saveBoundDeltas);
                });
              });
            },
            Promise.resolve()
          ).then(() => {
            //console.log("HEY CESAR WE DONE APPROVING TEMP BIN");
            setTempBin(approveTempBin.result);
            setLoading(false);
          });
        });
      } else {
        setLoading(false);
      }
    });
  };

  const openViewTempBinModal = () => {
    APP.instance.createModal(<ViewCreateTempBin tempBin={tempBin} setTempBin={setTempBin} tempBinService={tempBinService} />, {
      modal_name: "View Create Temp Bin: " + tempBin.id,
    });
  };

  return (
    <div>
      <hr />

      <h2>New Temp Bin Service</h2>
      {loading ? <Loading /> : null}
      <div className="serviceChangeButtons">
        {!loading && (tempBin ? tempBin.status != "approved" : true) ? <Button onClick={() => openTempBinModal()}> Edit Temp Bin </Button> : null}
        {tempBin && tempBin.status == "approved" && !loading ? <Button onClick={() => openViewTempBinModal()}> View Created Temp Bin Service </Button> : null}
        {tempBin && !loading && tempBin.status == "approved" ? (
          <Icon onClick={() => APP.instance.createModal(<LineHistory serviceId={tempBinService.id} />)} icon="history" size="2x" title="View Service History" />
        ) : null}
      </div>

      <hr />
    </div>
  );
}

// ============================== ViewCreateTempBin ==============================
// UNUSED

const ViewCreateTempBin = ({ tempBin, setTempBin, tempBinService }) => {
  const thisModal = useContext(CurrentModal);

  const approveTempBin = () => {
    //console.log("HEY CESAR WE APPROVING TEMP BIN");

    return APP.central.TempBin.approveTempBin(tempBin.id).then((approveTempBin) => {
      return _.reduce(
        tempBin.changes,
        (previousPromise, deltas) => {
          return previousPromise.then(() => {
            return APP.central.ServiceContract.saveBoundDeltas(tempBin.serviceId, deltas).then((saveBoundDeltas) => {
              //console.log("HEY CESAR saveBoundDeltas result:", saveBoundDeltas);
            });
          });
        },
        Promise.resolve()
      ).then(() => {
        //console.log("HEY CESAR WE DONE APPROVING TEMP BIN");
        setTempBin(approveTempBin.result);
        APP.instance.closeModal(thisModal != undefined && thisModal.id != undefined ? thisModal.id : undefined);
      });
    });
  };

  return (
    <div>
      <h2>View/Create Temp Bin</h2>
      <EditService id={tempBinService.id} />
      {tempBin.status != "approved" ? <SaveToolbar handleSave={approveTempBin} saveVerb="Approve Temp Bin Request" /> : null}
    </div>
  );
};
