import React, { Component, useContext, useEffect, useState } from 'react';
import { Bound, BoundDataContext, AccessControl } from '.';
import Icon from './Icon';
var _ = require('lodash');
import PropTypes from 'prop-types';

export const ToolbarContext = React.createContext({});

export default function SaveToolbar(props) {
  const [savingState, setSavingState] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const cancelAllowed = props.showCancel;
  const [toolbarContext, setToolbarContext] = useState({});

  const bound = useContext(BoundDataContext);

  useEffect(() => {
    if (bound && bound.on) {
      bound.on("*", (a, b, c) => {
        if (c.deltas.length >= 1) {
          setShowCancel(true);
        }
      });
    }
  }, [bound]);

  useEffect(() => {
    if (savingState == "times") {
      setTimeout(() => setSavingState(""), 1000);
    }
  }, [savingState]);

  const doSave = (a, b, c) => {
    if (savingState == "spinner") {
      return;
    }
    setSavingState("spinner");

    let handler = null;
    try {
      handler = props.handleSave(a, b, c);

      if (handler != null && handler.result == "error") {
        setTimeout(() => setSavingState("toilet"), 500);
        setTimeout(() => setSavingState(""), 1250);
      } else if (handler && handler.then) {
        return handler.then((result) => {
          setTimeout(() => setSavingState("check"), 500);
          setTimeout(() => setSavingState(""), 1250);
        });
      } else {
        setTimeout(() => setSavingState("check"), 1250);
        setTimeout(() => setSavingState(""), 2000);
      }
    } catch {
      setSavingState("times");
    }
  }

  const doCancel = (a, b, c) => {
    props.onCancel && props.onCancel(a, b, c);
  };

  useEffect(() => {
    setToolbarContext({
      save: doSave,
      wonder: 7
    });
  }, []);

  return <div className="saveToolbar container">
    <AccessControl permission="W">
      {showCancel && cancelAllowed ? <button type="button" className="btn btn-secondary" onClick={(a, b, c) => doCancel(a, b, c)}>Cancel</button> : null}
      <button type="button" style={props.saveButtonStyle ? props.saveButtonStyle : null} className="btn btn-primary flexed" onClick={(a, b, c) => doSave(a, b, c)}>
        <Icon icon={savingState ? savingState : ""} size="2x" color="white" className={savingState} />
        <div>{props.saveVerb ? props.saveVerb : "Save"}</div>
      </button>
      {props.message ?
        <h4>{props.message}</h4> :
        null
      }
    </AccessControl>

    <ToolbarContext.Provider value={toolbarContext}>
      {props.children}
    </ToolbarContext.Provider>
  </div>
}

SaveToolbar.propTypes = {
  message: PropTypes.string, // message to display
  children: PropTypes.any, // children to render
  handleSave: PropTypes.func, // function to call when save is clicked
  saveVerb: PropTypes.string, // verb to use for save button
  saveButtonStyle: PropTypes.object, // style to apply to save button
  showCancel: PropTypes.bool, // show cancel button
  onCancel: PropTypes.func // function to call when cancel is clicked
}

