import React, { useEffect, useState, useContext, useRef }  from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './Bound';
import * as _ from 'lodash';
import Icon from './Icon';
import Loading from './Loading';

/*
props:
    name
    field
    preFetchAll
    central
    children
    className
    onSelect
*/

export default function InputAutoComplete(props) {
    const bound = useContext(DataContext);
    const [optionsData, setOptionsData] = useState([]);
    const [value, setValue] = useState();
    const initialValue = useRef();
    const searchCount = useRef(0);
    const lastSearch = useRef("");
    const [lastRefresh, setLastRefresh] = useState(0);
    const [searching, setSearching] = useState({search: null, shouldExpand: null, searchById: null});

    useEffect(()=>{
        if(searching.search != null){
            centralThing.current(searching.search, searching.shouldExpand, searching.searchById);
        }
    }, [searching]);

    useEffect(()=>{
        if (lastRefresh != props.refresh && props.refresh > 0){
            setLastRefresh(props.refresh);
            setSearching({search: lastSearch.current, shouldExpand: false, searchById: null});
        }
    }, [props.refresh, lastRefresh]);

    const centralThing = useRef(
        _.debounce( (search, shouldExpand, searchById)=>{
            searchCount.current = searchCount.current + 1;
            const thisSearch = searchCount.current;
            props.central(search, shouldExpand, searchById).then((r)=>{
                if (thisSearch == searchCount.current){
                    setOptionsData(r.result);
                }

                setSearching({search: null, shouldExpand: null, searchById: null});
            });
        }, 1500 )
    );

    const appStatePath = "autocomplete/" + props.field;
    
    const setFromAppState = ()=>{
    	const appState = APP.getState(appStatePath)
    	const item = appState && appState.find ? appState.find( (r)=>r.key == initialValue.current ) : null;
    	if (item){
    		setValue(item.displayValue);
    	}
    }
    const setValueFromThing = (thing)=>{
        const search = thing;
        const shouldExpand = props.shouldExpand? props.shouldExpand : false;
        const searchById = props.searchById? props.searchById : true;
        if(search != undefined && !(search == "" || search == 0)){
            if(isFinite(search)){
                props.central(search, shouldExpand, searchById).then((r)=>{
                    if(r.result.length > 0){
                        setValue(r.result[0].displayValue);
                    }                
                });
            } else if(search.id) {
                props.central(search.id, shouldExpand, searchById).then((r)=>{
                    if(r.result.length > 0){
                        setValue(r.result[0].displayValue);
                    }                
                });
            }
        }
    }

    useEffect(()=>{
        const boundValue = bound.magicalGet(props.field);

        if(initialValue.current != boundValue){
            initialValue.current = boundValue;
        
            bound.magicalState(props.field, (thing)=>{
                initialValue.current = thing;
                setValueFromThing(initialValue.current);
            });
            
        
            if (props.preFetchAll){
                let found = false;
                
                const options = APP.getState(appStatePath, (a)=>{
                    if (a != "loading"){
                        setFromAppState(initialValue.current);
                    }
                });
                if (_.isArray(options)){
                    setFromAppState(initialValue.current);
                }
                
                if (APP.getState(appStatePath) == null){
                    APP.setState(appStatePath, "loading");
                    
                    props.central("__ALL__").then( (r)=>{	       
                        APP.setState(appStatePath, r.result);
                    });	        	
                }
                
                return;
            }
            
            
            setValueFromThing(initialValue.current);
        }
    });
    
    
    const onChange = (e)=>{
        if(e.currentTarget.value.length > 0){
            if(props.central != undefined){
                const search = e.currentTarget.value;
                lastSearch.current = search;
                const shouldExpand = props.shouldExpand? props.shouldExpand : false;
                const searchById = props.searchById? props.searchById : false;
                setSearching({search: search, shouldExpand: shouldExpand, searchById: searchById});
            } else {
                console.log("Missing central function for auto complete of " + props.field);
            }

            setValue(e.currentTarget.value);
        } else {
            if(bound && bound.magicalSet){
                bound.magicalSet(props.field, null);
            }

            setValue(null);
        }
    }
    
    const onSelect = (option)=>{
        if(props.store == "object"){
            if (option.basis && option.basis.__type){
                bound.magicalSet(props.field, option.basis);
            }else{
                //we can assume we really don't care as the bound uses an object as it's value - or we don't care
                bound.magicalSet(props.field, option);
            }
        } else {
            if(option.key){
                bound.magicalSet(props.field, option.key);
            } else {
                bound.magicalSet(props.field, option);
            }
        }
        
        if(props.onSelect){
            props.onSelect(option, bound);
        }
        
        setValue(option.displayValue);
        setOptionsData([]);
    }

    const optionsToUse = _.groupBy(optionsData, "className");
    
    let optionsList = undefined;

    if(props.dataCallback != undefined){
        optionsList = props.dataCallback(optionsData);
    } else {
        optionsList = _.map(optionsToUse, (oTUValues, oTUKey) => {
            const content = _.map(oTUValues, (option, index)=>{
                let display = option;
                if(option.displayValue){
                    display = option.displayValue;
                }
                
                if(props.children != undefined){
                    display = props.children(option);
                }
                return <span key={option.id || index} onClick={(a)=>onSelect(option)}>
                    {display}
                </span>
            });

            const label = _.keys(optionsToUse).length > 1 && (oTUKey != undefined && oTUKey.trim().length > 0)? oTUKey : undefined;

            return <div key={oTUKey}>
                {label}
                {content}
            </div>
        });
    }

    const resetValue = ()=>{
        if(bound && bound.magicalSet){
            bound.magicalSet(props.field, null);
        }
        setValue(null);
    }
    const canEdit = props.readOnly != true;


    return <div key="input_auto_complete" className={"input_auto_complete field " + (props.className || "")} >
        <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
            {props.name ? <label style={{marginRight: 5}}>{props.name}</label> : ""}
            <div className="input" style={{ display: 'flex', paddingTop: '10px' }}>
                {canEdit ? <input type="text" onChange={(e) => onChange(e)} value={value || ""} disabled={props.disabled} /> : <div className="readOnly-field">{value}</div>}
                {canEdit ? searching.search != null ? <Loading size="2x" /> : <Icon onClick={() => resetValue()} icon="backspace" className="clear" /> : null}
            </div>
        </div>
        <div className="options" style={{cursor: 'pointer'}}>
            {optionsList}
        </div>
    </div>
}

InputAutoComplete.propTypes = {
    componentName: PropTypes.string,
    componentType: PropTypes.string,
    field: PropTypes.string,
    name: PropTypes.string,
    central: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func
    ]),
    onSelect: PropTypes.func,
    shouldExpand: PropTypes.bool,
    searchById: PropTypes.bool,
    dataCallback: PropTypes.func,
    disableResults: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    refresh: PropTypes.number,
    store: PropTypes.oneOf(["object", "string"]),
    preFetchAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
    style: PropTypes.object
}

InputAutoComplete.defaultProps = {
    componentName: "InputAutoComplete",
    componentType: "BoundComponent",
    store: "object",
    disabled: false
}