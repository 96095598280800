import React, { useContext, Fragment, useState, useEffect } from "react";
import { useData } from "@opidcore/hooks/WTF";
import BoundCommit from "@opidcore/components/BoundCommit";
import {
  InputText,
  DatePicker,
  UnlockToEdit,
  Icon,
  TagPicker,
  BoundView,
  Bound,
  InputSelect,
  Button,
  InputToggleSwitch,
  FlexRow, useMagic, Loading
} from "@opidcore/components";
import { TermPicker, EndDatePicker } from "@opidcore/components/DatePicker";
import ActionBar from "@opidcore/components/ActionBar";
import { ContractStatusLight } from "../StatusLight";
import { File, NiceBox, NiceBoxContainer, LinesThing } from "../../components";
import { useParams } from "react-router-dom";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import Notes from "../Notes";
import Tasks from "../Tasks";
import _ from "lodash";
import { NiceBoxSection } from "../../components/NiceBox";
import ChangeLogs from "../ChangeLog/View";

export default function ClientEditContract(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const [data, dataSet] = useData("client_contract", params.id, extra);

  const addSites = (sites) => {
    APP.central.ClientContract.addTo(data.id, sites).then((r) => {
      //hopefully this can be smarter, or something?
      APP.central.Util.clearCache(dataSet.uid).then((r) => {
        dataSet.fetch();
      });
    });
  };

  const refreshDataset = () => {
    APP.central.Util.clearCache(dataSet.uid).then((r) => {
      dataSet.fetch();
    });
  };

  const openEditContract = () => {
    APP.instance.createModal(<EditClientContractDetails data={data} dataSet={dataSet} afterSave={refreshDataset} />, { modal_name: "Client Contract #" + params.id });
  };

  const deleteContract = (id) => {
    APP.confirm("Are you sure?", (res) => {
      if (res == true) {
        APP.alert("Not yet implemented");
      }
    });
  };

  return (
    <div key="edit_client_contract">
      <h2 style={{ flex: "auto" }}> Client Contract</h2>
      <ActionBar model={data} showNotes={true} showTasks={true}>
        <ContractStatusLight contract={data} />
        <div style={{ flex: "auto" }} />
        {data.__type ? <Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} /> : <Loading/>}
        {/*<Icon icon="pencil" size="2x" className="open-edit-contract" onClick={openEditContract} />*/}
        <TagPicker />
        <Notes model={data} />
        <Tasks model={data} />
        <ChangeLogs model={data}/>
      </ActionBar>

      {data.__type ? <DisplayClientContract clientContract={data} /> : <Loading/>}

      <h2>Contract Lines</h2>
      <LinesThing lines={data.lines} groups={["site", "service"]} dataSet={dataSet} onDoStuff={(sites) => addSites(sites)} />
      {data.lines && data.lines.length && data.lines.length > 0 ? null : <Button onClick={() => deleteContract(data.id)}>Delete Contract</Button>}
    </div>
  );
}

const EditClientContractDetails = ({ data, dataSet, afterSave = undefined }) => {
  const [lookups, lookupsDS] = useData("lookup;clientContractExpiryStatus", { name: "clientContractExpiryStatus" });

  const isActive = (status) => {
    return status == "active";
  };
  

  const [showTermDate, setShowTermDate] = useState(!isActive(data.status));
  const [contract, setContract] = useState(data);
  const [contractStatusBool, setContractStatusBool] = useState({ status: isActive(data.status) });
  const [expiryOpts, setExpiryOpts] = useState({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month to Month" , autoRenewal: "Auto Renewal"});

  const bound = useMagic({});

  useEffect( ()=>{
    bound.magicalState("status", (s)=>{
      setShowTermDate(!isActive(s));
    } );
    
  }, [bound]);

  useEffect(() => {
    updateExpiryOpts();
  }, [lookupsDS.loading]);

  useEffect(() => {
    updateExpiryOpts();
  }, [contract]);

  useEffect(() => {
    setContract(data);
    bound.replaceTo(data);
    setContractStatusBool({ status: isActive(data.status) });
  }, [data]);

  const doAfterSave = () => {
    APP.central.ClientContract.fetch(contract.id).then((r) => {
      if (r.status == "ok") {
        setContract(r.result);
        bound.replaceTo(r.result);
      }
      if (afterSave) {
        afterSave();
      }
    });
  };

  useEffect(() => {
    doAfterSave();
  }, []);

  const updateExpiryOpts = () => {
    if (lookups && lookups.length > 0 && bound.magicalGet("expiryStatus")) {
      const statusOpt = _.find(lookups, { key: bound.magicalGet("expiryStatus") });
      if (statusOpt && statusOpt.key) {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month", autoRenewal : "Auto Renewal" , [statusOpt.key]: statusOpt.label});
      } else {
        setExpiryOpts({ lost: "Lost", renewed: "Renewed", monthToMonth: "Month To Month" , autoRenewal : "Auto Renewal"});
      }
    }
  };

  const doOnStatusChange = (s) => {
    bound.magicalSet("status",  s ? "active" : "inactive");
    updateExpiryOpts();
  };

  return (
    <Fragment>
      <div>
        <FlexRow>
          <h2>{contract && contract.customer ? contract.customer.name : null}</h2>
          <Bound to={contractStatusBool}>
            <InputToggleSwitch
              field="status"
              name={(status) => {
                return status == undefined || status == false ? "Inactive" : "Active";
              }}
              onChange={doOnStatusChange}
            />
          </Bound>
        </FlexRow>
        <ContractStatusLight contract={contract} />
      </div>
      <BoundCommit
        to={bound}
        dataSet={dataSet}
        commit={APP.central.ClientContract.saveChanges}
        afterSave={doAfterSave}
        boundId={"editClientContractDetailsBound"}
      >
        <UnlockToEdit>
          <InputText field="friendlyId" name="#" />
        </UnlockToEdit>
        {showTermDate ? (
          <Fragment>
            <DatePicker field="terminationDate" name="Termination Date" required />{" "}
            <InputText field="terminationNote" name="Termination Note" inputType="textarea" required />
          </Fragment>
        ) : null}
        <DatePicker field="effectiveFrom" name="Start" required={true} />
        <TermPicker field="contractTerm" startField="effectiveFrom" endField="effectiveTo" required={true} />
        <EndDatePicker termField="contractTerm" startField="effectiveFrom" field="effectiveTo" name="End" required={true} />
        <InputText  field="expiryPeriod" inputType="number" name="Expiry Warning Period (days)" />
        <InputSelect options={expiryOpts} name="Expiry Status" field="expiryStatus" showEmpty={true} />
        {/*<InputText field="internalNotes" name="Notes" inputType="textarea" />*/}
        {/*<InputText field="contractIssues" name="Contract Issues" inputType="textarea" />*/}     
      </BoundCommit>
    </Fragment>
  );
};

export { EditClientContractDetails };

export function DisplayClientContract(props) {
  const [files, setFiles] = useState([]);

  if (props.clientContract == undefined) {
    return null;
  }

  const openAnnotations = (md5) => {
    APP.alert("hey " + md5);
  };

  return (
    <div className="edit-client-contracts">
      <Bound to={props.clientContract} boundId="clientContractNiceBlocks" disableEditing={true}>
        <NiceBoxSection>
          <NiceBoxContainer>
            <h4>Client Info</h4>
            <NiceBox label="Client #">
              <BoundView boundTo="customer" key="customer-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Client">
              <BoundView boundTo="customer" key="customer-name">
                <InputText field="name" disabled={true} />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>

          <NiceBoxContainer>
            <h4>Contract Info</h4>
            <NiceBox label="Client Contract #">
              <BoundView key="contract-id">
                <InputText field="friendlyId" disabled={true} />
              </BoundView>
            </NiceBox>

            <NiceBox label="Start">
              <BoundView key="contract-effectiveFrom">
                <DatePicker field="effectiveFrom" />
              </BoundView>
            </NiceBox>

            <NiceBox label="End">
              <BoundView key="contract-effectiveTo">
                <DatePicker field="effectiveTo" />
              </BoundView>
            </NiceBox>
          </NiceBoxContainer>
        </NiceBoxSection>

        <File onFileList={(files) => setFiles(files)} showHistoricalSet={true} />
      </Bound>
    </div>
  );
}

