import React, { useContext, useState } from 'react'
import { DataContext } from '@opidcore/components/Bound'
import SupportServiceChange from './SupportServiceChange'
import SupportTempBin from './SupportTempBin'
import SupportGreaseTrap from './SupportGreaseTrap'
import { Bound, BoundCommit, Icon, Tabbed } from '@opidcore/components'
//import { useData, useMagic } from '@opidcore/hooks/WTF'
import _ from 'lodash'
import Fields from '../../../js/Form/Fields'


export default function SupportRelatedItem({formUID}) {

    const bound = useContext(DataContext);
    const customFieldsBound = bound.getBound("details")._ourMagic;
    const [refreshView, setRefreshView] = useState('initial-view');

    formUID = bound.magicalGet("formUID")
    // formInstanceID = bound.magicalGet("formInstanceId")

    if (formUID == undefined || formUID == null || formUID == "") {
        return null
    }

    // These are to get the new style of service forms to work. They aren't real forms (see Form.java and related for those), so we need to do this.
    if(formUID == "GREASE_TRAP") {
      return <SupportGreaseTrap bound={bound} />
    }
    if(formUID == "SERVICE_CHANGE") {
      return <SupportServiceChange bound={bound} />
    }
    if(formUID == "TEMP_BIN_REQUEST_NEW") {
      return <SupportTempBin bound={bound} />
    }

    /**
     * SupportRelatedItem grabs all the stuff for whatever might be related to a task type.
     * @param relatedTable - This is the name of the table we need to pull from.
     * @param relatedId - Not really sure how this should work yet, or if it even needs to.
     */

    //const [data, dataSet] = useData(formUID, formInstanceID)
    var subForm = APP.findForm(formUID)

    const updateFunc = (a,field,value,_bound) => {
        if (_bound != null){
            _bound.magicalSet(field,value);
        }
    }

    const saveFunc = (a,b,c,d,e)=>{
        const bb = bound;
        const cc = customFieldsBound;

        //const _data = {};
        //APP.central.Stuff.saveTestData(data);
        debugger;
    }

    const handleAddRow = (sectionUid)=>{
        const size = customFieldsBound.magicalGet(sectionUid).length;
        const newRow = customFieldsBound.getBound(sectionUid, size);
        newRow._ourMagic.magicalSet('__created', new Date().toLocaleString());
        setRefreshView(sectionUid + "-" + size);
    };

    const handleDelete = (sectionUid, row)=>{
        const size = customFieldsBound.magicalGet(sectionUid).length;
        customFieldsBound.magicalSplice(sectionUid, row._keyPathPos);
        customFieldsBound.clearGotBounds();
        setRefreshView("removed-" + sectionUid + "-" + size);

        /** const newRow = customFieldsBound.getBound(sectionUid, size);
        newRow._ourMagic.magicalSet('__created', new Date().toLocaleString());
         **/
    }

    let renderedSections = [];
    

    _.forEach(subForm.sections, (section)=>{
        let content = <></>;

        if (section.type == 'one-to-many'){
				let rows = [];
				let mySection = customFieldsBound.magicalGet(section.uid, null);
                
                if (mySection == null){
                    customFieldsBound.magicalSet(section.uid, []);
                    mySection = customFieldsBound.magicalGet(section.uid);
                }

                if (mySection.length == 0){
                    const newRow = customFieldsBound.getBound(section.uid, 0);
                    newRow._ourMagic.magicalSet('__created', new Date().toLocaleString());
                }

                /** <RowStatus handleDelete={}/> */
                            
                _.each(mySection, (r, index)=>{
                    var fields = [];//  form.fields.filter(f=>f.section == section.uid);
                    //console.log("TODO -- @ian you need to figure this out.  @cody can't delete this console.log");

                    let  extraStuff = null;
                    
                    const rowBound = customFieldsBound.getBound(section.uid, index);

                    rows.push(
                        <div className="repeated-row">
                            <Bound to={rowBound._ourMagic}>
                                <Fields
                                    className={"something_outer"}
                                    viewType={"embed"}
                                    form={subForm}
                                    central={APP.central}
                                    section={section.uid}
                                    outerTag="div"
                                    update={updateFunc}
                                    fields={_.filter(subForm.fields, { section: section.uid })} />
                                <Icon icon="trash" onClick={(e)=>handleDelete(section.uid,rowBound)} />
                            </Bound>
                       </div>
                    );
                
                });
			
				content = <div className="one-to-many-body">

                    {rows}

                    <div className="add_row">
                        <a className="button btn-primary btn" onClick={()=>handleAddRow(section.uid)}><Icon icon="plus-square"/> Add Row</a>
                    </div>
                </div>
        }else{
            content = <Fields
                    boundTo={customFieldsBound}
                    className={"something_outer"}
                    viewType={"embed"}
                    form={subForm}
                    central={APP.central}
                    section={section.uid}
                    outerTag="div"
                    update={updateFunc}
                    fields={_.filter(subForm.fields, { section: section.uid })} />;
        }
        renderedSections.push( 
            <div title={section.title} className="basicForm">
                { content }                        
            </div>
        );

    });

    /**    */


    return <div>
        <Bound to={customFieldsBound}>
            <Tabbed inline={true}>
                {renderedSections}
            </Tabbed>
            <span style={{display:"none"}}>{refreshView}</span>
        </Bound>
    </div>
}