import React, { useEffect, useState, useContext, useRef } from 'react';
// import BoundCommit from '@opidcore/components/BoundCommit';
import Bound, { BoundMagic } from '@opidcore/components/Bound';
import { DataContext } from '@opidcore/components/Bound';
import {InputText, DatePicker, LookupInputSelect, Expandable, Grid, InputSelect, InputToggleSwitch, BoundDataContext, Icon, Loading, Button, SaveToolbar, FlexRow, InputDecimal} from '@opidcore/components';
import { GridItem, GridHeading} from '@opidcore/components/Grid';
// import { sortBy } from 'lodash';
import _ from 'lodash';
import { APP } from '../../../../js/index';
import ItemCalculator from './ItemCalculator';
import {  ClientInfo, BaselineInfo } from '../../components/JasonBlocks';
// import SelectClientSiteService from '../../components/SelectClientSiteService';
import { useData, useMagic } from '@opidcore/hooks/WTF';
import { CurrentModal, RoutedDefaults } from '@opidcore/components/OpidApplication';
import moment from 'moment';
//import { NiceMaterial, NiceEquipmentType, NiceCurrency } from '../Nice';
import { ClientSiteServiceSelector, NiceBox, NiceBoxContainer } from '../../components';
import { generatePath } from 'react-router';
import { useHistory,  useRouteMatch } from 'react-router-dom';
import Util from '@opidcore/Util';
import { EditVendorActivity } from '../Admin/VendorActivities'
// import { faPoundSign } from '@fortawesome/free-solid-svg-icons';

const itemFilter = (boundItem, comparisonItem)=>{
    const ret = [undefined, undefined];

    if( comparisonItem.magicalGet("lineItemRelatedTypeId", undefined) == boundItem.magicalGet("lineItemRelatedTypeId", undefined)
        && comparisonItem.magicalGet("service", undefined) == boundItem.magicalGet("service", undefined)
        && _.isEqual(comparisonItem.magicalGet("site", undefined), boundItem.magicalGet("site", undefined)) ){

        if(boundItem.magicalGet("activityMappingId", null) != null
            && comparisonItem.magicalGet("activityMappingId", null) == boundItem.magicalGet("activityMappingId", null)){
            ret[0] = true; //same row of grid and same contract line
        } else {
            ret[1] = true; //just same contract line
        }
    }

    return ret;
}

function ItemCalculatorIcon(props){
    const boundMagic = useContext(BoundDataContext);
    const modalContext = useContext(CurrentModal);
    const [allClientsLineItemsData, allClientsLineItemsDataset] = useData("client_line_items" + props.serviceData.customer, APP.central.Customer.fetchAllLineItems, {clientId: props.serviceData.customer});
    const [allClientsLineItems, setAllClientsLineItems] = useState([]);
    
    useEffect(()=>{
        setAllClientsLineItems(allClientsLineItemsData);
    }, [allClientsLineItemsDataset.loading]);

    const parentMagic = boundMagic.getParentMagic();

    if(parentMagic == null){
        return null;
    }

    const openDetails = ()=>{
        const mappingId = boundMagic.magicalGet("activityMappingId");
        const modalOptions = {};
        if (modalContext != null && modalContext.id != undefined){
            modalOptions.dependantOn = modalContext;
        }
        APP.instance.createModal(<EditLineDetails magic={parentMagic} mappingId={mappingId}/>, null, modalOptions);
    }

    const openCalculator = ()=>{
        const activity =  boundMagic.magicalGet("activity", "");
        const title = ("Calculator: " + activity).trim();
        const boundMagicItems = [];
        const allLineItems = [];

        _.forEach(parentMagic._gotBounds, (comparisonItem, comparisonItemKey)=>{
            const shouldUse = itemFilter(boundMagic, comparisonItem._ourMagic);
            if(shouldUse[0] != undefined){ //same row of grid and same contract line
                boundMagicItems.push(comparisonItem._ourMagic);
                allLineItems.push(comparisonItem._ourMagic);
            } else if(shouldUse[1] != undefined){ //just same contract line
                allLineItems.push(comparisonItem._ourMagic);
            }
        });

        _.forEach(allClientsLineItems, (comparisonItem)=>{
            const comparisonItemBoundMagic = BoundMagic.create(comparisonItem);
            allLineItems.push(comparisonItemBoundMagic);
        });

        ///  it sort of needs to be both :(
        const modalProps = {
            serviceData: props.serviceData,
            allLineItemMagics: _.uniqBy(allLineItems, (item)=>{ return item.magicalGet('lineItemType') + " " + (item.magicalGet("vendorActivityDescription") || item.magicalGet("activity"))}),
            lineItemsBoundMagics: boundMagicItems
        }
        
        try{
            const theVendorStuff = boundMagic.getParentMagic().getParentMagic().to.contractLines[0]["vendor_contract_lines"][0];
            
            if (theVendorStuff.vendorServiceReference.indexOf('SPLIT') >= 0){
                modalProps.showSplit = true;
            }

        }catch(e){
            // this is a mess!
        }

		APP.instance.createModal(<ItemCalculator {...modalProps}/>, {modal_name: title});
    };

    const hasCalculator = boundMagic.magicalGet("calculator").id > 0;

    return <div className="modal-link" style={{display: "flex"}}>
        <Icon title="Item Calculator" icon="calculator" size="3x" color={!hasCalculator? "#ccc" : undefined} onClick={()=>openCalculator()}/>
        <Icon title="View Details" icon="pencil" size="3x" color="#ccc" onClick={()=>openDetails()}/>
    </div>;
}

function FindService({close}){
    const history = useHistory();
    const match = useRouteMatch();

    const setter = (service, site, client) =>{
        const id = parseInt(service.id);
        const closeMyModal = false;

        // @ts-ignore
        const currentIds = match.params.serviceIds;
        if(currentIds == id){
            return closeMyModal;
        }

        if(currentIds.split(",").indexOf(id) > -1){
            return closeMyModal;
        }
        
        const path = generatePath(match.path, { serviceIds: currentIds + "," + id });
        history.replace(path);
        close();

        return closeMyModal;
    }

    return <ClientSiteServiceSelector callback={(service, site, client)=>setter(service, site, client)} labelText="Next" serviceCheckable={true}/>;
}

export default function MultipleEditContract({
  match,
  manageMultiple = true,
  embedded = false,
  singleService = 0,
  registeredGrids = useRef({}),
  showInactive = true,
  isServiceChange = false,
}) {
  const [servicesComponents, setServicesComponents] = useState([]);
  const extra = useContext(RoutedDefaults);
  const [addOpen, setAddOpen] = useState(extra && extra.openSearch ? true : false);
  const [theServices, setTheServices] = useState({});
  const [loadingService, setLoadingService] = useState(false);
  const previousShowInactive = useRef(showInactive);

  const registerGridContext = (gridCtx) => {
    if (gridCtx.id != undefined) {
      if (registeredGrids.current[gridCtx.id] == undefined) {
        registeredGrids.current[gridCtx.id] = gridCtx;
      }
    }
  };

  const loadService = (serviceId) => {
    setLoadingService(true);
    APP.central.Service.fetch(serviceId).then((r) => {
      const newTheServices = { ...theServices };
      newTheServices[serviceId] = { data: r.result, updated: true };

      setTheServices(newTheServices);
      setLoadingService(false);
    });
  };

  useEffect(() => {
    const newServiceComponents = [];
    const newTheServices = { ...theServices };
    let updated = false;
    console.log("previousShowInactive", previousShowInactive.current);

    _.forEach(_.keys(newTheServices), (serviceId) => {
      if (newTheServices[serviceId].updated == true || previousShowInactive.current != showInactive) {
        updated = true;
        newTheServices[serviceId].updated = false;
        previousShowInactive.current = showInactive;
        newServiceComponents.push(
          <SingleEdit
            embedded={embedded}
            extra={extra}
            key={serviceId}
            serviceData={newTheServices[serviceId].data}
            registerGridContext={registerGridContext}
            showInactive={showInactive}
            isServiceChange={isServiceChange}
          />
        );
      }
    });

    if (updated) {
      setTheServices(newTheServices);
      setServicesComponents(newServiceComponents);
    }
  }, [theServices, showInactive]);

  useEffect(() => {
    //router match
    if (match != null && match.params) {
      _.each(match.params.serviceIds.split(","), (serviceId) => {
        if (serviceId != undefined && serviceId != "" && serviceId > 0) {
          loadService(serviceId);
        }
      });
    }
  }, [match]);

  useEffect(() => {
    if (singleService > 0) {
      loadService(singleService);
    }
  }, [singleService]);

  const reloadTheWorld = () => {
    _.forEach(_.keys(APP.registeredBoundMagics), (rbmKey) => {
      APP.registeredBoundMagics[rbmKey].resetDelta();
    });

    _.forEach(_.keys(registeredGrids.current), (k) => {
      registeredGrids.current[k].resetAllBoundDeltas();
    });

    setTheServices([]);
  };

  const doSave = () => {
    const promises = _.map(_.keys(APP.registeredBoundMagics), (rbmKey) => {
      const rbm = APP.registeredBoundMagics[rbmKey];

      if (rbm.isDirty()) {
        const deltas = rbm.getAllDeltas();

        if (deltas.length > 0) {
          return APP.central.Bound.saveChanges(deltas);
        } else {
          //console.log("hey cody we don't have a delta but we are dirty!");
          debugger;
        }
      }
    });

    Promise.all(promises).then((res) => {
      reloadTheWorld();
    });
  };

  const closeFinder = (s) => {
    setAddOpen(false);
  };

  return (
    <div className="edit-multiple-service-lines">
      {manageMultiple ? (
        <>
          <h2>Add/Edit Activities</h2>
          <CurrentEditingCrazyContext.Provider value={registeredGrids}>{extra ? extra.referenceElement : null}</CurrentEditingCrazyContext.Provider>
          {servicesComponents.length > 0 ? <Button onClick={() => setAddOpen(true)}>Add Service</Button> : null}
          {addOpen ? <FindService close={() => closeFinder()} /> : loadingService == true ? <Loading /> : null}
        </>
      ) : null}
      <div className="services-editor">{servicesComponents}</div>

      {embedded == false && servicesComponents.length > 0 ? <SaveToolbar handleSave={doSave} /> : null}
    </div>
  );
}

export const CurrentEditingCrazyContext = React.createContext({});

function SingleEdit(props){
    const [single, setSingle] = useState(null);
    const [boundState, setBoundState] = useState(null);
    const stuff = useRef(null);
    const embedded = props.embedded || false;
    const isServiceChange = props.isServiceChange || false;

    const filterContractLines = (lines) =>{
        const filteredLines = _.map(lines, (line)=>{
            const newLine = {...line};
            newLine.client_contract_line_items = _.filter( line.client_contract_line_items, (item)=>{ 
                return item.active != props.showInactive || props.showInactive;
            });

            newLine.vendor_contract_line_items = _.filter( line.vendor_contract_line_items, (item)=>{ 
                return item.active != props.showInactive || props.showInactive;
            });

            newLine.baseline_contract_line_items = _.filter( line.baseline_contract_line_items, (item)=>{ 
                return item.active != props.showInactive || props.showInactive;
            });

            return newLine;
        })

        return filteredLines;
    }

    const fetchLines = ()=>{
        APP.central.ServiceContract.fetchGriddedContractLines({serviceId: props.serviceData.id, siteId: props.serviceData.site.id}, "", "").then((result) =>{
            const s = {service: props.serviceData, contractLines: filterContractLines(result.result.rows), allLines: result.result.rows};
            const contractId = _.first(_.compact(_.map(s.contractLines[0].vendor_contract_lines, (l)=>l.vendorContractId )));

            stuff.current = { service: s.service, vendorContract: {id: contractId, "__type": "VendorContract"} };
            setSingle(s);
        });
    }

    useEffect(()=>{
        fetchLines();
    }, [props.serviceData]);

    useEffect(()=>{
        if(single != null && boundState != null){
            boundState.clearGotBounds();
            boundState.replaceTo(single);
        }
    }, [single]);

    useEffect(()=>{
        if(single != null){
            const newSingle = {...single};
            newSingle.contractLines = filterContractLines(single.allLines);
            
            setSingle(newSingle);
        }
    }, [props.showInactive]);

    if(single == null){
        return <Loading />;
    }

    return (
      <div className="big-edit-service">
        <Bound to={single} init={(bound) => setBoundState(bound)}> 
          <EditContractLines
            extra={props.extra}
            embedded={embedded}
            single={single}
            serviceData={single.service}
            contractLines={single.contractLines}
            registerGrid={props.registerGridContext}
            refreshLines={() => fetchLines()}
            isServiceChange={isServiceChange}
          />
        </Bound>
      </div>
    );
}

export function TheRealVendorInfo(props){
    const boundMagic = useContext(DataContext);
	const [vendor, setVendor] = useState(null);
	const serviceBoundTo = props.serviceBound;

	useEffect(()=>{
        if(serviceBoundTo.vendor != undefined){
            setVendor(serviceBoundTo.vendor);
        } else if(boundMagic.to.vendor != undefined){
            setVendor(boundMagic.magicalGet("vendor", null));
        }
	}, [boundMagic, serviceBoundTo]);

	const updateVendor = (newVendor)=>{
        if(newVendor != undefined){
            setVendor(newVendor);
        }
	}

	return <div className="vendor-info">
        <Bound to={serviceBoundTo}>
            <NiceBoxContainer>
                <h4>Vendor Info</h4>
                <NiceBox label="Vendor #">
                    {vendor != undefined? vendor.friendlyId : ""}
                </NiceBox>
                <NiceBox label="Vendor">
                    <LookupInputSelect what="active_vendors" fetch={APP.central.Vendor.listActiveVendors} fetchOptions={{vendorId: (serviceBoundTo.vendor != undefined? serviceBoundTo.vendor.friendlyId: undefined)}} field="vendor" showEmpty={true} bound={true} store="object" onChange={(service)=>updateVendor(service.vendor)}/>
                </NiceBox>
            </NiceBoxContainer>
        </Bound>
    </div>;
}

export function EditContractLines(props){
    const [firstRow, setFirstRow] = useState(null);
    const bound = useContext(DataContext);
    const ourGridContext = useRef(null);
    const single = props.single;
    const serviceBound = bound.getBound("service", undefined, {boundId: "contractLines-service-"+bound.magicalGet("service.id", 0)});
    const [ vclLines, vclLinesDS ] = useData("vcllines-" + serviceBound.id, APP.central.VendorContract.getContractLinesForService, {service: serviceBound} );

    const [vendorContractLineOptions, setVendorContractLineOptions] = useState([]);

    const isServiceChange = props.isServiceChange || false;
    
    useEffect( ()=>{
        const  newOptions = vclLines.map( (l)=>{ return {key: l.id, value: l.vendorServiceReference}} );
        if (_.isEqual( newOptions,vendorContractLineOptions ) == false){
            setVendorContractLineOptions( newOptions );
        }
    }, [vclLines]);

    const embedded = props.embedded || false;
	const [data, dataSet] = useData("jasonBlocks:" + single.service.id + moment().format("YYYY-MM-DD hh:mm"), APP.central.JasonBlocks.fetchServiceEditInfoBlocks, {serviceId: single.service.id, customerId: single.service.customer, siteId: single.service.site.id});

    const addToAll = (e, whatWasClicked, gridCtx, sourceAction)=>{
        whatWasClicked.boundAs = "client_contract_line_items,vendor_contract_line_items,baseline_contract_line_items";
        handleCellAction(e, whatWasClicked, gridCtx, sourceAction);
        return false;
    }    

    const handleCellAction = (e, whatWasClicked, gridCtx, sourceAction)=>{
        e.stopPropagation();
        
        const bodyData = {};
        let useBasis = false;

        let toCreate = "";
        let toDelete = "";
        let insertAt = gridCtx.sortedData.length;

        if(insertAt > 0 && gridCtx.sortedData[0].client_contract_line_items != undefined){
            //insertAt = gridCtx.sortedData[0].client_contract_line_items.length;
        }

        if(sourceAction == undefined || sourceAction == "cellHole"){
            useBasis = true;
        }

        if(whatWasClicked.currentBasis != undefined && useBasis){
            bodyData.currentBasis = whatWasClicked.currentBasis;
        }

        if(whatWasClicked.boundAs){
            if(sourceAction != undefined && sourceAction.toLowerCase().indexOf('delete') > -1){
                toDelete = whatWasClicked.boundAs
                bodyData.data = whatWasClicked.data;
            } else {
                toCreate = whatWasClicked.boundAs;
            }

            insertAt = whatWasClicked.rowIndex;
            bodyData.existingRow = whatWasClicked.row;
        }
    
        if(toCreate != "" || toDelete != ""){
            APP.central.ServiceContract.fetchGriddedContractLines({}, toCreate, toDelete, bodyData).then( (r)=>{
                const abc = serviceBound;
                if(r.result.error != undefined){
                    APP.instance.openConfirmDialog(r.result.errorMessage, "Error");
                }

                if(toCreate != ""){
                    if (firstRow == null){
                        setFirstRow(r.result.rows[0]);
                    }
                    gridCtx.setData(r.result.rows[0], insertAt);
                } else {
                    gridCtx.setData(r.result.rows[0], insertAt);
                }             
            });
        }
    }

    const deleteRow = (e, toBeBound, whatWasClicked, gridCtx, sourceAction)=>{
        e.persist();
        const dataToDelete = [];
        const row = gridCtx.sortedGridRowData[whatWasClicked.details.cell.expectedI];
        if(row != undefined){
            const types = new Array("client_contract_line_items", "vendor_contract_line_items", "baseline_contract_line_items");
            for(const itemType of types){
                const item = row[itemType];
                if(item != undefined){
                    dataToDelete.push(item);
                }                
            }
            
            const confirmCallback = (continueResult)=>{
                if(continueResult){
                    APP.central.ServiceContract.DestroyGridRow(dataToDelete).then((r)=>{
                        gridCtx.removeSubGridData(whatWasClicked.rowIndex, whatWasClicked.details.cell.boundDataIndex, types);
                    });
                }
            }

            APP.instance.openConfirmDialog("Continue?", "Deleting is permanent." , (r)=>confirmCallback(r));
        }   
    }

    let rowActions = [{text: "Delete", icon: "trash", action: (e, toBeBound, whatWasClicked, gridCtx, sourceAction)=>{ deleteRow(e, toBeBound, whatWasClicked, gridCtx, sourceAction); }}];
    if(isServiceChange) {
      rowActions = [];
    }

    const stealOurGrid = (ctx)=>{
        ourGridContext.current = ctx;
        props.registerGrid(ctx);
    }

    const onActivityChange = (to,field,value,ctx)=>{
        if(to.periodFrom == undefined && to.periodTo == undefined){
            //only do this if they haven't set any periods for the line item
            let maxStart = null;
            let maxEnd = null;
            const keyPath = to._keyPath;

            if(keyPath != undefined){
                _.forEach( _.keys(ourGridContext.current.sortedGridRowData), (gridRowNumber) =>{
                    const row = ourGridContext.current.sortedGridRowData[gridRowNumber];
                    if(row[keyPath] != undefined){
                        if(row[keyPath].periodFrom != undefined){
                            if( maxStart == null ){
                                maxStart = row[keyPath].periodFrom;
                            } else if( moment(maxStart).isBefore( moment(row[keyPath].periodFrom) ) ){
                                maxStart = row[keyPath].periodFrom;
                            }
                        }

                        if(row[keyPath].periodTo != undefined){
                            if(maxEnd == null){
                                maxEnd = row[keyPath].periodTo;
                            } else if( moment(maxEnd).isBefore( moment(row[keyPath].periodTo) ) ){
                                maxEnd = row.client_contract_line_items.periodTo;
                            }
                        }
                    }
                });
            }

            if(maxStart != null){
                ctx.magicalSet("periodFrom", maxStart);
            }

            if(maxEnd != null){
                ctx.magicalSet("periodTo", maxEnd);
            }
        }
        
        ourGridContext.current.refresh();        
    }

    const vendorContractLine = useMagic(single.contractLines[0].vendor_contract_lines[0] || {}, bound );

    const addAnotherVendorContractLine = (a,b,c)=>{
        const existingLine = single.contractLines[0].vendor_contract_lines[0];
        APP.central.VendorContract.addAnotherLine( existingLine.id ).then( (r)=>{
            vclLinesDS.fetch();
        });
    }

    const initBound = (magic,bound)=>{
        const extra = props.extra;
        if (extra && extra.vendorAccountReference){
            // TODO MAKE SITE AND SERVICE SAVE
            if (magic.magicalGet("vendorAccountReference") != extra.vendorAccountReference){
                magic.magicalSet("vendorAccountReference", extra.vendorAccountReference);
                magic.magicalSet("vendorSiteReference", extra.vendorSiteReference);
                magic.magicalSet("vendorServiceReference", extra.vendorServiceReference);
            }
        }

        if (extra && extra.vendor){            
            if (serviceBound.vendor == null){
                serviceBound._ourMagic.magicalSet("vendor", extra.vendor);
                if (magic.magicalGet("id", null) == null){                    
                    window.lastExtra = extra;
                }
            }
        }

    };

  
    //any additional ones will be added below
    _.each(vclLines, (vcl)=>{
        if ( false && vcl.id != vendorContractLine.id ){
        /**    vendorBounds.push( <Bound key="first" to={vcl} boundId={"vendorRef" + vcl.id}>
                <Expandable minimizedContent={"Vendor Identifers (" + vcl.vendorServiceReference + ")"} open={false}>
                    <InputText field="vendorAccountReference" name="Vendor Account" />
                    <InputText field="vendorSiteReference" name="Vendor Site" />
                    <InputText field="vendorServiceReference" name="Vendor Service"/>

                    <Button onClick={addAnotherVendorContractLine}>Add Another</Button>
                </Expandable>
            </Bound>); **/
        }
    });

    const addSplit = ()=>{
        
        if (vendorContractLine.magicalGet("vendorServiceReference").indexOf("SPLIT") == -1){
            vendorContractLine.magicalSet("vendorServiceReference", vendorContractLine.magicalGet("vendorServiceReference") + " SPLIT")
        }

    }

    const appendable = isServiceChange ? null : { text: "add row", add: addToAll };

    return (
      <div key="service_lines" className="service_lines scrollable compact">
        <Grid
          boundData={"contractLines"}
          orderBy={["site.name"]}
          rowBasis="service.id"
          key="site_grid"
          gridIdentifier={"lines-" + single.service.id}
          onCellAction={handleCellAction}
          scroll={true}
          registerGrid={stealOurGrid}
          appendable={appendable}
          disableHoleActions={true}
        >
          <GridHeading start={0} span={9}>
            <Bound to={data[0]} boundId={data[0] ? "service-client-info-" + single.service.id : null} editing={true}>
              {embedded == false ? <ClientInfo /> : null}
            </Bound>
          </GridHeading>
          <GridHeading start={9} span={7}>
            <div className="top-info">
              {embedded == false ? <TheRealVendorInfo disableEditing={false} serviceBound={serviceBound} /> : null}

              <Bound key="first" to={vendorContractLine} init={initBound} >
                <Expandable minimizedContent={"Vendor Identifiers"} open={false} style={{ maxWidth: "20em" }}>
                  <InputText field="vendorAccountReference" name="Vendor Account" />
                  <InputText field="vendorSiteReference" name="Vendor Site" />
                  <InputText field="vendorServiceReference" name="Vendor Service Reference" />

                  <Expandable minimizedContent={"Advanced"} open={false}>
                    <p>
                      <em>Use 'Split Service' when a single service is billed to multiple clients (e.g. sharing a Bin between two clients)</em>
                      <br />
                      <Button onClick={addSplit}>Split Service</Button>
                    </p>
                    <p>
                      <em>
                        Use additional vendor identifiers when a single service is billed from a vendor with more than one 'Service Reference'. You can assign
                        the identifiers to activity lines the with the dropdown that will appear, and edit the identifier with the pencil icon beside the
                        dropdown.
                      </em>
                      <br />
                      <Button onClick={addAnotherVendorContractLine}>Add Another Service Reference</Button>
                    </p>
                  </Expandable>
                </Expandable>
              </Bound>
            </div>
          </GridHeading>
          <GridHeading start={18} span={2}>
            <Bound to={data[0]}>{embedded == false ? <BaselineInfo disableEditing={false} /> : null}</Bound>
          </GridHeading>

          <GridItem key="client_items_status">
            <Grid
              boundData="client_contract_line_items"
              rowBasis="activityMappingId"
              rowBasisGroup="activity"
              gridIdentifier="client_items_status"
              rowActions={rowActions}
            >
              <GridItem title="Active" width={90}>
                <ActiveToggleSwitch />
              </GridItem>
              {isServiceChange ? null : (
                <GridItem title="Calculator" hideTitle={true} width={80}>
                  <ItemCalculatorIcon serviceData={props.serviceData} />
                </GridItem>
              )}
            </Grid>
          </GridItem>

          <GridItem key="client_items">
            <Grid
              boundData="client_contract_line_items"
              rowBasis="activityMappingId"
              rowBasisGroup="activity"
              gridIdentifier="client_items"
              appendable={{ disabled: true, text: "add a client item", add: null }}
            >
              <GridItem title="WS Activity">
                <LookupInputSelect what="lookup" display="activity" field="activity" showEmpty={false} /* what="lookup" field="activity" fetch={{status: "active"}} */ onChange={onActivityChange} />
              </GridItem>
              <GridItem title="Schedule Frequency">
                <DisableableFrequency />
              </GridItem>
              <GridItem title="Price">
                <DisableablePrice field="unitPrice" />
              </GridItem>
              <GridItem title="Per">
                <LookupInputSelect what="lookup" field="unitPricePer" />
              </GridItem>
              <GridItem title="Start Date">
                <DatePicker field="periodFrom" name="Start" />
              </GridItem>
              <GridItem title="End Date">
                <DatePicker field="periodTo" name="End" />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem key="vendor_line_items">
            <Grid
              boundData="vendor_contract_line_items"
              rowBasis="activityMappingId"
              rowBasisGroup="activity"
              gridIdentifier="vendor_items"
              appendable={{ disabled: true, text: "add a vendor item", add: null }}
            >
              <GridItem title="Vendor Activity">
                <VendorActivities serviceBound={serviceBound} onActivityChange={onActivityChange} />
              </GridItem>
              <GridItem title="Split Service" hideTitle={true} width={90}>
                <VendorContractLineSelector
                  setter={setVendorContractLineOptions}
                  options={vendorContractLineOptions}
                  vclLines={vclLines}
                  afterClose={() => vclLinesDS.fetch()}
                />
              </GridItem>
              <GridItem title="Price">
                <DisableablePrice field="unitPrice" />
              </GridItem>
              <GridItem title="Per">
                <LookupInputSelect what="lookup" field="unitPricePer" name="Per" />
              </GridItem>
              <GridItem title="Start">
                <DatePicker field="periodFrom" name="Start" />
              </GridItem>
              <GridItem title="End">
                <DatePicker field="periodTo" name="End" />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem key="base_line_items">
            <Grid
              boundData="baseline_contract_line_items"
              rowBasis="activityMappingId"
              rowBasisGroup="activity"
              gridIdentifier="baseline_items"
              appendable={{ disabled: true, text: "add a baseline item", add: null }}
            >
              <GridItem title="Baseline Activity">
                <LookupInputSelect what="lookup" field="activity" onChange={onActivityChange} />
              </GridItem>
              <GridItem title="Schedule Frequency">
                <DisableableFrequency />
              </GridItem>
              <GridItem title="Price">
                <DisableablePrice field="unitPrice" />
              </GridItem>
              <GridItem title="Per">
                <LookupInputSelect what="lookup" field="unitPricePer" name="Per" />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem key="client_items_pi">
            <Grid
              boundData="client_contract_line_items"
              rowBasis="activityMappingId"
              rowBasisGroup="activity"
              gridIdentifier="client_items"
              appendable={{ disabled: true, text: "add a client item", add: null }}
            >
              <GridItem title="Client PI">
                <InputDecimal field="unitPriceIncrease" />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </div>
    );
}

const VendorActivities = ({serviceBound, onActivityChange = (to,field,value,ctx)=>{ console.log("missing")} }) =>{
    const someCrazyContext = useContext(DataContext);
    const [vendorActivitiesData, vendorActivitiesDataSet] = useData('vendor_activity;edit-lines');
    const [selectedVendor, setSelectedVendor] = useState(null);
    const previousVendor = useRef(0);     
    const [vendorActivitiesOptions, setVendorActivitiesOptions] = useState([]);

    useEffect( ()=>{
      if (selectedVendor != null) {
        const vendorId = selectedVendor.id;

        APP.central.Vendor.fetch(vendorId).then((r) => {
          let newActivities = _.filter( vendorActivitiesData, (r)=>r.vendor && r.vendor.id == vendorId);
          
          if(newActivities.length == 0) {
            newActivities = _.filter(
              vendorActivitiesData,
              (va) => va.vendor && (va.vendor.id == vendorId || (r.result.parentVendor && r.result.parentVendor.id == va.vendor.id))
            );
          }
          
          if(_.isEqual(newActivities, vendorActivitiesOptions) == false) {
            setVendorActivitiesOptions(newActivities);
          }
        });
      }
    }, [vendorActivitiesData, selectedVendor]);

    useEffect(() => {
      if (serviceBound != null && vendorActivitiesDataSet.loading == false) {
        serviceBound._ourMagic.magicalState("vendor", setSelectedVendor);
        setSelectedVendor(serviceBound._ourMagic.magicalGet("vendor", null));
      }else{
        if ( APP.registeredBoundMagics["serviceData|jasonBlocks"] != null){

            APP.registeredBoundMagics["serviceData|jasonBlocks"].getBound("service")._ourMagic.magicalState("vendor", setSelectedVendor)
            setSelectedVendor( APP.registeredBoundMagics["serviceData|jasonBlocks"].getBound("service").vendor );
        }
      }
    }, [serviceBound, vendorActivitiesDataSet.loading]);

    useEffect( ()=>{
        if (selectedVendor != null){
            const vendorId = selectedVendor.id;

            if(previousVendor.current != vendorId){
                previousVendor.current = vendorId;

                const newActivities = _.filter( vendorActivitiesData, (r)=>r.vendor && r.vendor.id == vendorId);
                setVendorActivitiesOptions( newActivities);
            }
        }
    }, [selectedVendor]); 

    const assignVendorActivity = (to,field,value,ctx)=>{
        const vendorActivity = _.find(vendorActivitiesOptions, (o)=>o.id == value);
        if (vendorActivity != null){
            someCrazyContext.magicalSet("activity", vendorActivity.defaultActivity);
            someCrazyContext.magicalSet("vendorActivity", vendorActivity);
        }else{
            someCrazyContext.magicalSet("activity", null);
            someCrazyContext.magicalSet("vendorActivity", null);
        }

        onActivityChange(to,field,value,ctx);
    }

    const refreshVendorActivitiesDataSet = () => {
      vendorActivitiesDataSet.fetch();
    }

    const newVendorActivity = () => {
        APP.instance.createModal(<EditVendorActivity vendorActivity={{ __new: true, __dataUID: vendorActivitiesDataSet.dataUID }} />, { vendor: selectedVendor }, {afterClose: () => refreshVendorActivitiesDataSet()});
    };

    return <InputSelect optionsCollection={vendorActivitiesOptions} labelKey="activity" field="vendorActivity" onChange={assignVendorActivity} appendable={true} handleAppendClicked={newVendorActivity} store="object"/>
}

function EditVendorLineDetails(props){
    const vclMagic = useMagic(props.vcl);

    const save = ()=>{
        debugger;
        const deltas = vclMagic.getAllDeltas();
                
        if(deltas.length > 0){
            return APP.central.Bound.saveChanges(deltas);
        }
    };


    const vendorContractLine = {};

    return <div>


    <Bound to={vclMagic}>
                <h2>Vendor Identifiers</h2>
                    <InputText field="vendorAccountReference" name="Vendor Account" />
                    <InputText field="vendorSiteReference" name="Vendor Site" />
                    <InputText field="vendorServiceReference" name="Vendor Service"/>

                    <Button onClick={save}>Save</Button>
            </Bound>
    </div>
}

function VendorContractLineSelector(props){
    const boundMagic = useContext(DataContext);

    const editContractLine = ()=>{
        const a =  boundMagic;
        const id = boundMagic.magicalGet("lineItemTypeRelatedId");
        const vcl = props.vclLines.find( (v)=>v.id == id );

        const modalOptions = {
            afterClose: ()=>{
                props.afterClose();
            }
        };

        APP.instance.createModal(<EditVendorLineDetails vcl={vcl} />, null, modalOptions);
    }

    if (props.options.length <= 1){
        return null;
    }

    let newOptions = [];
    _.forEach(props.options, (o)=>{
      let newOp = o;
      const same = _.filter(props.options, (o2)=>o2.value == o.value);
      if (same.length > 1){
        const vcl = _.find(props.vclLines, (v)=>v.id == o.key);
        if (vcl){
          newOp = {key: o.key, value: vcl.vendorAccountReference + " - " + vcl.vendorSiteReference + " - " + o.value};
        }
      }      
      newOptions.push(newOp);
    })

    return <>
        <InputSelect appendable={false} showEmpty={false} field="lineItemTypeRelatedId" name="Line" options={newOptions}/>
        <Icon icon="pencil" onClick={editContractLine}/>
    </>;
}

function DisableablePrice(props) {
    const boundMagic = useContext(DataContext);
    const [disabled, setDisabled] = useState(false);
    const applyBlended = useRef(false);
    const disabledBy = useRef(null);
    const [title, setTitle] = useState("");
    const [activity, setActivity] = useState(null);

    const prepareLines = () => {
        const parentMagic = boundMagic.getParentMagic();
        const activityMappingId = boundMagic.magicalGet("activityMappingId", null);

        if (parentMagic != undefined && activityMappingId != null) {
            const clientLineItem = _.find(parentMagic.magicalGet("client_contract_line_items", []), (clientItem) => {
                return clientItem.activityMappingId == activityMappingId;
            });
            const vendorLineItem = _.find(parentMagic.magicalGet("vendor_contract_line_items", []), (vendorItem) => {
                return vendorItem.activityMappingId == activityMappingId;
            });
            const baselineLineItem = _.find(parentMagic.magicalGet("baseline_contract_line_items", []), (baselineItem) => {
                return baselineItem.activityMappingId == activityMappingId;
            });

            if(boundMagic.getAllDeltas().length > 0){
                APP.central.Savings.proposedLines({
                    client: clientLineItem,
                    vendor: vendorLineItem,
                    baseline: baselineLineItem
                }).then((r) => {
                    //lock unit price

                    if (r.result.forceClientPrice){
                        clientLineItem._ourMagic.magicalSet("unitPrice", r.result.proposedBlendedUnitPrice);
                        clientLineItem._ourMagic.magicalSet("unitPricePer", r.result.unitPricePer);
                    }else if (applyBlended.current && r.result.proposedBlendedUnitPrice != null){
                        clientLineItem._ourMagic.magicalSet("unitPrice", r.result.proposedBlendedUnitPrice);
                        clientLineItem._ourMagic.magicalSet("unitPriceMargin", r.result.margin);
                    }

                    
                    
                });
            }
        }
    }

    const updateTitle = ()=>{

        const margin = boundMagic.magicalGet("unitPriceMargin", 0);

        const vendorLineItem = _.find(boundMagic.getParentMagic().magicalGet("vendor_contract_line_items", []), (vendorItem) => {
            return vendorItem.activityMappingId == boundMagic.magicalGet("activityMappingId");
        });

        if (vendorLineItem == null){
            return;
        }
        
        const vendorCost = vendorLineItem.unitPrice;
        const adjustment = boundMagic.magicalGet("unitPriceAdjustment", 0);

        const newTitle = boundMagic.magicalGet("unitPrice", 0) + " = "  + vendorCost + plusOrMinus(margin) + plusOrMinus(adjustment);
        if (title != newTitle){
            setTitle(newTitle);
        }
    }

    const _disableUnitPrice = (thing)=>{
        if( thing != null){
            if(thing.__type == "ClientType"){ //consulting
                if(thing.id == 2){
                    //pretty sure we don't need to do this anymore but I'm sure I'll be wrong.
                    if (boundMagic.magicalGet("lineItemType") == "client_contract"){
                       disabledBy.current = "client";
                       setDisabled(true);
                    }
                }else {
                    if(disabledBy.current == "client"){
                        disabledBy.current = null;
                        setDisabled(false);
                    }
                }
            } else if(thing.__type == "CompensationType"){
                applyBlended.current = thing.shouldApplyBlendedPrice;
                if(thing.lockUnitPrice == true && boundMagic.magicalGet("lineItemType") == "client_contract"){
                    disabledBy.current = "compensation";
                    setDisabled(true);
                } else {
                    if(disabledBy.current == "compensation"){
                        disabledBy.current = null;
                        setDisabled(false);
                    }
                }
            }
        } else {
            setDisabled(false);
        }
    }

    useEffect(() => {
        /** this is if we are depending on the client inside the grid */
        APP.customize.on("BoundMagics", "service-client-info-" + boundMagic.magicalGet("service"), (bm)=>{
            const theBound = bm.getBound( bm.magicalGet("client.clientTypeOverrideLevel") );
            theBound._ourMagic.magicalState("compensationType", _disableUnitPrice );
            theBound._ourMagic.magicalState("clientType", _disableUnitPrice );
        }, true);

        /** this is if we are depending on the client in the main service edit jasonblocks */
        APP.customize.on("BoundMagics", "clientInfo-client", (bm)=>{
            // const bm = APP.registeredBoundMagics["clientInfo-client"];
            if (bm.magicalGet("clientTypeOverrideLevel")){
                const theAuthority = "clientInfo-" + bm.magicalGet("clientTypeOverrideLevel");

                APP.customize.on("BoundMagics", theAuthority, (authorityBound)=>{
                    authorityBound.magicalState("compensationType", _disableUnitPrice );
                    authorityBound.magicalState("clientType", _disableUnitPrice );
                });
            }
        }, true);
    }, [])

    useEffect(() => {
        boundMagic.magicalState("unitPricePer", (val) => {
            if (val != undefined && disabledBy.current == null) {
                if (val == "surcharge") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
            }
        });

        if(boundMagic.getParentMagic() != undefined){
            if(boundMagic.magicalGet("lineItemType") == "vendor_contract" || boundMagic.magicalGet("lineItemType") == "baseline_contract"){
                boundMagic.magicalState("unitPrice", prepareLines);
            }

            if(boundMagic.magicalGet("lineItemType") == "client_contract"){
               boundMagic.magicalState("unitPriceAdjustment", prepareLines);

               boundMagic.magicalState("unitPrice", updateTitle);
            }
            
            boundMagic.magicalState("serviceFrequency", prepareLines);
            boundMagic.magicalState("activity", setActivity);
            
        }

        

    }, [boundMagic]);

    
    const formatter = {
        parse(e,a){
            return e && e.replaceAll ? e.replaceAll("[$]","") : e;
        },
        format(a,b,c){
            return a;
        }
    };

    let isDisabled = disabled;
    if (activity != null && activity == "Management Fee"){
        isDisabled = false;
    }
    return <div><InputText formatter={formatter} field="unitPrice" disabled={isDisabled} name={props.name} title={title}/></div>
}

function plusOrMinus(num){
    if (num >= 0){
        return " + " + Util.roundNice(num, 2);
    }

    return " - " + Util.roundNice(Math.abs(num), 2);
}

function DisableableFrequency(props){
    const [visible,setVisible] = useState(false);
    const boundMagic = useContext(DataContext);
    const [activityData, activityDataDS] = useData("activity_definition");

    const updateVendorFrequency = (frequency) =>{
        if(boundMagic.magicalGet("lineItemType") == "client_contract"){
            const parentMagic = boundMagic.getParentMagic();
            if(parentMagic != undefined){
                const vendorLineItem = _.find(parentMagic.magicalGet("vendor_contract_line_items", []), (vendorItem) => {
                    return vendorItem.activityMappingId == boundMagic.magicalGet("activityMappingId", null);
                });

                if(vendorLineItem != undefined && vendorLineItem._ourMagic != null){
                    const vendorFrequency = vendorLineItem._ourMagic.magicalGet("serviceFrequency", null);
                    if( (frequency != null && vendorFrequency == null) || (frequency == null && vendorFrequency != null) || (frequency != null && vendorFrequency != null && frequency.id != vendorFrequency.id) ){
                        vendorLineItem._ourMagic.magicalSet("serviceFrequency", frequency);
                    }
                }
            }
        }
    }

    const checkVisible = (a,b,c,)=>{
        // pricePer == "surcharge"
        const d = boundMagic;
        const e = activityData;

        let expectedServiceSchedule = null;
        if (APP.registeredBoundMagics["serviceData|jasonBlocks"]){
            expectedServiceSchedule = APP.registeredBoundMagics["serviceData|jasonBlocks"].to.service.schedule;
        }

        let ourActivityDef = null;
        if (_GLOBAL_DATA_CACHE["activity_definition"]){
            const activity = boundMagic.magicalGet("activity");
            ourActivityDef = _GLOBAL_DATA_CACHE["activity_definition"].filter({activity: activity })[0];

            if (ourActivityDef && ourActivityDef.showFrequency == true){
                if (boundMagic.magicalGet('serviceFrequency', null) == null){
                    if (expectedServiceSchedule){
                        boundMagic.magicalSet('serviceFrequency', expectedServiceSchedule);
                    }
                }

                if (visible == false){
                    setVisible(true);
                }
            }

            if (ourActivityDef && ourActivityDef.showFrequency == false){
                if (visible == true){
                    setVisible(false);
                }
            }
        }
        
    }

    useEffect( ()=>{
        checkVisible();
    }, [activityData]);
    
	useEffect( ()=>{		
        boundMagic.magicalState("serviceFrequency", updateVendorFrequency);
        boundMagic.magicalState("activity", checkVisible);
        boundMagic.magicalState("unitPricePer", checkVisible);
        boundMagic.magicalState("serviceFrequency", checkVisible);
	}, [boundMagic]);

    if (visible == false){
        return <div></div>;
    }

    return <div><LookupInputSelect what="service_schedule" field="serviceFrequency" name="Schedule Frequency" display={(r)=>{ return r.frequency + " (" + r.monthlyRate + ")"}} store="object"/></div>
}

/* now using DatePicker components, keeping this just in case
const EndDatePicker = () =>{
    const bound = useContext(DataContext);
    const [periodTo, setPeriodTo] = useState("");
    const lastEvent = useRef(null);

    const periodToUpdater = (v,e)=>{
        //if set term and end date unset, then set end date to end of month
        if(bound.magicalGet("term", null) != null && lastEvent.current == null){
            //let newEnd = moment(bound.magicalGet("periodFrom")).add("month", bound.magicalGet("term")).startOf("month").add("day", -1);
            //(theBound.magicalGet(startField), theBound.magicalGet(termField));
            let newEnd = Util.addTerm(bound.magicalGet("periodFrom"), bound.magicalGet("term"));

            if( !newEnd.isSame( bound.magicalGet("periodTo") ) ){
                bound.magicalSet("periodTo", newEnd.format("YYYY-MM-DD"));
                setPeriodTo(newEnd.format("YYYY-MM-DD"));
            }
        }

        lastEvent.current = null;
    }

    useEffect( ()=>{
		bound.magicalState("term", (v,e)=>{ periodToUpdater(v,e) });
        bound.magicalState("periodFrom", (v,e)=>{ periodToUpdater(v,e) });
	}, [bound]);

    return <div key="end-date-picker">
        <DatePicker field="periodTo" name="End" defaultValue={periodTo} update={()=>{ lastEvent.current = "me" }}/>
    </div>
}

const TermPicker = () =>{
    const bound = useContext(DataContext);
    const [lookups, lookupsDataset] = useData("lookup", {method: "fetchOptions"});
    const terms = useRef([]);
    const lastEvent = useRef(null);

    useEffect(()=>{
        if(terms.current.length <= 0){
            const lookupData = lookupsDataset.filter({name: "term"});
            if(lookupData.length > 0){
                terms.current = _.sortBy(_.keys(lookupData[0].options), (option)=>{ return parseInt(option) });
            }
        }
    }, [lookupsDataset.loading])

    const onPeriodChange = (v,e) =>{
        if(bound.magicalGet("periodFrom") != undefined && bound.magicalGet("periodTo") != undefined && bound.magicalGet("periodFrom") != "" && bound.magicalGet("periodTo") != "" && lastEvent.current == null){
            //set term to difference between periodFrom and periodTo
            const difference = Math.ceil( Math.abs( moment(bound.magicalGet("periodFrom")).diff( moment(bound.magicalGet("periodTo")), "months", true ) ) );
            bound.magicalSet("term", difference);
        }

        lastEvent.current = null;
    }

    useEffect( ()=>{
		bound.magicalState("periodFrom", onPeriodChange);
        bound.magicalState("periodTo", onPeriodChange);
	}, [bound]);

    return <div key="term-picker">
        <InputTextWithSelect field="term" options={terms.current} onChange={()=>{ lastEvent.current = "me" }}/>
    </div>
}
*/


const ActiveToggleSwitch = () =>{
    const applyToAllItemsInRow = (value, field, bound)=>{
        const parentMagic = bound.getParentMagic();
        if(parentMagic != undefined){
            const myKeyPath = bound.magicalGet("_keyPath", null);
            const myActivityMappingId = bound.magicalGet("activityMappingId", null);

            const parentGotBounds = parentMagic._gotBounds;
            if(parentGotBounds != undefined){
                const excludedSubset = [];
                _.forEach(_.keys(parentGotBounds), (boundKey)=>{
                    const gotBound = parentGotBounds[boundKey];

                    if(gotBound.activityMappingId == myActivityMappingId && gotBound._keyPath != myKeyPath){
                        excludedSubset.push(gotBound._ourMagic);
                    }
                });

                if(excludedSubset.length > 0){
                    _.forEach(excludedSubset, (boundMagic)=>{
                        boundMagic.magicalSet(field, value);
                    });
                }
            }
        }
    }
    
    return <InputToggleSwitch field="active" onChange={(value, field, bound)=>applyToAllItemsInRow(value, field, bound)}/>;
}

export const SelectService = ({services, allowAddService = false, createService = undefined})=>{
    return <InputSelect field="service" name="Service" options={_.fromPairs(services.map((s)=>[s.id, s.shortDescription]))} appendable={allowAddService} appendableLabel={"Create New Service"} handleAppendClicked={createService}/>
}

export const EditLineDetails = ({magic, mappingId})=>{
    const [serviceBound, setServiceBound] = useState({});
    const [services, setServices] = useState([]);
    const [clientLineItemBound, setClientLineItemBound] = useState({});
    const [vendorLineItemBound, setVendorLineItemBound] = useState({});
    const [baselineLineItemBound, setBaselineLineItemBound] = useState({});
    const [info, setInfo] = useState(null);

    const [showAdjustments, setShowAdjustments] = useState(false);
    const [lockClientUnitPrice, setLockClientUnitPrice] = useState(false);

    useEffect( ()=>{
        const childId = _.findIndex( magic.to.client_contract_line_items, (li)=>li.activityMappingId == mappingId );

        setServiceBound( magic.getBound("service") );
        setClientLineItemBound( magic.getBound("client_contract_line_items", childId));
        setVendorLineItemBound( magic.getBound("vendor_contract_line_items", childId));
        setBaselineLineItemBound( magic.getBound("baseline_contract_line_items", childId));
    }, [magic, mappingId]);

    const _disableThings = (compensationType)=>{
            if(compensationType != null){
                if(_.isString(compensationType)){
                    if(compensationType.indexOf("Blended") > -1){
                        setShowAdjustments(true);
                        setLockClientUnitPrice(true);
                    }else{

                    }
                } else {
                    if(compensationType.name.indexOf("Blended") > -1){
                        setShowAdjustments(true);
                        setLockClientUnitPrice(true);
                    }
                }
            }
    }
    useEffect( ()=>{
            if (serviceBound.customer != null){
                const customerId = serviceBound.customer;
                const siteId = serviceBound.site.id;

                APP.central.Service.fetchServicesForCustomer(customerId, siteId).then((a)=>{
                    setServices(a.result ? a.result : []);
                });
            }

           /** APP.customize.on("BoundMagics", "service-client-info-" + boundMagic.magicalGet("service"), (bm)=>{
                const theBound = bm.getBound( bm.magicalGet("client.clientTypeOverrideLevel") );
                theBound._ourMagic.magicalState("compensationType", _disableThings );
                //theBound._ourMagic.magicalState("clientType", _disableUnitPrice );
            }, true); **/
        
            APP.customize.on("BoundMagics", "clientInfo-client", (bm)=>{
                // const bm = APP.registeredBoundMagics["clientInfo-client"];
                if (bm.magicalGet("clientTypeOverrideLevel")){
                    const theAuthority = "clientInfo-" + bm.magicalGet("clientTypeOverrideLevel");
                    if (APP.registeredBoundMagics[theAuthority] != null){
                        const theBound = APP.registeredBoundMagics[theAuthority];
                        theBound.magicalState("compensationType", _disableThings );
                        //theBound.magicalState("clientType", _disableUnitPrice );
                    }
                }
            }, true);
    }, [serviceBound]);

    return <div className="edit-ar-line edit-lines-blown-up">
        <h2>Activity Line Details</h2>

        <Bound to={serviceBound}>
            <div className="line-item-container">
                <div className="line-item line-item-after">
                    <h4>Client</h4>
                    <Bound to={clientLineItemBound}>
                        <SelectService services={services}/>
                        <LookupInputSelect what="lookup" field="activity" name="WS Activity"/>
                        <DisableableFrequency name="Schedule Frequency"/>
                        <FlexRow>
                            <InputToggleSwitch
                                field="additionalItemBilling"
                                name={(status) => {
                                    return status == undefined || status == false ? "Billed from Vendor Item" : "Always Billed To Client";
                                }}
                                icon="repeat"
                            />
                            { (serviceBound.customer == 1715 || serviceBound.customer == 1687 || serviceBound.customer == 1714) ? // ian hates his life and this is boardwalk
                           <InputToggleSwitch
                                field="fixedBilling"
                                name={(status) => {
                                    return status == undefined || status == false ? "Boardwalk Variable Charge" : "Boardwalk Fixed Charge";
                                }}
                                icon="check-circle"
                            /> : null }
                        </FlexRow>
                        <InputText field="quantity" name="Quantity" />                        
                        <InputText field="unitPrice" name="Unit Price" disabled={lockClientUnitPrice}/>
                        <LookupInputSelect what="lookup" field="unitPricePer" name="Per"/>
                        <InputText field="extendedPrice" name="Extended Price" />
                        {showAdjustments == true? <InputText field="unitPriceAdjustment" name="Unit Price Adjustment" inputType="decimal" /> : null}
                        <LookupInputSelect what="taxes" field="taxId" name="Tax" store="object"/>
                        <LookupInputSelect what="lookup:subscriptionPeriods" field="subscriptionPeriod" name="Subscription"/>
                        <LookupInputSelect what="lookup:subscriptionApplyTo" field="subscriptionPeriodApplyTo" name="Subscription Apply To"/>
                        
                        <InputText field="footnote" name="Invoice Footnote" />
                    </Bound>
                </div>

                <div className="line-item line-item-vendor">
                    <h4>Vendor</h4>
                    <Bound to={vendorLineItemBound}>
                        <SelectService services={services}/>                  
                        <LookupInputSelect what="lookup" field="activity" name="WS Activity"/>
                        <DisableableFrequency name="Schedule Frequency"/>
                        <FlexRow><InputToggleSwitch
                            field="fixedBilling"
                            name={(status) => {
                                return status == undefined || status == false ? "Expected Charge" : "Expected Charge";
                            }}
                            icon="check-circle"
                            />
                        </FlexRow>
                        <InputText field="quantity" name="Quantity" />
                        <DisableablePrice field="unitPrice" name="Price"/>
                        <LookupInputSelect what="lookup" field="unitPricePer" name="Per"/>
                        <InputText field="extendedPrice" name="Extended Price" />
                        {showAdjustments == true? <InputText field="unitPriceAdjustment" name="Unit Price Adjustment" inputType="decimal" disabled={true}/> : null}
                        <LookupInputSelect what="taxes" field="taxId" name="Tax" store="object"/>
                        <InputText field="footnote" name="Invoice Footnote" />
                    </Bound>
                </div>

                <div className="line-item line-item-before">
                    <h4>Baseline</h4>

                    <Bound to={baselineLineItemBound}>
                        <SelectService services={services}/>
                        <LookupInputSelect what="lookup" field="activity" name="WS Activity"/>
                        <DisableableFrequency name="Schedule Frequency"/>
                        <InputText field="quantity" name="Quantity" />
                        <DisableablePrice field="unitPrice" name="Price"/>
                        <LookupInputSelect what="lookup" field="unitPricePer" name="Per"/>
                        <InputText field="extendedPrice" name="Extended Price" />
                        {showAdjustments == true? <InputText field="unitPriceAdjustment" name="Unit Price Adjustment" inputType="decimal" disabled={true}/> : null}
                        <LookupInputSelect what="taxes" field="taxId" name="Tax" store="object"/>
                        <InputText field="footnote" name="Invoice Footnote" />
                    </Bound>
                </div>
            </div>
        </Bound>

        <CloseAndApply/>
    </div>
};

export const CloseAndApply = ()=>{
    const modalContext = useContext(CurrentModal);

    const doClose = ()=>{
        if (modalContext && modalContext.id){
            APP.instance.closeModal(modalContext.id);
        }
    }


	return <Button onClick={()=>doClose()}>Apply</Button>;

}


