import React, { Fragment, useContext, useEffect, useState } from "react";
import { Data } from "@opidcore/Data";
import { ActionBar, Button, Bound, HoverElement, Loading, Tabular, TabularColumn, TabularDataSet } from "@opidcore/components";
import Calendar from "@opidcore/components/Content/Types/Calendar";
import Icon from "@opidcore/components/Icon";
import { RoutedDefaults } from "@opidcore/components/OpidApplication";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import MapFromMarker from "../../components/MapFromMarker";
import ViewPanel, { Field } from "../../components/ViewLayouts";
import ChangeLogs from "../ChangeLog/View";
import EditClientContact from "../Customer/EditClientContact";
import Notes from "../Notes";
import Tasks from "../Tasks";
import ValueAddPrograms from "./ValueAddPrograms";
import File from "./../../components/File"

/*
 * Component to be displayed when a site is selected from the /ui/sites/list page
 * Details site information
 */

const NavBarContent = ({ site, inPortal }) => {
  return (
    <div key="navbar-content" className="title-with-stuff">
      <h2>Site Details: {site.name}</h2>
      {!inPortal ? (
        <Field>
          <Link to={"/ui/sites/edit/" + site.friendlyId}>
            <Icon icon="edit" size="2x" />
          </Link>
        </Field>
      ) : null}
      <div className="flex-spacer"></div>
    </div>
  );
};

export default function DetailSite(props) {
  let params = useParams();
  const extra = useContext(RoutedDefaults);
  const siteId = params.id || props.siteId;

  const [site, dataSet] = useData("site;" + siteId, siteId, extra);
  //const [customer, customerDataSet] = useData("customer;customer-for-site" + siteId, site.customer);
  const [customer, setCustomer] = useState(null);

  const [schedule, setSchedule] = useState(new Data([]));

  //mode will be portal
  const mode = props.mode || "wsc";

  const inPortal = mode == "portal";

  const [services, setServices] = useState([]);
  const [servicesDetails, setServiceDetails] = useState([]);

  useEffect(() => {
    if (site && site.customer) {
      APP.central.Customer.fetch(site.customer).then((r) => {
        setCustomer(r.result);
      });
    }
  }, [site]);

  //update navbar
  const nb = useNavBar("Site Details: " + site.name);
  useEffect(() => {
    const navbarUpdateOptions = nb.createUpdateOptions("Site Details: " + site.name, <NavBarContent site={site} inPortal={inPortal} />);

    nb.fullUpdate(navbarUpdateOptions);
  }, [site, inPortal]);

  //get services (List of models.erp.Service)
  useEffect(() => {
    if (site && site.id != undefined) {
      APP.central.Service.fetchFilteredServicesForCustomer(site.customer, site.id, false).then((r) => {
        setServices(r.result);
      });
    }
  }, [site]);

  //get service details (ArrayList of ServiceDetails)
  useEffect(() => {
    if (site && site.id != undefined) {
      APP.central.Service.fetchServiceDetailsForCustomer2(site.customer, site.id).then((r) => {
        setServiceDetails(r.result);
      });
    }
  }, [site]);

  //get and format site primary contact
  const [primaryCustomerContacts, setprimaryCustomerContacts] = useState([]);
  useEffect(() => {
    if (site && site.id != undefined) {
      APP.central.Site.fetchSiteContacts(site.friendlyId).then((r) => {
        let primaryContacts = [];

        _.forEach(r.result, (contact) => {
          if (contact.isPrimary && contact.historical == false) {
            let primCont = { name: "", phone: "" };
            (contact.lastName ? (primCont.name += contact.lastName + ", ") : "") + (contact.firstName ? (primCont.name += contact.firstName) : "");
            contact.cellPhone ? (primCont.phone = contact.cellPhone) : (primCont.phone = "-");
            if (!primCont.name) {
              primCont.name = "-";
            }
            if (contact.email > "") {
              primCont.email = <a href={"mailto:" + contact.email}>{contact.email}</a>;
            }
            primaryContacts.push(primCont);
          }
        });

        setprimaryCustomerContacts(primaryContacts);
      });
    }
  }, [site]);

  //get service schedule for calendar
  useEffect(() => {
    if (site != null && site.id != null) {
      APP.central.Site.fetchServiceScheduleDetails(site.id).then((r) => {
        setSchedule(new Data(r.result));
      });
    }
  }, [site]);

  //count containers on site
  let numContainers = 0;
  _.forEach(services, (ser) => {
    if (ser.historical == 0) {
      ser.quantityOnSite ? (numContainers += ser.quantityOnSite) : numContainers++;
    }
  });

  const isUS = site.country == "USA";

  const displayAttributes = (attributes) => {
    if (attributes) {
      const asArray = Object.entries(attributes);
      const filtered = asArray.filter(([key, value]) => !key.startsWith("_"));
      const attributesFiltered = Object.fromEntries(filtered);
      return (
        <div>
          <h5>Site Attributes</h5>
          <ViewPanel>
            {Object.entries(attributesFiltered).map(([key, val]) => {
              return <Field label={key}>{val}</Field>;
            })}
          </ViewPanel>
        </div>
      );
    }
  };

  const uploadFile = (file) => {
    console.log(file)
  }

  const VendorContacts = (props) => {
    const [vendorContacts, setVendorContacts] = useState(null);

    useEffect(() => {
      if (props.vendor != null) {
        APP.central.Vendor.fetchContacts(props.vendor).then((r) => {
          setVendorContacts(r.result);
        });
      }
    }, [props]);

    if (vendorContacts == null) {
      return <Loading />;
    }
    return (
      <div>
        <Tabular data={vendorContacts}>
          <TabularColumn data={(row) => row.firstName} title="First Name" />
          <TabularColumn data={(row) => row.lastName} title="Last Name" />
          <TabularColumn data={(row) => row.title} title="Title" />
          <TabularColumn data={(row) => row.phone} title="Phone" />
          <TabularColumn data={(row) => row.cellPhone} title="Cell Phone" />
          <TabularColumn data={(row) => row.email} title="Email" />
        </Tabular>
      </div>
    );
  };

  return (
    <div className="multi-block portal-content">
      <div className="action-bar-block">
        <ActionBar>
          <div style={{ flex: 5 }}></div>
          {customer ? <Notes model={site} entities={[customer]} types={["Customer", "Site"]} /> : null}
          <Tasks model={site} />
          <ChangeLogs model={site} />
        </ActionBar>
      </div>

      <div className="viewPanel-block">
        <ViewPanel>
          <Field label="Client name">
            {customer ? (
              <Link className="plain_link" to={"/ui/clients/" + customer.id}>
                {inPortal ? "ABC Co." : customer.name}
              </Link>
            ) : (
              <Loading size="2x" />
            )}
          </Field>
          <Field label="Site name">{site.name}</Field>

          <Field label="Site address">{site.address ? site.address : "-"}</Field>
          <Field label="City">{site.city ? site.city : "-"}</Field>
          <Field label={isUS ? "State" : "Province"}>{site.province ? site.province : "-"}</Field>
          <Field label={isUS ? "Zip Code" : "Postal code"}>{site.postalCode ? site.postalCode : "-"}</Field>

          <Field label="Containers on site">{numContainers}</Field>
          {customer ? <Field label="Group">{customer.umbrella ? customer.umbrella : inPortal ? "Retail" : "-"}</Field> : null}

          <PrimaryContactsFields primaryCustomerContacts={primaryCustomerContacts} />
        </ViewPanel>

        {site.tags && displayAttributes(site.tags)}
      </div>

      <div className="map-block">
        <MapFromMarker mapMarker={site.mapMarker} />
      </div>

      <div className="page-block">
        <div>
          <NiceVisualServices services={services} serviceDetails={servicesDetails}></NiceVisualServices>
        </div>
      </div>

      <div className="page-block">
        <h3>Haulers</h3>
        <NiceVisualVendors services={services} serviceDetails={servicesDetails}></NiceVisualVendors>
        {/*<VendorContacts vendor={site.vendorContactOverride != null ? site.vendorContactOverride : null} site={site}/>*/}
        <div></div>
      </div>

      <div className="full-width-block">
        <h3>Files</h3>
        <Bound to={site}>
          <File onUpload={(file) => uploadFile(file)}/>
        </Bound>
      </div>

      <div className="page-block">
        <h3>Contacts</h3>
        <SiteContacts siteFriendlyId={siteId} edit={false} />
      </div>

      <div className="full-width-block">
        <ValueAddPrograms site={site} disabled={true} />
      </div>

      {false ? (
        <div className="full-width-block">
          <h3>Active Tickets</h3>
          <p>TODO Tickets would go here</p>
        </div>
      ) : null}

      <div className="calendar-block">
        <h3>Schedule</h3>
        <Calendar dataset={schedule} linkTo="#" dateVarName={"date"} onClickDay={console.log("do something here probably")}>
          <PickupDetail />
        </Calendar>
      </div>
    </div>
  );
}

const PrimaryContactsFields = ({ primaryCustomerContacts = [] }) => {
  return (
    <Fragment>
      {_.map(primaryCustomerContacts, (contact, index) => {
        return (
          <div key={"primaryContact" + index} style={{ marginRight: "10px" }}>
            <Field label={"Primary Site Contact #" + (index + 1)}>
              {contact.name}
              <br />
              {contact.email}
              <br />
              {contact.phone}
            </Field>
          </div>
        );
      })}
    </Fragment>
  );
};

function PickupDetail(props) {
  const event = props.scope.event;
  return (
    <div className="pickup" style={{ backgroundColor: event.color }}>
      {event.label}
    </div>
  );
}

//============================================================================= NICE SERVICES =============================================================================
export function NiceVisualServices(props) {
  //gets services as prop.
  const services = props.services;
  const NULLMATERIALID = getNullMaterial().id;

  let siteId = 0;
  if (services && services.length > 0) {
    siteId = services[0].site.id;
  } else {
    return null;
  }

  const [site, dataSet] = useData("site;" + siteId, siteId);
  const [servicesDetails, setServiceDetails] = useState([]);

  //get service details (ArrayList of ServiceDetails)
  useEffect(() => {
    if (site && site.id != undefined && props.serviceDetails == undefined) {
      APP.central.Service.fetchServiceDetailsForCustomer2(site.customer, site.id).then((r) => {
        setServiceDetails(r.result);
      });
    }
  }, [site]);

  useEffect(() => {
    setServiceDetails(props.serviceDetails);
  }, [props.serviceDetails]);

  return (
    // a nice box for each material type
    <>
      <h4>Services</h4>
      <div className="visual-service-container">
        {_.map(_.groupBy(servicesDetails, "material_id"), (details, materialId) => (
          <NiceVisualService materialData={details || []} materialId={materialId} key={materialId} onClick={props.onClick} />
        ))}
      </div>
    </>
  );
}

const NiceVisualService = ({ materialData = [], materialId = undefined, onClick = undefined }) => {
  if (materialData == null || materialData.length <= 0) {
    return null;
  }

  const NULLMATERIALID = getNullMaterial().id;
  const nullMaterial = getNullMaterial();

  let colorSetAccent = {};
  let colorSetMain = {};
  if (materialId != null && materialId != NULLMATERIALID) {
    colorSetAccent = { background: materialData[0].material_accent_colour };
    colorSetMain = { background: materialData[0].material_main_colour };
  } else {
    colorSetAccent = { background: nullMaterial.accentColour };
    colorSetMain = { background: nullMaterial.mainColour };
  }

  let icon = materialData[0].material_icon;

  let fType = "";
  if (icon && icon.indexOf(":") > 0) {
    const chunks = icon.split(":");
    icon = chunks[1];
    fType = chunks[0];
  }

  const onClickHelper = () => {
    onClick && onClick(materialData[0]);
  };

  return (
    <Fragment>
      <div className="visual-service-box" style={colorSetAccent} onClick={onClickHelper}>
        <h4 className="visual-service-title" style={colorSetMain}>
          {materialId == NULLMATERIALID ? nullMaterial.name : materialData[0].material_name}
        </h4>
        <div className="material-icon">
          <Icon icon={materialId == NULLMATERIALID ? nullMaterial.icon : icon} fType={fType} colour={colorSetMain.background} size="2x" />
        </div>
        {_.map(materialData, (d) => {
          if (d.equipment_size_id == null || d.equipment_size_id <= 0) {
            return (
              <ServiceDetailBox
                key={d.equipment_size_id || -1}
                containerName={d.container_name}
                equipmentType={d.equipment_type == null ? "" : d.equipment_type + " - "}
                quantity={d.quantity_on_site}
                equipmentSize={"No Size"}
              />
            );
          } else {
            return (
              <ServiceDetailBox
                key={d.equipment_size_id}
                containerName={d.container_name}
                equipmentType={d.equipment_type == null ? "" : d.equipment_type + " - "}
                quantity={d.quantity_on_site}
                equipmentSize={d.equipment_size}
              />
            );
          }
        })}
      </div>
    </Fragment>
  );
};

const ServiceDetailBox = ({ containerName, equipmentType, quantity, equipmentSize }) => {
  return (
    <div style={{ marginLeft: "10px", marginRight: "10px", width: "100%", textAlign: "center" }}>
      <div>{containerName}</div>
      <div>
        <span>
          {equipmentType}
          {equipmentSize}
        </span>{" "}
        (Qty: {quantity})
      </div>
    </div>
  );
};

//============================================================================= NICE VENDORS =============================================================================
function NiceVisualVendors(props) {
  //gets services as prop.
  const services = props.services;

  const nullMaterial = getNullMaterial();

  let siteId = 0;
  if (services && services.length > 0) {
    siteId = services[0].site.id;
  } else {
    return null;
  }

  const [site, dataSet] = useData("site;" + siteId, siteId);
  const [servicesDetails, setServiceDetails] = useState(props.serviceDetails || []);

  //get service details (ArrayList of ServiceDetails)
  useEffect(() => {
    if (site && site.id != undefined && props.serviceDetails == undefined) {
      APP.central.Service.fetchServiceDetailsForCustomer2(site.customer, site.id).then((r) => {
        setServiceDetails(r.result);
      });
    }
  }, [site]);

  useEffect(() => {
    setServiceDetails(props.serviceDetails);
  }, [props.serviceDetails]);

  return (
    <div className="visual-vendor-container">
      {_.map(_.groupBy(servicesDetails, "vendor_id"), (details, vendorId) => (
        <NiceVisualVendor key={vendorId} vendorData={details || []} />
      ))}
    </div>
  );
}

const NiceVisualVendor = ({ vendorData = [] }) => {
  if (vendorData.length <= 0) {
    return null;
  }

  const openVendorDetailsModal = (data) => {
    APP.instance.createModal(<VendorDetails serviceIds={_.map(data, "service_id")} />, { modal_name: "Details" });
  };

  const content = (
    <div className="visual-vendor-box" style={{ background: vendorData[0].material_accent_colour }} onClick={() => openVendorDetailsModal(vendorData)}>
      <h4 className="visual-vendor-title" style={{ background: vendorData[0].material_main_colour }}>
        {vendorData[0].vendor_name}
      </h4>
      <div className="visual-vendor-materials">
        {_.map(vendorData, (d) => {
          let icon = d.material_icon;
          let fType = "";
          if (icon && icon.indexOf(":") > 0) {
            const chunks = icon.split(":");
            icon = chunks[1];
            fType = chunks[0];
          }
          return (
            <div
              key={d.material_id}
              style={{ margin: "5px", cursor: "pointer" }}
              title={(d.equipment_type != null ? d.equipment_type + " - " : "") + (d.equipment_size || "No Size")}
            >
              <Icon icon={icon} fType={fType} colour={d.material_main_colour} size="2x" />
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <HoverElement title="Click to open Vendor Info Modal" style={{ bottom: 175 }} content={content} hoverTime={100}>
      <VendorContactHover serviceIds={_.map(vendorData, "service_id")} />
    </HoverElement>
  );
};

const VendorContactHover = ({ serviceIds }) => {
  const [allVendorData, allVendorDataSet] = useData("vendorData-" + _.join(serviceIds, "|"), APP.central.Vendor.fetchDataFromServices, { serviceIds: serviceIds });
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(null);

  const doCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (serviceIds.length > 0 && allVendorDataSet.loading == false) {
      allVendorDataSet.setQueryArgs({ serviceIds: serviceIds });
      allVendorDataSet.fetch();
    }
  }, [serviceIds]);

  useEffect(() => {
    if (allVendorDataSet.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (allVendorData && allVendorData.length > 0 && allVendorData[0].vendorContacts) {
      setDisplay(
        <div className="vendorContactHoverInterior">
          <h4>{allVendorData[0].vendors[0].name}</h4>
          <Tabular data={allVendorData[0].vendors}>
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.accountNumber)}>
                  {row.accountNumber}
                </a>
              )}
              title="Account #"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.website)}>
                  {row.website}
                </a>
              )}
              title="Website"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.phone)}>
                  {row.phone}
                </a>
              )}
              title="Phone"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.email)}>
                  {row.email}
                </a>
              )}
              title="Email"
            />
          </Tabular>
          <h4>{allVendorData[0].vendors[0].name + " Contacts"}</h4>
          <Tabular data={allVendorData[0].vendorContacts}>
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.firstName)}>
                  {row.firstName}
                </a>
              )}
              title="First Name"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.lastName)}>
                  {row.lastName}
                </a>
              )}
              title="Last Name"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.title)}>
                  {row.title}
                </a>
              )}
              title="Title"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.phone)}>
                  {row.phone}
                </a>
              )}
              title="Phone"
            />
            <TabularColumn
              data={(row) => (
                <a className="plain_link" title="Click to copy" onClick={() => doCopyText(row.email)}>
                  {row.email}
                </a>
              )}
              title="Email"
            />
          </Tabular>
        </div>
      );

      /*
        _.map(allVendorData[0].vendorContacts, (d) => (
          <div key={d.id} className="vendorContactHoverItem">
            <div className="vendorContactHoverItemName">{d.firstName}</div>
            <div className="vendorContactHoverItemName">{d.lastName}</div>
            <div className="vendorContactHoverItemName">{d.email}</div>
            <div className="vendorContactHoverItemName">{d.phone}</div>
          </div>
        ))
      */
    }
  }, [allVendorDataSet.loading]);

  if (loading == true) {
    return <Loading />;
  }

  if (allVendorData.length == 0) {
    return null;
  }

  return <div className="vendorContactHover">{display}</div>;
};

const VendorDetails = ({ serviceIds = [] }) => {
  const [allVendorData, allVendorDataSet] = useData("vendorData-" + _.join(serviceIds, "|"), APP.central.Vendor.fetchDataFromServices, { serviceIds: serviceIds });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (serviceIds.length > 0 && allVendorDataSet.loading == false) {
      allVendorDataSet.setQueryArgs({ serviceIds: serviceIds });
      allVendorDataSet.fetch();
    }
  }, [serviceIds]);

  useEffect(() => {
    if (allVendorDataSet.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [allVendorDataSet.loading]);

  if (loading == true) {
    return <Loading />;
  }

  if (allVendorData.length == 0) {
    return null;
  }

  const elements = _.map(allVendorData[0].vendors, (vendor) => {
    const vendorData = {
      serviceVendorInfo: _.filter(allVendorData[0].serviceVendorInfo, { vendor_id: vendor.id }),
      services: _.filter(allVendorData[0].services, (s) => s.vendor.id == vendor.id),
      sites: allVendorData[0].sites, //site contacts could be it's own elements chunk since it's independent of the vendor - as is it's a duplicated section
      vendorContacts: _.filter(allVendorData[0].vendorContacts, { vendorId: vendor.id }),
      vendors: _.filter(allVendorData[0].vendors, { id: vendor.id }),
    };

    return <VendorDetailsBreakdown allData={vendorData} vendor={vendor} key={vendor.id} />;
  });

  return <div className="vendor-details">{elements}</div>;
};

const VendorDetailsBreakdown = ({ allData = undefined, vendor = undefined }) => {
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    if (allData.serviceVendorInfo != undefined && vendor != undefined) {
      setServiceData(allData.serviceVendorInfo);
    }
  }, [allData]);

  if (vendor == undefined || allData == undefined) {
    return null;
  }

  const accountRef = serviceData != undefined && serviceData.length > 0 ? serviceData[0].vendor_account_reference_agg : null;

  return (
    <div key={vendor.id} className="vendor-details-breakdown">
      <div className="vendor-link">
        <h2>{vendor.name}</h2>
        <Link className="plain_link" to={"/ui/vendors/edit/" + vendor.id} target="_blank">
          <Icon icon="pencil" />
        </Link>
      </div>
      <h4>Vendor Account Number: {accountRef}</h4>
      <Tabular data={serviceData}>
        <TabularColumn data={(row) => row.service_friendly_id} title="Service" />
        <TabularColumn data={(row) => row.vendor_site_reference_agg} title="Vendor Site" />
        <TabularColumn data={(row) => row.site_name} title="Site" />
        <TabularColumn data={(row) => row.material_name} title="Material" />
        <TabularColumn data={(row) => (row.equipment_type != null ? row.equipment_type + " - " : "") + (row.equipment_size || "No Size")} title="Equipment" />
        <TabularColumn data={(row) => row.quantity_on_site} title="Quantity" />
      </Tabular>

      <h4>Site Contacts</h4>
      <Tabular data={allData.siteContacts}>
        <TabularColumn data={(row) => row.firstName} title="First Name" />
        <TabularColumn data={(row) => row.lastName} title="Last Name" />
        <TabularColumn data={(row) => row.role} title="Title" />
        <TabularColumn data={(row) => row.phone} title="Phone" />
        <TabularColumn data={(row) => row.email} title="Email" />
      </Tabular>

      <h4>Vendor Contacts</h4>
      <Tabular data={allData.vendorContacts}>
        <TabularColumn data={(row) => row.firstName} title="First Name" />
        <TabularColumn data={(row) => row.lastName} title="Last Name" />
        <TabularColumn data={(row) => row.title} title="Title" />
        <TabularColumn data={(row) => row.phone} title="Phone" />
        <TabularColumn data={(row) => row.email} title="Email" />
      </Tabular>
    </div>
  );
};

function getNullMaterial() {
  return {
    id: -1,
    name: "No Material Name",
    density: 0,
    epaRecycling: false,
    accentColour: "#FFACAC",
    mainColour: "#FF0505",
    icon: "question",
    savedinStorage: false,
  };
}

const SiteContacts = ({ siteFriendlyId, edit = false, site = null}) => {
  const [contactData, contactDataSet] = useData("site-contacts-" + siteFriendlyId, APP.central.Site.fetchSiteContacts, { siteFriendlyId: siteFriendlyId });

  const refreshContacts = () => {
    contactDataSet.fetch();
  };

  const editContact = (e, contact) => {
    e.stopPropagation();
    APP.instance.createModal(
      <EditClientContact contactId={contact.id} />,
      { modal_name: "Edit Contact:" + contact.id },
      {
        afterClose: () => refreshContacts(),
      }
    );
  };

  const addContact = () => {
    APP.instance.createModal(<EditClientContact contactId={0} customer={site.customer} site={site} />, 
      { modal_name: "Add Contact" },
      { 
        afterClose: () => refreshContacts(),
      }
    );
  };

  const archiveContact = (contact) => {
    APP.confirm("Archive " + (contact.firstName || "") + " " + (contact.lastName || "") + "?", (answer) => {
      if (answer == true) {
        APP.central.CustomerContact.archiveContact(contact.id).then((r) => {
          APP.alert(r.message, () => {
            if (r.success == true) {
              contactDataSet.fetch();
            }
          });
        });
      }
    });
  };

  return (
    <Fragment>
      <TabularDataSet dataSet={contactDataSet} noData="">
        <TabularColumn
          data={(row) =>
            row.isPrimary ? (
              <b>
                {"(Primary)"}
                <br />
                {(row.lastName ? row.lastName : "-") + ", " + (row.firstName ? row.firstName : "-")}
              </b>
            ) : (
              row.lastName + ", " + row.firstName
            )
          }
          title="Name"
          sortCol="firstName"
        />
        <TabularColumn data={(row) => (row.isPrimary ? <b>{row.title}</b> : row.title)} title="Title" sortCol="title" />
        <TabularColumn data={(row) => row.rolesString} title="Roles" />
        <TabularColumn data={(row) => row.email} title="Email" />
        <TabularColumn data={(row) => row.phone} title="Phone" />
        {edit ? (
          <TabularColumn
            data={(row) => {
              return <Icon icon="edit" onClick={(e) => editContact(e, row)} size="1x" />;
            }}
            title="Edit"
          />
        ) : null}

        {edit ? (
          <TabularColumn data={(row) => <Icon icon="trash" onClick={() => archiveContact(row)} />} title="Archive" />
        ) : null}


      </TabularDataSet>
      {edit ? (
        <div>
          <Button onClick={() => addContact()}>Add Contact</Button>
        </div>
      ) : null}
    </Fragment>
  );
};

export { SiteContacts };
