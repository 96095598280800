import React ,{ Fragment, useEffect, useRef, useState } from "react";
import {
	ActionBar,
	Button,
	DatePicker,
	FlexBreak,
	Icon,
	InputAutoComplete,
	InputCheckbox,
	InputDecimal,
	InputSelect,
	InputText,
	InputTextPattern,
	InputToggleSwitch,
	Loading,
	Searchable,
	Tabular,
	TabularColumn,
	TabularDataSet,
	TagPicker,
	UnlockToEdit
} from "@opidcore/components";
import BoundCommit from "@opidcore/components/BoundCommit";
import LookupInputSelect from "@opidcore/components/LookupInputSelect";
import { useData, useNavBar } from "@opidcore/hooks/WTF";
import * as _ from "lodash";

import { Link, useParams } from "react-router-dom";
import { APP } from '../../../../js';
import { AddressBlock, StaffSelect } from "../../components";
import { CompensationSelector } from "../../components/JasonBlocks";
import Notes from "../Notes";
import Tasks from "../Tasks";
import EditClientContact from "./EditClientContact";

import { ChangeLogDelta } from "@opidcore/ChangeLog";
import moment from "moment";
import ClientTaskViewer from "../../components/ClientTaskViewer";
import ChangeLogs from '../ChangeLog/View';
import FullTextSearchable from "../../components/FullTextSearchable";

export default function ClientEdit(props) {
	let params = useParams();
	const initialClientId = (props.id != undefined ? props.id : params.id) || "0";
	const [clientId, setClientId] = useState(initialClientId);
	const nav = useNavBar(clientId == "0" ? "New Client" : "Loading Client...");

	const [subscribedServices, setSubscribedServices] = useState({});
	const [showBlendedCheckBoxes, setShowBlendedCheckBoxes] = useState(false);
	const [data, dataSet] = useData("customer", clientId);
	const [disableActiveToggle, setDisableActiveToggle] = useState(true)

	const [isUS, setIsUS] = useState(false);
	const contactsAnchor = useRef(null);


	useEffect(() => {
		APP.central.Customer.fetchSubscriptionData(clientId).then((r) => {
			const subs = _.groupBy(r.result, "subType");
			_.forEach(_.keys(subs), (subGroupKey) => {
				subs[subGroupKey] = _.groupBy(subs[subGroupKey], "status");
			});

			setSubscribedServices(subs);
		});

		if (window.location.hash === '#contact-list') {
			scrollToContacts();
		}
	}, []);

	useEffect(() => {
		const tempId = APP.getWebSession().userId;
		if (data.billingAnalyst != null && tempId == data.billingAnalyst.id) {
			setDisableActiveToggle(false)
		} else if (data.regionalAccountManager != null && tempId == data.regionalAccountManager.id) {
			setDisableActiveToggle(false)
		}
	}, [data]);

	const scrollToContacts = () => {
		contactsAnchor.current.scrollIntoView();
		contactsAnchor.current.focus();
	}

	const updateCountry = (a, b, c) => {
		if (c == "United States") {
			setIsUS(true);
		} else {
			setIsUS(false);
		}
	}

	useEffect(() => {
		if (clientId == 0 && props.id == undefined && data.id > 0) {
			setClientId(data.id);
		}
		const linkId = data.friendlyId || data.id;
		if (data != undefined && data.id >= 0) {
			const links = [<Link to={"/ui/clients/" + linkId}>Client</Link>, <Link to={"/ui/clients/sites/" + linkId}>Sites</Link>, <Link to={"/ui/clients/" + linkId + "/invoices"}>Invoices</Link>];

			if (APP.instance.isModalOpen() == false) {
				nav.update(data.name, links, { selectedItem: 0 });
			} else {
				nav.updateTitle(data.name)
			}
		}

		if (linkId != undefined && APP.registeredBoundMagics["clientData"] != undefined) {
			APP.registeredBoundMagics["clientData"].magicalState("compensationType", updateBlendedOptions);
		}

		updateCountry(null, null, data.billingCountry);

		if(dataSet.loading == false){
			if (window.location.hash === '#contact-list') {
				scrollToContacts();
			}
		}
	}, [dataSet.loading]);

	const updateBlendedOptions = (value) => {
		if (value != undefined) {
			if (value.name == "Blended Savings") {
				setShowBlendedCheckBoxes(true);
			} else {
				APP.registeredBoundMagics["clientData"].magicalSet("reportTemplateOptions", null);
				setShowBlendedCheckBoxes(false);
			}
		}
	}

	if (dataSet.loading == true) {
		return <Loading />;
	}

	const combineAllAttachments = { true: "Yes", false: "No" };

	const countSubscribedServices = (service, status) => {
		const current = subscribedServices[service];
		if (current != undefined) {
			if (current[status] != undefined) {
				return current[status].length;
			}
		}

		return 0;
	};

	const doSave = () => {
		
		const clientData = APP.registeredBoundMagics["clientData"];
		if (clientData != undefined) {
			if (clientData.magicalGet("sustainabilityAnalyst", null) == "") {
				clientData.magicalSet("sustainabilityAnalyst", null)
			}
			if (clientData.magicalGet("sustainabilityAnalyst", null) != null && clientData.magicalGet("hasEIRReporting") != true) {
				APP.confirm(
					"A sustainability analyst has been set, but the client does not receive sustainability reports." +
					"Enable sustainability reports? Cancel to save without enabling sustainability reports",
					(ok) => {
						if (ok) {
							clientData.magicalSet("hasEIRReporting", true);
						}
					}
				);
			}
			if (clientData.deltas[0].delta.hasOwnProperty("hasEIRReporting") && clientData.deltas[0].delta.hasEIRReporting == true) {
				APP.central.Customer.sendEIRReportingEmail(clientData.magicalGet("id"), clientData.deltas[0].delta.hasEIRReporting).then((r) => {

				})
			}
			if (clientData.deltas[0].delta.hasOwnProperty("sustainabilityAnalyst") && clientData.deltas[0].delta.sustainabilityAnalyst != null) {
				APP.central.Customer.sendSustainabilityAnalystEmail(clientData.magicalGet("id"), clientData.deltas[0].delta.sustainabilityAnalyst).then((r) => {

				})
				
			}
			if (clientData.magicalGet("targetNetGHG") == null || clientData.magicalGet("targetNetGHG") == "") {
				clientData.magicalSet("targetNetGHG", 0);
			}
      		if (clientData.magicalGet("billingAnalyst") == "") {
				clientData.magicalSet("billingAnalyst", null);
			}
			if(clientData.deltas[0].delta.hasOwnProperty("billingAnalyst") && clientData.deltas[0].delta.billingAnalyst != null){  
				const staffId = clientData.deltas[0].delta.billingAnalyst;
				const clientName =  clientData.magicalGet("name");			
                const clientId =   clientData.magicalGet("id");
				const clientFriendlyId = clientData.magicalGet("friendlyId");	 			
				APP.central.Staff.sendClientTeamMemberNotificationEmail(staffId, clientName, clientFriendlyId, clientId, "billing").then((r)=>{
					if (r.status == "ok") {
						APP.alert("Notification Email Sent.");						
					  } else {
						APP.alert("Failed to send notification email.");
					  }
				});
			}

			if(clientData.deltas[0].delta.hasOwnProperty("regionalAccountManager") && clientData.deltas[0].delta.regionalAccountManager != null){  
				const staffId = clientData.deltas[0].delta.regionalAccountManager;
				const clientName =  clientData.magicalGet("name");
				const clientId =   clientData.magicalGet("id");
				const clientFriendlyId = clientData.magicalGet("friendlyId");	
				APP.central.Staff.sendClientTeamMemberNotificationEmail(staffId, clientName, clientFriendlyId, clientId, "accountManager").then((r)=>{
					if (r.status == "ok") {
						APP.alert("Notification Email Sent.");						
					  } else {
						APP.alert("Failed to send notification email.");
					  }
				});

			}		

			return APP.central.Bound.saveChanges(clientData.getAllDeltas()).then((r) => {
				const result = r.result[clientData.magicalGet("__dataUID")];

				if (props.afterSave != undefined) {
					setClientId(result.id);
					props.afterSave(result);

					return false;
				} else if (result != undefined && (clientId == 0 || clientId == null)) {
         			setClientId(result.id);
					APP.redirect(props.match.path.replace(":id", result.friendlyId));
				}
			})
		}
	}

	let actionItems = undefined;
	if (data.id > 0) {
		actionItems = <>
			<Notes model={data} />
			<Tasks model={data} />
			<ChangeLogs model={data} />
		</>;
	}

	const openChangeLogList = () => {
		APP.instance.createModal(<ContactChangeLogList api={APP.central.Customer.clientContactChangeLogs} params={{ clientId: data.id }} />, { modal_name: "List Change Logs" });
	}

	const updateCurrency = (a, b, c) => {

	}

	return (
		<div className="?">
			<div key="edit_customer" className="basicForm page-block">

				<ActionBar model={data} showNotes={true} showTasks={true}>
					<div style={{ flex: 5 }}></div>
					<TagPicker />
					{actionItems}
				</ActionBar>
				
				<BoundCommit initialValue={data} commit={doSave} boundId="clientData" saveVerb={props.saveButtonText}>
					<div className="small-flex">
						<UnlockToEdit>
							<InputText field="friendlyId" name="Client #" />
						</UnlockToEdit>
					</div>
          
          <UnlockToEdit unlocked={data && (data.xeroUUID == null || _.isEmpty(data.xeroUUID))}>
            <InputText required={true} field="name" name="Client Name" />
					  <InputText required={true} field="legalName" name="Legal Name" /> 
          </UnlockToEdit>

					<div className="small-flex">
						<InputToggleSwitch
							field="active"
							name={(status) => {
								return status == undefined || status == false ? "Inactive" : "Active";
							
							}}
							disabled={disableActiveToggle}
						/>
						<InputCheckbox field="isParent" name="Act As Parent" />
					</div>

					<DatePicker field="targetConversionDate" name="Target Conversion Date" /> 

					<LookupInputSelect what="lookup" field="accountingGroup" name="Accounting Group" defaultValue="WSC" forcedDefault={true} style={{ width: "10%" }} />
					<LookupInputSelect what="lookup" field="umbrella" name="Umbrella"  appendable={true} style={{ width: "10%" }} />
					<InputAutoComplete field="parentCustomer" name="Parent Client" central={APP.central.Customer.autocompleteParent}>
						{(customer) => <div className="customer_option">{customer.displayValue}</div>}
					</InputAutoComplete>
					<AddressBlock className="field full" mapFields={["billingAddress1", "billingCity", "billingProvince", "billingCountry"]}>
						<InputText required={true} field="billingAddress1" name="Address Line 1" />
						<InputText field="billingAddress2" name="Address Line 2" /> 
						<div className="flex_group">
							<InputText required={true} className="flex_element" field="billingCity" name="City" />  
							<LookupInputSelect what="lookup:State Province" parameter1={isUS ? "USA" : "CAN"} field="billingProvince" name={isUS ? "State" : "Province"} required={true}/>
							{isUS ? <InputTextPattern field="billingPostal" name="Zip Code" message="54321" required={true} pattern={"^([0-9][0-9][0-9][0-9][0-9])$"} />
								: <InputTextPattern field="billingPostal" name="Postal Code" message="A0A 1B1" required={true} pattern={"^([A-Z][0-9][A-Z]) ?([0-9][A-Z][0-9])$"} />}
							<LookupInputSelect required={true} what="lookup:Country" name="Country" field="billingCountry" onChange={(a, b, c) => updateCountry(a, b, c)} />
						</div>
					</AddressBlock>

					<h3>Billing</h3>
					<div className="four-wide">
						<InputText field="poNumber" name="Purchase Order #" />
						<LookupInputSelect what="lookup" field="billingScheme" name="AutoBill Setting" forcedDefault={true} defaultValue={"review_required"} />
						<LookupInputSelect what="lookup" field="invoiceType" name="Invoice Type" forcedDefault={true} />
						<LookupInputSelect what="reportType" field="reportType" name="Report Type" store="name" />
						<InputSelect field="combineAllAttachments" name="Combine All Attachments" options={combineAllAttachments} forcedDefault={true} />
						<LookupInputSelect what="lookup:Billing System" field="billingSystem" name="Billing System" forcedDefault={true} defaultValue={"wastemetric"}/>  
						<LookupInputSelect what="lookup:billingPriority" field="billingPriority" name="Billing Priority" forcedDefault={true} />
						<FlexBreak />
						<InputSelect field="clientTypeOverrideLevel" name="Client Type Set" options={["", "client", "site", "service"]} showEmpty={true} />
						<LookupInputSelect what="clientType" field="clientType" name="Client Type" store="object" />
						<CompensationSelector name="Compensation Type" />
						<LookupInputSelect what="compensationJointVenture" field="compensationJointVenture" name="Joint Venture" store="object" />
						<FlexBreak />
						<InputSelect field="reportTemplateOverrideLevel" name="Report Template Set" options={["client", "site", "service"]} forcedDefault={true} defaultValue={"client"} />
						<ReportTemplateLookupSelector field="reportTemplate" label="Report Template" />
						{showBlendedCheckBoxes ? <div className="field" style={{ "display": "flex", "flexDirection": "row", "justifyContent": "space-around" }}>
							<InputCheckbox name="Show Baseline" field="reportTemplateOptions.showBaseline" />
							<InputCheckbox name="Blended Breakdown" field="reportTemplateOptions.blendedDetails" />
						</div> : null}
						<LookupInputSelect what="currency" field="invoiceCurrency" name="Invoice Currency" store="object" display="description"/>
						{/*<InputSelect field="invoiceCurrency" name="Invoice Currency" optionsCollection={currencies} fieldKey="code" />*/}
					</div>

					<h3>Sustainability</h3>
					<div>
						<InputDecimal maxPlaces={0} places={0} name="Target Net GHG (MT CO₂)" field="targetNetGHG" defaultValue={0} />
					</div>

					<h3>Subscribed Services</h3>
					<div className="full has-services">
						<div className="option">
							<Icon icon="sensor" size="2x" />
							<div className="field sensor-count">
								<label>Sensors</label>
								{countSubscribedServices("SiteEquipmentSensor", "active")} /{" "}
								{countSubscribedServices("SiteEquipmentSensor", "active") + countSubscribedServices("SiteEquipmentSensor", "inactive")}
								<InputCheckbox hideLabel={true} field="hasServiceSensors" name="Sensors" />
							</div>
						</div>
						<div className="option">
							<Icon icon="mobile" size="2x" />
							<div className="field mobile-app-count">
								<label>Mobile App</label>
								{countSubscribedServices("MobileApp", "active")} /{" "}
								{countSubscribedServices("MobileApp", "active") + countSubscribedServices("MobileApp", "inactive")}
								<InputCheckbox hideLabel={true} field="hasServiceMobileApp" name="Mobile App" />
							</div>
						</div>
						<div className="option">
							<Icon icon="trash" size="2x" />
							<div className="field mobile-app-count">
								<label>Waste Audit</label>
								{countSubscribedServices("WasteAudit", "active")} /{" "}
								{countSubscribedServices("WasteAudit", "active") + countSubscribedServices("WasteAudit", "inactive")}
								<InputCheckbox hideLabel={true} field="hasServiceWasteAudit" name="Waste Audit" />
							</div>
						</div>
						<div className="option">
							<Icon icon="recycle" size="2x" />
							<div className="field eir-count">
								<label>EIR Reporting</label>
								{countSubscribedServices("eir", "active")} /{" "}
								{countSubscribedServices("eir", "active") + countSubscribedServices("eir", "inactive")}
								<InputCheckbox hideLabel={true} field="hasEIRReporting" name="EIR Report" />
							</div>
						</div>
					</div>

					<FlexBreak />

					<h3>Team Members</h3>
					<StaffSelect what="staff" field="regionalAccountManager" name="Account Manager" />
					<StaffSelect what="staff" field="businessDevelopmentManager" name="BDR" />
					<StaffSelect what="staff" field="sustainabilityAnalyst" name="Sustainability" />
					<StaffSelect what="staff" field="billingAnalyst" name="Billing" />

					<h3>Implementation</h3>
					<StaffSelect what="staff" field="implementationAssignedTo" name="Assigned To" />
					<LookupInputSelect what="lookup" field="implementationStage" name="Stage" />

					<div className="full field">
						<label>Last Contact</label>
						<div className="value">
							<em>TODO This will be driven from the activity tab</em>
						</div>
					</div>

					<LookupInputSelect what="lookup" field="environmentalIndustry" name="Industry" />
					<LookupInputSelect what="lookup" field="environmentalDensity" name="Density Setting" />
					
					<FlexBreak />
				</BoundCommit>
				<FlexBreak />


			</div>

			<div className="basicForm page-block">
				<div className="flex_group" style={{ justifyContent: "space-between", width: "100%" }}>
					<h3 style={{ flex: "0 0 0" }} ref={contactsAnchor}>Contacts</h3>
					<Icon icon="history" onClick={() => openChangeLogList()} />
				</div>
				<FlexBreak />

				<ContactList client={data} />
			</div>

			<div className="basicForm page-block">
				<h3>Support Cases</h3>
				<FlexBreak />
				<ClientTaskViewer id={data.id} />
			</div>

		</div>
	);
}

const ContactChangeLogList = ({ api, params }) => {
	const [changeLogs, changeLogsSet] = useData("contact_change_logs", api, params);

	const openChangeLog = (log) => {
		APP.instance.closeModal("Change Log", null, () => APP.instance.createModal(<ChangeLogDelta delta={log.differentials} />, { modal_name: "Change Log" }));
	};

	return <div className="change-log-list" key="change-log-list">
		<h4>Change Logs</h4>
		<TabularDataSet dataSet={changeLogsSet} defaultSort="start" defaultSortOrder="desc" pageSize={10}>
			<TabularColumn title="Date" data={(row) => moment(row.start).format("YYYY-MM-DD HH:mm")} />
			<TabularColumn title="Record" data={(row) => row.relatedTable} />
			<TabularColumn title="Contact" data={(row) => row.extras} />
			<TabularColumn title="Type" data={(row) => row.differentials != undefined ? row.differentials.changeType : null} />
			<TabularColumn title="Changed By" data={(row) => row.userObject.fullName} />
			<TabularColumn title="" data={(row) => <Icon icon="history" onClick={() => openChangeLog(row)} />} />
		</TabularDataSet>
	</div>
}

// {field = "reportTemplate", label = null, store = "object", showEmpty = true, readOnly = falseConsole.log}
export const ReportTemplateLookupSelector = (props) => {

	const field = props.field ? props.field : "reportTemplate";
	const label = props.label ? props.label : null;
	const store = props.store ? props.store : "object";
	const showEmpty = props.showEmpty ? props.showEmpty : true;
	const readOnly = props.readOnly ? props.readOnly : false;

	const [reportTemplateData, reportTemplateDataSet] = useData("reportTemplateLookup", APP.central.Lookup.fetchForName, { name: "reportTemplate" });
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setOptions(_.filter(reportTemplateData, (row) => { return row }));
	}, [reportTemplateDataSet.loading]);

	return <InputSelect {...props} field={field} labelKey="label" name={label} showEmpty={showEmpty} optionsCollection={options} store={store} forcedDefault={true} defaultValue={options[0]} readOnly={readOnly} />;
}

export function ContactList({ client, truncate = false }) {
  //const [contactList, contactListDataset] = useData("contacts-" + client.id, APP.central.CustomerContact.fetchForClient, { clientId: client.id });
  const [clientContacts, setClientContacts] = useState([]);
  const [fetchClientContacts, setFetchClientContacts] = useState(false);

  const refreshContacts = () => {
    //dataSet.fetch();
    setFetchClientContacts(true);
  };

  /*
  useEffect(() => {
    contactListDataset.setQueryArgs({ clientId: client.id });
    contactListDataset.fetch();
  }, [client]);
  */

  const addContact = () => {
    APP.instance.createModal(<EditClientContact contactId={0} customer={client.id} />, 
    { modal_name: "Add Contact"},
    {
      afterClose: () => {
        //contactListDataset.fetch();
        refreshContacts();
      }
    }
    );
  };

  const editContact = (person) => {
    let modalName = (person.first_name || "") + " " + (person.last_name || "");
    if (modalName.trim().length == 0) {
      modalName = "Edit Contact";
    }
    APP.instance.createModal(<EditClientContact contactId={person.id} />, { modal_name: modalName });
  };

  const archiveContact = (contact) => {
    APP.confirm("Archive " + (contact.first_name || "") + " " + (contact.last_name || "") + "?", (answer) => {
      if (answer == true) {
        APP.central.CustomerContact.archiveContact(contact.id).then((r) => {
          APP.alert(r.message, () => {
            if (r.success == true) {
              //contactListDataset.setQueryArgs({ clientId: client.id });
              //contactListDataset.fetch();
              refreshContacts();
            }
          });
        });
      }
    });
  };

  const impersonate = (contact) => {
    APP.central.Portal.impersonatePortal(contact.id, 1).then((r) => {
      window.open(r.result.portalUrl, "_blank");
    });
  };

  /*
  if (contactListDataset.loading == true) {
    return <Loading />;
  }
  */

  if (client.id == 0) {
    return null;
  }

  return (
    <div>
      <ActionBar title="Actions">
        <FullTextSearchable
          model={"CustomerContact"}
          method={"reasonableListForCustomer"}
          setData={setClientContacts}
          doFetchData={fetchClientContacts}
          allowBlank={true}
          setDoFetchData={setFetchClientContacts}
          additionalParams={[client.id]}
        />
      </ActionBar>
      {clientContacts.length == 0 ? "Search to show results." : null}

      <Tabular data={clientContacts} pageSize={10} >
        <TabularColumn
          data={(row) =>
            row.is_primary ? (
              <b>
                {"(Primary)"}
                <br />
                {(row.last_name ? row.last_name : "-") + ", " + (row.first_name ? row.first_name : "-")}
              </b>
            ) : (
              row.last_name + ", " + row.first_name
            )
          }
          title="Name"
          sortCol="first_name"
        />
        <TabularColumn data={(row) => (row.title ? row.title : "-")} title="Title" />
        {truncate ? null : (
          <TabularColumn
            data={(row) => {
              const roles = _.map(_.split(row.roles_string, ","), (role, index) => {
                return (
                  <Fragment>
                    {role}
                    {index > 0 ? "," : null}
                    <br />
                  </Fragment>
                );
              });
              return <Fragment>{roles}</Fragment>;
            }}
            title="Roles"
          />
        )}
        <TabularColumn data={(row) => row.email} title="Email" />
        <TabularColumn data={(row) => row.phone ? row.phone : row.cell_phone ? row.cell_phone : "-"} title="Phone" />
        {truncate ? null : (
          <TabularColumn data={(row) => row.associated_sites} title="Associated Sites"/>
        )}
        <TabularColumn data={(row) => (row.receives_invoices == true ? <Icon icon="dollar-sign" size="2x" /> : "")} title="Invoices" />
        <TabularColumn data={(row) => (row.receives_cost_savings == true ? <Icon icon="analytics" size="2x" /> : "")} title="Monthly Reports" />
        <TabularColumn data={(row) => (row.receives_sustainability == true ? <Icon icon="leaf" size="2x" /> : "")} title="Sustainability Reports" />
        <TabularColumn data={(row) => <Icon icon="user-edit" onClick={() => editContact(row)} />} title="Edit" />
        {truncate ? null : <TabularColumn data={(row) => <Icon icon="user" onClick={() => impersonate(row)} />} title="Impersonate Portal" />}
        <TabularColumn data={(row) => <Icon icon="trash" onClick={() => archiveContact(row)} />} />
      </Tabular>
      <br />
      <Button onClick={() => addContact()}>Add Contact</Button>
    </div>
  );
}